import { getThemeProp } from 'global/variables';
import { isEqual, size } from 'lodash';
import React, { Component } from 'react';
import { GeoJSON } from 'react-leaflet';

const animations = getThemeProp('animationsColor');
const animationsWeight = getThemeProp('animationsWeight');

let isUnmounted = false;
let isTourChanging = false;

class MapAnimatedTour extends Component {
  constructor(props) {
    super(props);
    this.interval = 555;
    this.segmentsAmount = 40;
    this.state = { onTour: null, step: 0 };
    isUnmounted = false;
    isTourChanging = false;
  }

  componentDidMount = () => {
    const time = setInterval(() => {
      this.animateTour(this.props.tour, this.state.step + 1);
    }, this.interval);
    this.setState({ ...this.state, animation: time });
  };

  UNSAFE_componentWillReceiveProps({ tour: nextTour = {} }) {
    if (!isEqual(this.props.tour, nextTour)) {
      isTourChanging = true;
      clearInterval(this.state.animation);
      const time = setInterval(() => {
        this.animateTour(nextTour, this.state.step + 1);
      }, this.interval);
      this.setState({ ...this.state, onTour: null, animation: time }, () => {
        isTourChanging = false;
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  componentWillUnmount = () => {
    isUnmounted = true;
    clearInterval(this.state.animation);
  };

  getDynamicTour = (tours, startPos = 0) => {
    if (!tours) {
      return null;
    }

    const features = [];
    this.props.display.routeIds.forEach(id => {
      const { bbox, geometry } = tours.features.find(t => t.properties.routeId === id) || {};
      if (bbox && geometry) {
        const { coordinates } = geometry;
        const coordinatesSize = size(coordinates);
        const laps = Math.round(coordinatesSize / this.segmentsAmount);
        const amount = Math.round(coordinatesSize / laps);
        for (let lap = 0; lap < laps; lap++) {
          for (let segment = 0; segment < size(animations); segment++) {
            const start = lap * amount + segment + startPos;
            const geo = {
              bbox,
              geometry: {
                coordinates: coordinates.slice(start, start + 2),
                type: 'LineString',
              },
              type: 'Feature',
              properties: { level: segment },
            };
            features.push(geo);
          }
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features,
    };
  };

  animateTour = (tours, startPos) => {
    const step = startPos > this.segmentsAmount ? 0 : startPos;
    const onTour = this.getDynamicTour(tours, step);
    if (!isUnmounted && !isTourChanging) {
      this.setState({ ...this.state, onTour, step });
    }
  };

  featureStyle = feature => ({
    color: animations[feature.properties.level],
    weight: animationsWeight,
  });

  render() {
    if (!this.state.onTour) {
      return <></>;
    }

    return (
      <GeoJSON
        key={`ontour-${this.state.step}`}
        data={this.state.onTour}
        style={this.featureStyle.bind(this)} // eslint-disable-line react/jsx-no-bind
      />
    );
  }
}

export default MapAnimatedTour;
