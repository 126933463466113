import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FLEET_MODES, setTourParameter } from 'actions';
import withTranslation from 'hoc/withTranslation';
import config from 'config';
import { find, isEqual, map, isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import TimeRangeSlider from './TimeRangeSlider';
import { getAsset, isMobileDevice } from '../../../utils/helpers';
import {
  StyledDoublePanel,
  StyledLine,
  StyledTextInput,
  StyledWizardContent,
  StyledWizardH1,
  StyledText,
  StyledTextLight,
  StyledSelectBigInput,
  StyledIcon,
  StyledSelectedInputLabel,
} from '../Global/WizardStyled';
import InputLocation from '../Global/InputLocation';
import Section from '../../Form/Section';
import Label from '../../Form/Label';
import Selector from '../../Form/Selector';
import ReadMoreLess from '../Global/ReadMoreLess';
import iconAmount from '../../../global/img/truck-black.svg';
import iconCapacity from '../../../global/img/shipment.svg';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import fleetModeIconMulti from '../../../global/img/fleet-multi.svg';
import FleetReturnAddress from './FleetReturnAddress';
import CompanyName from './CompanyName';

const isMobile = isMobileDevice();
const {
  scenario: { times: timesConfig },
  maxValues,
} = config;

const fleetModeIcons = {
  custom: fleetModeIconMulti,
  solo: iconAmount,
};

const StyledWizardH1AndSelector = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  lineHeight: '3rem',
  h1: {
    marginBottom: '2.4rem',
    lineHeight: '1.6rem',
  },
  div: {
    height: '2rem',
    justifySelf: 'end',
  },
  '@media screen and (max-width: 350px)': {
    '> div > div': {
      display: 'none',
    },
  },
});

const StyledDescriptionContainer = styled.div({
  padding: '0 0 2rem 0',
});

const StyledDescriptionElement = styled.div({
  margin: '0.5rem 0 0 0',
  lineHeight: '1.2rem',
  '*': {
    display: 'inline-block',
    margin: '0 0.5rem 0 0',
  },
});

const StyledIconAmountStyle = css`
  background-image: url(${getAsset(iconAmount)});
  background-position-y: 0.2rem;
`;

const Step1Container = ({
  user,
  oAuth,
  tourPlanner,
  translations: {
    error: { depotNotFound },
    global: { timesTrans },
    map: { depotMarkerTrans },
    tourPlanner: {
      depotLabelTrans,
      depotPlaceholderTrans,
      configurationLabelTrans,
      timeLabelTrans,
    },
    wizard: {
      fleet: {
        companyNameDescShort,
        companyNameDescLong,
        vehiclesAmountLabel,
        vehiclesCapacityLabel,
        vehiclesAmountLabelMobile,
        vehiclesCapacityLabelMobile,
        fleetModeCustomTitle,
        fleetModeSoloTitle,
        fleetModeExplain,
        fleetModeUnlimited,
        fleetModeTitle,
      },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(parameter => dispatch(setTourParameter(parameter)), [
    dispatch,
  ]);
  const {
    time: { key: timeTourParamKey, value: timeTourParamValue },
    vehicle: { amount, capacity },
    location,
    companyName,
    fleetMode,
  } = tourPlanner || {};
  const [times, setTimes] = useState(timeTourParamValue);
  const [fleetAmount, setFleetAmount] = useState(amount);
  const [fleetCapacity, setFleetCapacity] = useState(capacity);
  const [noDepotFound, setNoDepotFound] = useState(false);

  const timeOptions = useMemo(
    () => map(timesConfig, ({ key }) => ({ key, value: timesTrans[`${key}`] })),
    [timesTrans],
  );

  const onSliderChange = useCallback(
    ({ start, end }) => {
      const newTimes = { start, end };

      if (!isEqual(newTimes, times)) {
        const { key } = find(timesConfig, ({ value }) => isEqual(newTimes, value)) || { key: null };
        setTimes(newTimes);
        handleSetTourParameter({
          time: {
            key,
            value: newTimes,
          },
        });
        AmplitudeService.log(AMPLITUDE_EVENTS.TIME_CONFIG_CHANGED);
      }
    },
    [handleSetTourParameter, setTimes, times],
  );

  const onTimeChange = useCallback(
    timeKey => {
      const { value } = find(timesConfig, ({ key }) => timeKey === key) || {};
      if (value) {
        setTimes(value);
        handleSetTourParameter({ time: { key: timeKey, value } });
      }
      AmplitudeService.log(AMPLITUDE_EVENTS.TIME_CONFIG_CHANGED);
    },
    [handleSetTourParameter, setTimes],
  );

  const onAmountChange = useCallback(
    e => {
      const value = e.target.value && parseInt(e.target.value, 10);
      setFleetAmount(value);
      e.target.focus();
    },
    [setFleetAmount],
  );

  const onAmountBlur = useCallback(() => {
    const value = parseInt(fleetAmount, 10);
    if (!Number.isNaN(value) && value >= 1 && value <= maxValues.vehicles) {
      handleSetTourParameter({ vehicle: { amount: value, capacity } });
    } else {
      setFleetAmount(amount);
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_VEHICLE_NUMBER);
  }, [handleSetTourParameter, setFleetAmount, amount, capacity, fleetAmount]);

  const onCapacityChange = useCallback(
    e => {
      const value = e.target.value && parseInt(e.target.value, 10);
      setFleetCapacity([value]);
      e.target.focus();
    },
    [setFleetCapacity],
  );

  const onCapacityBlur = useCallback(() => {
    const value = parseInt(fleetCapacity[0], 10);
    if (!Number.isNaN(value) && value >= 1 && value <= maxValues.capacity) {
      handleSetTourParameter({ vehicle: { capacity: [value], amount } });
    } else {
      setFleetCapacity([capacity]);
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_CAPACITY);
  }, [handleSetTourParameter, setFleetCapacity, capacity, amount, fleetCapacity]);

  const onLocationFound = useCallback(
    loc => {
      setNoDepotFound(!loc.value && !isEmpty(loc.label));
      handleSetTourParameter({ location: { ...loc } });
      if (loc && loc.value) AmplitudeService.log(AMPLITUDE_EVENTS.DEPOT_SET);
    },
    [handleSetTourParameter, setNoDepotFound],
  );

  const onCompanyNameSet = useCallback(
    name => {
      handleSetTourParameter({ companyName: name });
      AmplitudeService.log(AMPLITUDE_EVENTS.COMPANY_NAME_SET);
    },
    [handleSetTourParameter],
  );

  const onDefaultFleetConfigChange = useCallback(
    e => {
      handleSetTourParameter({ fleetMode: e.target.value });
      AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_FLEET_MODE, {
        fleetMode: e.target.value,
      });
    },
    [handleSetTourParameter],
  );

  return (
    <StyledWizardContent>
      <>
        <CompanyName onSet={onCompanyNameSet} initialValue={companyName} />
        {isEmpty(companyName) && (
          <ReadMoreLess
            short={companyNameDescShort}
            long={companyNameDescLong}
            origin="companyName"
          />
        )}
        <StyledLine />
      </>
      <StyledWizardH1>{depotMarkerTrans}</StyledWizardH1>
      <Section>
        {!noDepotFound && <Label>{depotLabelTrans}</Label>}
        {noDepotFound && <Label error>{depotNotFound}</Label>}
        <InputLocation
          id="input-depot-loca"
          placeholder={depotPlaceholderTrans}
          value={location.label}
          oAuth={oAuth}
          onLocationFound={onLocationFound}
          user={user}
          tourPlanner={tourPlanner}
          hasCurrentLocationControl
        />
      </Section>

      <Section>
        <FleetReturnAddress oAuth={oAuth} user={user} tourPlanner={tourPlanner} />
      </Section>

      <StyledLine />
      <StyledWizardH1AndSelector>
        <StyledWizardH1>{configurationLabelTrans}</StyledWizardH1>
        <div>
          <StyledSelectedInputLabel>{fleetModeTitle}</StyledSelectedInputLabel>
          <StyledSelectBigInput
            onChange={onDefaultFleetConfigChange}
            value={fleetMode}
            icon={fleetModeIcons[`${fleetMode}`]}
          >
            <option value="solo">{fleetModeSoloTitle}</option>
            <option value="custom">{fleetModeCustomTitle}</option>
          </StyledSelectBigInput>
        </div>
      </StyledWizardH1AndSelector>
      {fleetMode === FLEET_MODES.SOLO && (
        <StyledDescriptionContainer>
          <StyledDescriptionElement>
            <StyledTextLight>{fleetModeExplain}</StyledTextLight>
          </StyledDescriptionElement>
          <StyledDescriptionElement>
            <StyledIcon icon={StyledIconAmountStyle} sizeL margin="0 .5rem 0 1rem" />
            <StyledTextLight>{vehiclesAmountLabel}:</StyledTextLight>
            <StyledText>1</StyledText>
          </StyledDescriptionElement>
          <StyledDescriptionElement>
            <StyledIcon iconUrl={iconCapacity} sizeL margin="0 .5rem 0 1rem" />
            <StyledTextLight>{vehiclesCapacityLabel}:</StyledTextLight>
            <StyledText>{fleetModeUnlimited}</StyledText>
          </StyledDescriptionElement>
        </StyledDescriptionContainer>
      )}
      {fleetMode === FLEET_MODES.CUSTOM && (
        <Section>
          <StyledDoublePanel>
            <div>
              <Label>{isMobile ? vehiclesAmountLabelMobile : vehiclesAmountLabel}</Label>
              <StyledTextInput
                id="input-fleet-amount"
                icon={iconAmount}
                small
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                min="1"
                max={maxValues.vehicles}
                onChange={onAmountChange}
                value={fleetAmount}
                onBlur={onAmountBlur}
              />
            </div>
            <div>
              <Label>{isMobile ? vehiclesCapacityLabelMobile : vehiclesCapacityLabel}</Label>
              <StyledTextInput
                id="input-fleet-capacity"
                icon={iconCapacity}
                small
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                min="1"
                max={maxValues.capacity}
                onChange={onCapacityChange}
                value={fleetCapacity[0]}
                onBlur={onCapacityBlur}
              />
            </div>
          </StyledDoublePanel>
        </Section>
      )}
      {fleetMode === FLEET_MODES.CUSTOM && (
        <Section>
          <Section>
            <Label>{timeLabelTrans}</Label>
            <Selector
              id="button-time-window"
              initialValue={timeTourParamKey}
              options={timeOptions}
              onChange={onTimeChange}
            />
          </Section>
          <TimeRangeSlider
            id="slider-time-window"
            onChange={onSliderChange}
            timeAggregation={3}
            times={times}
          />
        </Section>
      )}
    </StyledWizardContent>
  );
};

export default withTranslation(Step1Container);
