import moment from 'moment';
import { last, isEmpty, keys, size } from 'lodash';
import { getOrdersByStop } from './OrdersHelpers';

const getHeader = (tourID, tripID) => {
  return {
    trip_id: tripID,
    title: 'WeGo Deliver Test Drive',
    subtitle: moment().format('YYY-MM-DD'),
    image: '',
  };
};

const getStopLink = (stop, previous, index, tripId) => {
  const values = {
    tripId,
    activityId: index + 1,
    to: `${stop.end_location[0]},${stop.end_location[1]}`,
  };
  if (previous) {
    values.from = `${previous.end_location[0]},${previous.end_location[1]}`;
  }
  const parts = keys(values).map(k => `${k}=${values[`${k}`]}`);
  return `wego:guidance?${parts.join('&')}`;
};

const getLocationFromOrder = order => {
  return {
    location: {
      lat: order.Latitude,
      lng: order.Longitude,
    },
    address: order.Address,
  };
};

const getDepotInfo = (orders, tourPlanner) => {
  if (tourPlanner.location.label) {
    return {
      location: { ...tourPlanner.location.value },
      address: tourPlanner.location.label,
    };
  }

  return getLocationFromOrder(orders[0]);
};

const getStopInfo = (stop, index, depot, ordersInStop) => {
  const loc = !isEmpty(ordersInStop) ? getLocationFromOrder(ordersInStop[0]) : depot;
  return {
    actions: [
      {
        title: 'Call recipient',
        href: 'tel:+4916012345678',
      },
      {
        title: 'Need parking?',
        href: `wego:parking?to=${loc.location.lat},${loc.location.lon}`,
      },
    ],
    activity_id: `${index + 1}`,
    end_location: [loc.location.lat, loc.location.lng],
    title: loc.address,
    subtitle: isEmpty(ordersInStop) ? 'Depot' : `${size(ordersInStop)} Order(s)`,
    deadline: stop.time.arrival,
  };
};

const getStops = (tour, depot, orders, tripID) => {
  const stopsInfo = tour.stops.map((stop, index) => {
    const ordersInStop = getOrdersByStop(orders, stop);
    return getStopInfo(stop, index, depot, ordersInStop);
  });
  return stopsInfo.map((stop, index) => {
    const previous = index > 0 && stopsInfo[index - 1];
    return {
      ...stop,
      href: getStopLink(stop, previous, index, tripID),
    };
  });
};

export function exportToWeGo(solution, tourID) {
  const sol = last(solution.iterations);
  const tour = sol.response.tours[`${tourID}`];
  const tourPlanner = sol.request.tourPlanner;
  const orders = sol.request.orders;
  const depot = getDepotInfo(orders, tourPlanner);
  const tripID = `Tour-${tourID}`;
  const header = getHeader(tourID, tripID);
  const activities = getStops(tour, depot, orders, tripID);
  return { ...header, activities };
}
