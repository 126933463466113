import { head, includes } from 'lodash';
import config from '../config';
import { getUrlLanguage } from './urlHelpers';
import { getStorageValue, STORAGE_IDS } from './localStorageHelpers';

export default function getInitialLanguage() {
  const userFromStorage = getStorageValue(STORAGE_IDS.userState);
  const langFromStorage = userFromStorage && userFromStorage.language;
  const fromUrl = getUrlLanguage();
  const browser = navigator && navigator.language ? navigator.language.substr(0, 2) : null;
  const locale = fromUrl || langFromStorage || browser;
  const { languages } = config;
  return includes(languages, locale) ? locale : head(languages);
}
