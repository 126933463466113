import styled from '@emotion/styled';
import RangeSlider from 'components/Form/RangeSlider';
import config from 'config';
import { colors, fontSize } from 'global/variables';
import { isNumber, isNull, map, noop, range, size } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { times as timesHelper } from 'utils/helpers';
import PropTypes from 'utils/PropTypes';
import { formatTimeLabelLocale } from '../../../utils/timeFormatHelpers';

const {
  scenario: { start: startTime, end: endTime },
} = config;

const DEFAULT_AGGREGATION = 1;
const DEFAULT_TIME = map(
  range(
    parseInt(startTime, 10) + DEFAULT_AGGREGATION,
    parseInt(endTime, 10) + DEFAULT_AGGREGATION,
    DEFAULT_AGGREGATION,
  ),
  t => `${t}`,
);
const DEFAULT_SCALE = size(DEFAULT_TIME);

const { primaryBorderColor } = colors;
const { smaller } = fontSize;

const StyledScale = styled.div`
  display: flex;
  margin: 0 0.5rem;
  height: 0.75rem;
  position: relative;
`;

const StyledStrong = styled.strong`
  width: 1.2rem;
  font-size: ${smaller};
  margin: 0;
  display: inline-block;
  padding: 0;
`;

const StyledItem = styled.div`
  flex: 1 1 0;
  text-align: right;

  &:nth-of-type(odd) {
    margin-top: 0.5rem;
    border-right: 2px solid ${primaryBorderColor};
  }

  &:nth-of-type(even) {
    border-right: 2px solid transparent;
    :after {
      content: ${props => `'${props.text}'`};
      font-size: ${smaller};
      font-weight: 700;
      left: 1.1rem;
      position: relative;
    }
  }
`;

const FirstStrong = styled(StyledStrong)`
  width: 2rem;
  position: absolute;
  left: -0.5rem;
  padding: 0;
  top: 1px;
  font-weight: 700;
`;

const Scale = ({ startRange, time }) => (
  <StyledScale>
    <FirstStrong>{formatTimeLabelLocale(startRange)}</FirstStrong>
    {timesHelper(size(time), i => (
      <StyledItem key={i} text={formatTimeLabelLocale(time[Number(i)])} />
    ))}
  </StyledScale>
);

const TimeRangeSlider = ({
  aggregation, // time difference on slider: 1 - 1 hour : 3 - 3 hours
  disabled,
  endRange,
  onChange,
  timeAggregation, // labels difference on scale: 1 - 6am, 7am, 8am, ... 21pm, 22pm, 23pm : 3 - 6am, 9am, 12am, ... 18pm, 21pm, 24pm
  startRange,
  times, // { start, end }
}) => {
  const [scale, setScale] = useState(DEFAULT_SCALE); // amount of elements on slider  - (endRange - startRange) / aggregation
  const [time, setTime] = useState(DEFAULT_TIME); // labels on scale - (endRange - startRange) / timeAggregation
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);

  useEffect(() => {
    const parsedStartRange = parseInt(startRange, 10);
    const parsedEndRange = parseInt(endRange, 10);

    if (
      isNumber(aggregation) &&
      aggregation > 0 &&
      isNumber(parsedStartRange) &&
      isNumber(parsedEndRange) &&
      parsedEndRange - parsedStartRange > aggregation
    ) {
      setScale(
        size(
          map(
            range(parsedStartRange + aggregation, parsedEndRange + aggregation, aggregation),
            hour => `${hour}`,
          ),
        ),
      );

      if (isNumber(timeAggregation) && timeAggregation > 0) {
        setTime(
          map(
            range(
              parsedStartRange + timeAggregation,
              parsedEndRange + timeAggregation,
              timeAggregation,
            ),
            t => `${t}`,
          ),
        );
      }
    }
  }, [aggregation, endRange, setScale, setTime, startRange, timeAggregation]);

  useEffect(() => {
    const parsedStartRange = parseInt(startRange, 10);
    const parsedEndRange = parseInt(endRange, 10);

    const { start, end } = times;
    const parsedStartValue = parseInt(start, 10);
    const parsedEndValue = parseInt(end, 10);

    if (
      isNumber(parsedStartValue) &&
      parsedStartValue >= parsedStartRange &&
      parsedStartValue < parsedEndValue &&
      parsedStartValue < parsedEndRange
    ) {
      setStartIndex((parsedStartValue - parsedStartRange) / aggregation);
    }
    if (
      isNumber(parsedEndValue) &&
      parsedEndValue > parsedStartRange &&
      parsedEndValue > parsedStartValue &&
      parsedEndValue <= parsedEndRange
    ) {
      setEndIndex((parsedEndRange - parsedEndValue) / aggregation);
    }
  }, [aggregation, endRange, times, startRange, setEndIndex, setStartIndex]);

  const onSliderChange = useCallback(
    (beginIndex = 0, finishIndex = 0) => {
      onChange({
        start: startTime + beginIndex * aggregation,
        end: endTime - finishIndex * aggregation,
      });
    },
    [aggregation, onChange],
  );

  return (
    <>
      {isNull(startIndex) ? (
        <></>
      ) : (
        <RangeSlider
          disabled={disabled}
          endIndex={endIndex}
          scale={scale}
          onChange={onSliderChange}
          startIndex={startIndex}
        >
          <Scale startRange={startRange} time={time} />
        </RangeSlider>
      )}
    </>
  );
};

TimeRangeSlider.propTypes = {
  aggregation: PropTypes.number,
  disabled: PropTypes.bool,
  endRange: PropTypes.number,
  onChange: PropTypes.func,
  timeAggregation: PropTypes.number,
  startRange: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  times: PropTypes.object,
};

TimeRangeSlider.defaultProps = {
  aggregation: DEFAULT_AGGREGATION,
  disabled: false,
  endRange: endTime,
  onChange: noop,
  timeAggregation: DEFAULT_AGGREGATION,
  startRange: startTime,
  times: { start: startTime, end: endTime },
};

export default TimeRangeSlider;
