import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearOrders,
  parseAndProcessCSVFile,
  setLoader,
  setTourParameter,
  setUploadedFile,
} from 'actions';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, size } from 'lodash';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import {
  StyledWizardContent,
  StyledWizardH1,
  StyledIcon,
  StyledFullWidthContainer,
  StyledDoublePanel,
  StyledText,
  StyledLine,
  JobForTitleIconStyle,
} from '../Global/WizardStyled';
import { canDownloadCSV, getAsset, isMobileDevice } from '../../../utils/helpers';
import FileUploadArea from './FileUploadArea';
import Section from '../../Form/Section';
import downloadIcon from '../../../global/img/download.svg';
import trashIcon from '../../../global/img/trash.svg';
import trashIconHover from '../../../global/img/trash_hover.svg';
import iconSettings from '../../../global/img/settings.svg';
import Orders from './Orders';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import Selector from '../../Form/Selector';
import { APP_MODES, getAppMode } from "../../../utils/urlHelpers";
import Label from '../../Form/Label';
import OrdersDemo from './OrdersDemo';
import OrdersManual from './OrdersManual';
import OrdersUrl from './OrdersUrl';
import { getOrdersFromDiscoverItems } from '../../../utils/GeoCoder';
import { saveCSVTemplate } from '../../../utils/CSVTemplateExport';
import OrdersNotLocated from './OrdersNotLocated';
import OrdersCSVConfig from './OrdersCSVConfig';
import { isCSVUnderstood } from '../../../utils/csv/ManualConfigHelpers';
import { saveOrdersToCSVFile } from '../../../utils/SolutionExportToCsv';

const { primaryTextColor, primaryColor, primaryColorHovered, secondaryTextColor } = colors;
const { small } = fontSize;
const appMode = getAppMode();

const StyledRemoveLink = styled.div({
  display: 'inline-block',
  cursor: 'pointer',
  div: {
    marginLeft: '2rem',
    transition: 'all 500ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  },
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  '&:hover': {
    div: {
      backgroundImage: `url(${getAsset(trashIconHover)})`,
    },
  },
});

const StyledLabelLeft = styled.div({
  marginBottom: '0.25rem',
  color: primaryTextColor,
  fontSize: small,
  textAlign: 'left',
  width: '300px',
  lineHeight: '1rem',
  '> div': {
    textTransform: 'uppercase',
    cursor: 'pointer',
    div: {
      float: 'right',
    },
    marginTop: '6px',
    fontWeight: '700',
    padding: '7px 9px 6px 12px',
    display: 'inline-block',
    width: '120px',
    borderRadius: '50px',
    border: `solid 1px ${primaryColorHovered} !important`,
    boxShadow: `inset 0px 0px 0px .1px ${primaryColor} !important`,
    color: secondaryTextColor,
    '&:hover': {
      border: `solid 1px ${primaryColor} !important`,
      boxShadow: `inset 0px 0px 0px 1px ${primaryColor} !important`,
    },
  },
});

const DownloadIconStyle = css`
  background-image: url(${getAsset(downloadIcon)});
  width: 1rem !important;
  height: 1rem !important;
  background-size: 0.85rem !important;
  background-position-y: 0px;
`;

const RemoveAllIconStyle = css`
  background-image: url(${getAsset(trashIcon)});
  background-position: center right;
  background-size: 1.1rem;
  width: 1.2rem;
  height: 1.2rem;
`;

const StyledEmptyOrders = styled.div({
  height: '100%',
  padding: '4rem 0 10rem 0',
  textAlign: 'center',
  div: {
    textAlign: 'center',
    margin: 'auto',
    fontWeight: 400,
    color: primaryTextColor,
  },
});

const StyledImportSettingsContainer = styled.div({
  textAlign: 'right',
  fontSize: small,
  color: secondaryTextColor,
  margin: '-1.2rem 0 0 0',
  lineHeight: '1rem',
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontWeight: 500,
  div: {
    position: 'relative',
    top: '2px',
  },
});

const StyledDownloadOrdersContainer = styled.div({
  cursor: 'pointer',
  display: 'inline-block',
});

const Step2Container = ({
  oAuth,
  user,
  orders,
  ordersNotLocated,
  errorState,
  tourPlanner,
  uploadedFile,
  onConfigToggle,
  translations: {
    wizard: {
      download,
      orders: {
        ordersTitle,
        templateLabel,
        removeAll,
        ordersAvailable,
        modesTitle,
        modes: { importTrans, manualTrans, demoTrans },
        emptyOrders,
        importSettings,
      },
    },
    csv,
    error,
  },
}) => {
  const dispatch = useDispatch();
  const handleSetLoader = useCallback(data => dispatch(setLoader(data)), [dispatch]);
  const handleSetTourParameter = useCallback(p => dispatch(setTourParameter(p)), [dispatch]);
  const handleSetFile = useCallback(data => dispatch(setUploadedFile(data)), [dispatch]);
  const handleProcessCSVFile = useCallback(
    (data, mode) => dispatch(parseAndProcessCSVFile(data, error, orders, mode)),
    [dispatch, error, orders],
  );
  const handleClearOrders = useCallback(() => {
    dispatch(clearOrders());
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_DELETE_ALL);
    if (tourPlanner.ordersMode === 'demo') {
      const ordersMode = isMobileDevice() ? 'manual' : 'import';
      handleSetTourParameter({ ordersMode });
    }
  }, [dispatch, tourPlanner, handleSetTourParameter]);
  const [requiresCSVConfig, setRequiresCSVConfig] = useState(false);

  useEffect(() => {
    onConfigToggle(requiresCSVConfig);
  }, [requiresCSVConfig, onConfigToggle]);

  const onCloseCSVConfig = useCallback(() => {
    setRequiresCSVConfig(false);
  }, [setRequiresCSVConfig]);

  const isUnderstood = useCallback(
    file => {
      return isCSVUnderstood(file, tourPlanner, user, csv);
    },
    [tourPlanner, user, csv],
  );

  useEffect(() => {
    if (!uploadedFile) return;

    isUnderstood(uploadedFile.file).then(isClear => {
      if (isClear) {
        handleProcessCSVFile(uploadedFile.file, uploadedFile.mode);
        handleSetFile();
        return;
      }

      setRequiresCSVConfig(true);
    });
  }, [uploadedFile, handleProcessCSVFile, handleSetFile, setRequiresCSVConfig, isUnderstood]);

  const ordersModes = useMemo(
    () => [
      { key: 'import', value: importTrans },
      { key: 'manual', value: manualTrans },
      { key: 'demo', value: demoTrans },
    ],
    [importTrans, manualTrans, demoTrans],
  );

  const ordersModesUX = useMemo(
    () => [
      { key: 'import', value: importTrans },
      { key: 'manual', value: manualTrans },
      { key: 'url', value: 'Url' },
      { key: 'demo', value: demoTrans },
    ],
    [importTrans, manualTrans, demoTrans],
  );

  const onOrdersModeChange = useCallback(
    ordersMode => {
      handleSetTourParameter({ ordersMode, editedOrder: {} });
    },
    [handleSetTourParameter],
  );

  useEffect(() => {
    if (tourPlanner.ordersMode === 'demo' && errorState) {
      const ordersMode = isMobileDevice() ? 'manual' : 'import';
      handleSetTourParameter({ ordersMode });
    }
  }, [errorState, tourPlanner.ordersMode, handleSetTourParameter]);

  const onDownloadTemplate = useCallback(() => {
    handleSetLoader({ isLoading: true });
    getOrdersFromDiscoverItems('restaurant', oAuth, user, tourPlanner).then(items => {
      saveCSVTemplate(items);
      handleSetLoader({ isLoading: false });
    });
  }, [oAuth, user, tourPlanner, handleSetLoader]);

  const onClickSettings = useCallback(() => {
    setRequiresCSVConfig(true);
  }, [setRequiresCSVConfig]);

  const onDownloadOrders = useCallback(() => {
    saveOrdersToCSVFile(orders, user, 'Orders2Plan -');
  }, [orders, user]);

  const demoIndex = appMode !== APP_MODES.UX ? 2 : 3;
  const showTemplate = canDownloadCSV();

  return (
    <StyledWizardContent>
      {requiresCSVConfig && (
        <OrdersCSVConfig
          uploadedFile={uploadedFile}
          tourPlanner={tourPlanner}
          orders={orders}
          onClose={onCloseCSVConfig}
          oAuth={oAuth}
          user={user}
        />
      )}
      {!requiresCSVConfig && (
        <>
          <StyledWizardH1>{ordersTitle}</StyledWizardH1>
          <Section>
            <Label>{modesTitle}</Label>
            <Selector
              id="button-orders-mode"
              initialValue={tourPlanner.ordersMode}
              options={appMode === APP_MODES.UX ? ordersModesUX : ordersModes}
              onChange={onOrdersModeChange}
              disabledIndexes={!isEmpty(orders) || !oAuth ? [demoIndex] : []}
            />
          </Section>
          {tourPlanner.ordersMode === 'import' && (
            <Section>
              {isEmpty(ordersNotLocated) && (
                <>
                  {showTemplate && (
                    <StyledLabelLeft hasOrders={!isEmpty(orders)}>
                      {templateLabel}
                      <br />
                      <div id="link-template-download" onClick={onDownloadTemplate}>
                        {download}
                        <StyledIcon icon={DownloadIconStyle} mini />
                      </div>
                    </StyledLabelLeft>
                  )}
                  {user && user.cookies.accept && !isEmpty(tourPlanner.csvConfig) && (
                    <StyledImportSettingsContainer onClick={onClickSettings}>
                      {importSettings}&nbsp;
                      <StyledIcon iconUrl={iconSettings} />
                    </StyledImportSettingsContainer>
                  )}
                  <FileUploadArea orders={orders} errorState={errorState} oAuth={oAuth} />
                </>
              )}
              {!isEmpty(ordersNotLocated) && (
                <OrdersNotLocated ordersNotLocated={ordersNotLocated} user={user} />
              )}
            </Section>
          )}
          {tourPlanner.ordersMode === 'demo' && (
            <OrdersDemo orders={orders} oAuth={oAuth} user={user} tourPlanner={tourPlanner} />
          )}
          {tourPlanner.ordersMode === 'manual' && (
            <OrdersManual oAuth={oAuth} user={user} tourPlanner={tourPlanner} orders={orders} />
          )}
          {tourPlanner.ordersMode === 'url' && (
            <OrdersUrl orders={orders} errorState={errorState} />
          )}
          {!isEmpty(orders) && (
            <>
              <StyledLine />
              <Section>
                <StyledDoublePanel extremes useMax marginTop>
                  <StyledText id="label-orders-info">
                    <StyledIcon icon={JobForTitleIconStyle} />
                    {`${ordersAvailable}: ${size(orders)}`}
                  </StyledText>
                  <div>
                    {canDownloadCSV() && tourPlanner.ordersMode === 'manual' && (
                      <StyledDownloadOrdersContainer title={download} onClick={onDownloadOrders}>
                        <StyledIcon icon={DownloadIconStyle} />
                      </StyledDownloadOrdersContainer>
                    )}
                    <StyledRemoveLink
                      id="link-remove-orders"
                      onClick={handleClearOrders}
                      title={removeAll}
                    >
                      <StyledIcon icon={RemoveAllIconStyle} />
                    </StyledRemoveLink>
                  </div>
                </StyledDoublePanel>
              </Section>
              <StyledFullWidthContainer>
                <Orders orders={orders} tourPlanner={tourPlanner} allowRemove />
              </StyledFullWidthContainer>
            </>
          )}
          {isEmpty(orders) && tourPlanner.ordersMode === 'manual' && (
            <>
              <StyledLine />
              <Section>
                <StyledFullWidthContainer>
                  <StyledEmptyOrders>
                    <StyledText>{emptyOrders}</StyledText>
                  </StyledEmptyOrders>
                </StyledFullWidthContainer>
              </Section>
            </>
          )}
        </>
      )}
    </StyledWizardContent>
  );
};

export default withTranslation(Step2Container);
