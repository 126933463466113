import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader, setOrdersFromUrl } from 'actions';
import withTranslation from 'hoc/withTranslation';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import Label from '../../Form/Label';
import { StyledTextInput } from '../Global/WizardStyled';

const StyledUrlContent = styled.div({
  width: '100%',
});

const OrdersUrl = ({ orders, errorState, translations: { error } }) => {
  const dispatch = useDispatch();
  const handleSetLoader = useCallback(data => dispatch(setLoader(data)), [dispatch]);
  const handleSetOrdersFromUrl = useCallback(
    url => {
      dispatch(setOrdersFromUrl(url, error, orders, 'url'));
    },
    [dispatch, error, orders],
  );

  const onBlurUrl = useCallback(
    e => {
      const url = e.target.value;
      if (isEmpty(url)) return;

      handleSetLoader({ isLoading: true });
      handleSetOrdersFromUrl(url, 'url');
    },
    [handleSetOrdersFromUrl, handleSetLoader],
  );

  const onKeyDown = useCallback(e => {
    if (e.keyCode === 13) e.target.blur();
  }, []);

  useEffect(() => {
    handleSetLoader({ isLoading: false });
  }, [orders, handleSetLoader, errorState]);

  return (
    <StyledUrlContent>
      <Label>Url</Label>
      <StyledTextInput id="input-import-orders-url" onBlur={onBlurUrl} onKeyDown={onKeyDown} />
    </StyledUrlContent>
  );
};

export default withTranslation(OrdersUrl);
