import React, { useState, useRef, useEffect, useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { StyledWizardH1 } from '../Global/WizardStyled';
import { colors, fontSize } from '../../../global/variables';
import editIcon from '../../../global/img/edit-dark.svg';

const StyledCompanyName = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
});

const StyledLabel = styled(StyledWizardH1)`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ isEmpty }) => (isEmpty ? 0.5 : 1)};
  white-space: ${({ singleLine }) => (singleLine ? 'nowrap' : 'unset')};
  cursor: pointer;

  &:hover + button {
    opacity: 1;
  }
`;

const StyledInput = styled.input(({ visible }) => ({
  width: '100%',
  visibility: visible ? 'visible' : 'hidden',
  position: 'absolute',
  // Align text withing the input with the label
  top: '0.685rem',
  left: 0,
  border: 'none',
  margin: 0,
  padding: 0,
  backgroundColor: 'transparent',
  fontSize: fontSize.normal,
  color: colors.secondaryTextColor,
  fontWeight: '700',
  fontFamily: 'FiraGO',
  fontSmooth: 'antialiased',
}));

const StyledEditButton = styled.button(({ visible, highlighted }) => ({
  visibility: visible ? 'visible' : 'hidden',
  opacity: highlighted ? 0.8 : 0.4,
  border: 'none',
  backgroundColor: 'transparent',
  backgroundImage: `url(${editIcon})`,
  backgroundRepeat: 'no-repeat',
  width: '1rem',
  height: '1rem',
  position: 'relative',
  top: '0.75rem',
  marginLeft: '0.375rem',
  transitionProperty: 'opacity',
  transitionDuration: '0.15s',
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },
}));

function CompanyName({ onSet, initialValue = '', translations }) {
  const [name, setName] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const returnValue = useCallback(() => {
    if (!onSet) return;
    onSet(name);
  }, [onSet, name]);

  useEffect(() => {
    if (editing && inputRef.current !== null) inputRef.current.focus();
    else if (!editing) returnValue();
  }, [editing, returnValue]);

  const startEditing = () => setEditing(true);
  const stopEditing = () => setEditing(false);
  const onInputKeyDown = event => {
    if (event.key === 'Enter') {
      stopEditing();
    }
  };
  const onNameChange = event => setName(event.currentTarget.value);

  return (
    <StyledCompanyName>
      <StyledLabel
        visible={!editing}
        isEmpty={name === ''}
        onClick={startEditing}
        singleLine={editing}
      >
        {name === '' ? translations.wizard.fleet.companyNameLabel : name}
      </StyledLabel>
      <StyledEditButton
        visible={!editing}
        highlighted={name === ''}
        disabled={editing}
        onClick={startEditing}
      />
      <StyledInput
        ref={inputRef}
        visible={editing}
        defaultValue={name}
        onChange={onNameChange}
        onBlur={stopEditing}
        onKeyDown={onInputKeyDown}
      />
    </StyledCompanyName>
  );
}

export default withTranslation(CompanyName);
