import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { increaseUserUsage, shareTourById } from 'actions';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { head, last, size, isEmpty } from 'lodash';
import { getNumberOfDepotStops, getTotalJobsInTour } from 'utils/SolutionHelpers';

import moment from 'moment';
import iconShare from 'global/img/share.svg';
import iconDetails from 'global/img/details.svg';
import Button from '../../Form/Button';
import { StopIconStyle, JobIconStyle, StyledIcon } from '../Global/WizardStyled';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import { getTourDemand } from '../../../utils/OrdersHelpers';
import TourDispatch from '../TourDispatch/TourDispatch';
import { createTourShareData } from '../../../utils/SolutionExport';
import { isMobileDevice } from '../../../utils/helpers';
import { getUrlInitialTourIndex } from '../../../utils/urlHelpers';

const initialTourIndex = getUrlInitialTourIndex();

const {
  secondaryBorderColor,
  secondaryTextColor,
  primaryTextColor,
  aqua,
  transparentGreyColor,
  black,
  white,
} = colors;
const { medium } = fontSize;

const StyledTour = styled.div(
  {
    padding: '0.75rem 0.2rem 0 0.2rem',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '2.5rem',
    borderBottom: `1px solid ${secondaryBorderColor}`,
    backgroundColor: transparentGreyColor,
    textRendering: 'geometricPrecision',
  },
  ({ isActive }) => ({
    backgroundColor: isActive && 'rgba(255,255,255,0.5)',
  }),
);

const StyledTourSpan = styled.div(
  {
    color: secondaryTextColor,
    fontSize: medium,
    position: 'relative',
  },
  ({ isActive, withButtons }) => ({
    fontWeight: isActive && '700',
    textAlign: withButtons && 'right !important',
  }),
);

const StyledButtonContainer = styled.div({
  width: '3rem',
  display: 'inline-block',
});

const StyledTimesWrapper = styled(StyledTourSpan)`
  line-height: 1.25rem;

  div {
    display: block;
    color: ${secondaryTextColor};
  }

  div:last-of-type {
    color: ${primaryTextColor};
  }
`;

const StyledActiveBorder = styled(StyledTour)(({ isActive }) => ({
  transition: 'all 250ms cubic-bezier(0.600, 0.005, 0.320, 1.000)',
  backgroundColor: isActive && aqua,
}));

const StyledTourDispatchContainer = styled.div(
  {
    position: 'absolute',
    top: '-7px',
    right: '2.8rem',

    padding: '0.35rem 0.75rem 0.35rem 1.5rem',
    backgroundColor: black,
    color: white,
    fontWeight: 500,
    borderRadius: '0.2rem',
    textAlign: 'right',

    flexDirection: 'row',
    transition: 'all 50ms ease-in',
    boxShadow: '0px 0px 20px 0px rgba(255,255,255,1), 0px 0px 40px 0px rgba(255,255,255,1)',
    lineHeight: '44px',
    div: {
      marginLeft: '6px',
    },
  },
  ({ isShareVisible }) => ({
    opacity: isShareVisible ? 0.95 : 0,
    display: isShareVisible ? 'flex' : 'none',
    width: isShareVisible ? 'max-content' : 0,
  }),
);

const Tour = withTranslation(
  ({
    isActive,
    onClickTour,
    tour,
    index,
    onTourChange,
    solution,
    tourPlanner,
    user,
    sharedTours,
    request,
    translations,
  }) => {
    const dispatch = useDispatch();
    const handleIncreaseUsage = useCallback(data => dispatch(increaseUserUsage(data)), [dispatch]);
    const handleShareTour = useCallback(data => dispatch(shareTourById(data)), [dispatch]);
    const [isShareVisible, setIsShareVisible] = useState(false);
    const [isShareInProgress, setIsShareInProgress] = useState(false);
    const canShare = navigator.share;
    const {
      map,
      tourSolution: { viewTour, tourDetails },
      wizard: {
        toursDetails: { shareTour, sharing, toursDetailsTitle },
      },
      csv: { phoneTrans },
    } = translations;

    const translationsToCopy = {
      ...map,
      ...sharing,
      ...tourDetails,
      phoneTrans,
      toursDetailsTitle,
    };

    useEffect(() => {
      if (!isActive) setIsShareVisible(false);
    }, [isActive, setIsShareVisible]);

    const onTourChangeClick = useCallback(
      t => {
        onTourChange();
        const tourInfo = {
          stopsInTour: t.stops.length - getNumberOfDepotStops(t.stops),
          ordersInTour: getTotalJobsInTour(t.stops),
          dropsInTour: getTourDemand(t, request),
          totalDistanceInTour: t.statistic.distance,
          totalDurationInTour: t.statistic.duration,
          totalCostInTour: t.statistic.cost,
        };
        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_VIEW, tourInfo);
      },
      [onTourChange, request],
    );

    const onClick = useCallback(() => {
      if (isMobileDevice()) onTourChangeClick(tour);
      onClickTour();
    }, [onClickTour, onTourChangeClick, tour]);

    const logToAmplitude = useCallback(
      mode => {
        const tourInfo = {
          stopsInTour: tour.stops.length - getNumberOfDepotStops(tour.stops),
          ordersInTour: getTotalJobsInTour(tour.stops),
          dropsInTour: getTourDemand(tour, request),
          dispatchMode: mode,
          origin: 'solution details',
          isDemo: user.isLastOrdersAddedDemo,
        };
        handleIncreaseUsage({ ordersDispatched: tourInfo.ordersInTour, mode });
        handleShareTour(index);
        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, tourInfo);
      },
      [tour, request, index, user, handleShareTour, handleIncreaseUsage],
    );

    const onTourShareClick = useCallback(
      evt => {
        if (isMobileDevice()) {
          evt.preventDefault();
          evt.stopPropagation();
        }

        if (!canShare) {
          setIsShareVisible(!isShareVisible);
          return;
        }

        const titleArr = ['HERE WeGo Deliver'];
        if (tourPlanner && !isEmpty(tourPlanner.companyName))
          titleArr.unshift(tourPlanner.companyName);
        const title = titleArr.join(' & ');
        const shareData = createTourShareData(tour, request, translationsToCopy, tourPlanner, user);
        navigator
          .share({
            title,
            text: shareData.text,
          })
          .then(() => {
            logToAmplitude('share');
          })
          .catch();
      },
      [
        tour,
        request,
        tourPlanner,
        user,
        logToAmplitude,
        canShare,
        translationsToCopy,
        setIsShareVisible,
        isShareVisible,
      ],
    );

    const onTourSharing = useCallback(
      isSharing => {
        setIsShareInProgress(isSharing);
        if (!isSharing) setIsShareVisible();
      },
      [setIsShareInProgress],
    );

    return (
      <>
        <StyledTour isActive={isActive} onClick={onClick} />
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTourSpan isActive={isActive}>{index + initialTourIndex}</StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick} />
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTourSpan isActive={isActive}>
            <StyledIcon icon={JobIconStyle} /> {getTotalJobsInTour(tour.stops)}
          </StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTourSpan isActive={isActive}>
            <StyledIcon icon={StopIconStyle} />
            {size(tour.stops) - getNumberOfDepotStops(tour.stops)}
          </StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTimesWrapper isActive={isActive}>
            <div>{moment(head(tour.stops).time.departure).format('LT')}</div>
            <div>{moment(last(tour.stops).time.departure).format('LT')}</div>
          </StyledTimesWrapper>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTourSpan isActive={isActive} withButtons>
            {!isMobileDevice() && (
              <StyledButtonContainer>
                <Button
                  inverted={!isActive}
                  small
                  id={`button-view-tour-${index}`}
                  onClick={() => onTourChangeClick(tour)}
                  icon={iconDetails}
                  title={viewTour}
                />
              </StyledButtonContainer>
            )}
            <StyledButtonContainer>
              <Button
                inverted
                checked={sharedTours.includes(index)}
                small
                id={`button-tour-share-${index}`}
                onClick={onTourShareClick}
                icon={iconShare}
                title={shareTour}
              />
            </StyledButtonContainer>
            <StyledTourDispatchContainer isShareVisible={isShareInProgress || isShareVisible}>
              {shareTour}
              <TourDispatch
                user={user}
                selectedTours={[index]}
                solution={solution}
                tourPlanner={tourPlanner}
                tour={tour}
                request={request}
                origin="solution details"
                contrast
                onStartShare={() => onTourSharing(true)}
                onFinishShare={() => onTourSharing(false)}
              />
            </StyledTourDispatchContainer>
          </StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick} />
        <StyledActiveBorder isActive={isActive} onClick={onClick} />
      </>
    );
  },
);

export default withTranslation(Tour);
