import { first, includes, isEmpty } from "lodash";
import { latLng, latLngBounds } from "leaflet";
import { calculateBBox } from "../GeoJSONConverter";
import { isRouteToDisplay } from "./MapFeatureFilters";

export const toBounds = bbox => latLngBounds(latLng(bbox[1], bbox[0]), latLng(bbox[3], bbox[2]));

export const getBounds = (mapData, user, display, routingRoutes) => {
  if (!mapData) {
    const defaultBbox = [
      13.35608790453967,
      52.51150757589767,
      13.42011969807939,
      52.55139198324973,
    ];
    const bbox =
      user && user.location
        ? calculateBBox([[user.location.longitude, user.location.latitude]])
        : defaultBbox;
    return toBounds(bbox);
  }

  if (isEmpty(display.routeIds)) return toBounds(mapData.geo.bbox);

  const active = routingRoutes &&
    routingRoutes.features.filter(f => isRouteToDisplay(f, display));

  if (!isEmpty(active)) return toBounds(first(active).bbox);

  return toBounds(
    mapData.geo.features
      .filter(
        f =>
          f.geometry.type === 'LineString' &&
          includes(display.routeIds, f.properties.routeId),
      )
      .map(route => route.bbox)
      .reduce(
        (acc, bbox) => {
          acc[0] = Math.min(bbox[0], acc[0]);
          acc[1] = Math.min(bbox[1], acc[1]);
          acc[2] = Math.max(bbox[2], acc[2]);
          acc[3] = Math.max(bbox[3], acc[3]);
          return acc;
        },
        [180, 90, -180, -90],
      ),
  );
};

export const mapDataKey = (mapData, user, display, routingRoutes) => {
  const featuresQty = routingRoutes && routingRoutes.features.length;
  const key = {
    mapData,
    routingRoutes: featuresQty,
    ...display,
    user,
  };
  return JSON.stringify(key);
};

