import React, { useCallback, useState } from "react";
import { isEmpty, keys, last } from "lodash";
import withTranslation from "../../../hoc/withTranslation";
import { StyledTextArea, StyledTextInput } from "../../Wizard/Global/WizardStyled";
import Label from "../../Form/Label";
import {
  StyledQuestionCheckboxContainer,
  StyledQuestionContainer,
  StyledQuestionLabelContainer,
  StyledQuestionTextBoxContainer,
} from "./SurveyCommon";
import { validateEmail } from "../../../utils/helpers";

const SurveyQuestion = ({ index, question, topic, value, valueExtra, onChange, onNext, QUESTIONS_AMOUNT, translations }) => {
  const [hasError, setHasError] = useState()
  const { survey: { surveyLabels } } = translations

  const isValidValue = useCallback(() => {
    if (!question.isEmail) return true

    const isValid = validateEmail(value)
    setHasError(!isValid)
    return isValid
  }, [question, value])

  const handleOnChange = useCallback((key) => {
    setHasError()
    if (!onChange) return

    let res = key.target ? key.target.value : key
    if (!question.isUnique && question.options) {
      const resOriginal = value || []
      res = resOriginal.includes(res) ? resOriginal.filter(r => r !== res) : [...resOriginal, res]
    }
    onChange(topic, res, valueExtra)
  }, [onChange, topic, value, valueExtra, question])

  const handleOnChangeExtra = useCallback((key) => {
    if (!onChange) return

    let newValue = value
    if (isEmpty(value)) {
      newValue = last(keys(question.options))
      if (!question.isUnique) newValue = [newValue]
    }

    const res = key.target.value
    onChange(topic, newValue, res)
  }, [onChange, topic, value, question])


  const handleOnKeyPress = useCallback(event => {
    if (event.keyCode === 13 && !isEmpty(event.target.value) && onNext && isValidValue()) onNext()
  }, [onNext])

  const handleOnBlur = useCallback(() => {
    isValidValue()
  })

  return (
    <StyledQuestionContainer>
      <StyledQuestionLabelContainer>
        {index < QUESTIONS_AMOUNT && (<Label direction="vertical">
          {surveyLabels.questionLabel} {index} / {QUESTIONS_AMOUNT - 1}
        </Label>)}
      </StyledQuestionLabelContainer>
      <StyledQuestionLabelContainer>
        <p>
          {question.label}
          {question.options && !question.isUnique && (
            <span><br/>{surveyLabels.multiple}</span>
          )}
        </p>
      </StyledQuestionLabelContainer>
      {question.options ? (
        <>
          {keys(question.options).map(key => (
            <StyledQuestionCheckboxContainer onClick={() => handleOnChange(key, question.options[`${key}`])} key={key}>
              <input
                checked={value ? (question.isUnique ? value === key : value.includes(key)) : false}
                className="visible-checkbox"
                type={question.isUnique ? 'radio' : 'checkbox'}
                name={`question-${index}`}
                id={`question-${index}-${key}`}
                onChange={() => handleOnChange(key, question.options[`${key}`])}
              />
              <label htmlFor={`question-${index}`}>{question.options[`${key}`]}</label>
            </StyledQuestionCheckboxContainer>
          ))}
          {question.hasExtraInfo && (
            <StyledQuestionTextBoxContainer>
              <StyledTextInput
                value={valueExtra || ''}
                onChange={handleOnChangeExtra}
                onKeyDown={handleOnKeyPress}
                placeholder={question.extraPlaceholder || ''}
              />
            </StyledQuestionTextBoxContainer>
          )}
        </>
      ) : !question.isLongText ? (
        <StyledQuestionTextBoxContainer>
          <StyledTextInput
            withMarginBottom
            value={value || ''}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyPress}
            onBlur={handleOnBlur}
            placeholder={question.placeholder}
          />
          {hasError && (<Label error direction="vertical">{question.errorMessage}</Label>)}
        </StyledQuestionTextBoxContainer>
      ) : (
        <StyledQuestionTextBoxContainer>
          <StyledTextArea onChange={handleOnChange} />
        </StyledQuestionTextBoxContainer>
      )}
    </StyledQuestionContainer>
  );
};

export default withTranslation(SurveyQuestion);
