import {
  SELECT_TOUR_BY_ID,
  GET_ROUTING_TOUR,
  CLEAR_MAP_DATA,
  GET_SOLUTION,
  CLEAR_SOLUTION,
} from 'actions/index';
import { RoutingResponseToSolverFormat } from 'utils/RoutingConverter';
import { getStateFromMemory, setStateToMemory } from '../utils/MemoryHelpers';
import { STORAGE_IDS } from '../utils/localStorageHelpers';

const defaultState = null;
const stateID = STORAGE_IDS.routingTour;
const initialState = getStateFromMemory(stateID, defaultState);

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TOUR_BY_ID: {
      const newState = { ...state };
      delete newState.requestError;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case GET_ROUTING_TOUR: {
      const newState = { ...state };
      if (action.error) {
        newState.requestError = true;
        setStateToMemory(stateID, newState);
        return newState;
      }

      const converted = RoutingResponseToSolverFormat(action);
      if (converted) {
        newState[converted.tourId] = converted;
        setStateToMemory(stateID, newState);
        return newState;
      }

      return state;
    }
    case CLEAR_MAP_DATA: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    case CLEAR_SOLUTION: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    case GET_SOLUTION: {
      const newState = action.error ? state : null;
      setStateToMemory(stateID, newState);
      return newState;
    }
    default:
      return state;
  }
};
