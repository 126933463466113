import { SET_PROBLEM, GET_SOLUTION, SET_ERROR, CLEAR_SOLUTION, GET_OAUTH } from 'actions';

export default (state = null, action) => {
  switch (action.type) {
    case SET_ERROR:
      if (!action.payload) return null;
      if (typeof action.payload === 'string') {
        return { errorMessage: action.payload };
      }
      return action.payload;
    case SET_PROBLEM:
      return null;
    case CLEAR_SOLUTION:
      return null;
    case GET_SOLUTION:
      if (action.error) {
        return {
          errorCode: 'contactTrans',
        };
      }
      return null;
    case GET_OAUTH:
      if (action.error) {
        return {
          errorCode: 'fatalError',
          isFatal: true,
        };
      }
      return null;
    default:
      return state;
  }
};
