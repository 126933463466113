import styled from '@emotion/styled';
import Map from 'components/Map/Map';
import Modal from 'components/Presentation/Modal';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, last } from 'lodash';
import { getToursDataFromIteration, isIterationSolution } from 'utils/SolutionHelpers';
import DownloadSolution from '../Wizard/Global/DownloadSolution';
import { APP_MODES, getAppMode, getUrlTest } from '../../utils/urlHelpers';
import Wizard from '../Wizard/Wizard';
import { isMobileDevice } from '../../utils/helpers';
import CookieNotification from '../FloatingPanels/CookieNotification';

const isMobile = isMobileDevice();
const isTest = getUrlTest();
const appMode = getAppMode();

const StyledContainer = styled.div`
  position: relative;
`;

const MapContainer = ({ user, oAuth, showIntro, showMapMobile }) => {
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const mapData = useSelector(({ mapData: stateMapData }) => stateMapData);
  const { display } = useSelector(({ mapContainer }) => mapContainer);
  const routingTour = useSelector(({ routingTour: stateRoutingTour }) => stateRoutingTour);
  const [tourData, setTourData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const initialize = useCallback(() => {
    const allIterations = solution && solution.iterations ? solution.iterations : null;
    const lastIter = allIterations ? allIterations.slice(-1)[0] : null;
    setTourData(getToursDataFromIteration(lastIter));
    setIsInitialized(true);
  }, [solution, setTourData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    initialize();
  }, [initialize]);

  useEffect(() => initialize(), [solution, initialize]);

  const lastIteration = solution && last(solution.iterations);
  const isSolution = isIterationSolution(lastIteration);
  const data = isSolution ? lastIteration.geoJSON : mapData;
  const showDownloadToApp =
    appMode === APP_MODES.DEVELOPER && isSolution && !isEmpty(display.routeIds);
  const showDownload =
    appMode === APP_MODES.DEVELOPER &&
    solution &&
    solution.iterations &&
    solution.iterations.length > 0 &&
    solution.iterations[solution.iterations.length - 1].request &&
    solution.iterations[solution.iterations.length - 1].request.json &&
    solution.iterations[solution.iterations.length - 1].request.json.plan.jobs.length > 0;
  const showWizard = isMobileDevice() ? !showMapMobile : true;
  const showMapCanvas = appMode !== APP_MODES.HARP ? true : oAuth;

  return (
    <StyledContainer>
      {!showIntro && isInitialized && (
        <Modal isVisible={showWizard}>
          <Wizard user={user} oAuth={oAuth} tourData={tourData} />
        </Modal>
      )}
      {showMapCanvas && (
        <Map
          isSolution={isSolution}
          display={display}
          mapData={data}
          changes={[]}
          routingData={routingTour}
          tourData={tourData}
          user={user}
          oAuth={oAuth}
        />
      )}
      {!isMobile && showDownload && (
        <DownloadSolution solution={solution} />
      )}
      {!isMobile && showDownloadToApp && (
        <DownloadSolution index="2" solution={solution} tourID={display.routeIds[0]} mode="app" />
      )}
      {!isMobile && showDownloadToApp && (
        <DownloadSolution index="3" solution={solution} tourID={display.routeIds[0]} mode="wg" />
      )}
      {!isTest && user.cookies.display && !showIntro && <CookieNotification user={user} />}
    </StyledContainer>
  );
};

export default MapContainer;
