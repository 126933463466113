import React from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import stopLine from 'global/img/selected-route-line.svg';
import stopLineUnselected from 'global/img/unselected-route-line.svg';
import { getAsset } from '../../../utils/helpers';
import { StyledInfoEllipsis } from '../Global/WizardStyled';

const { secondaryTextColor, secondaryMarkerColor, transparentGreyColor, white } = colors;
const { small } = fontSize;

const StyledRouteSpan = styled.div(
  {
    color: secondaryTextColor,
    backgroundRepeat: 'repeat-y',
    backgroundPosition: 'center center',
  },
  ({ isActive, isLastStop }) => ({
    backgroundImage: isLastStop
      ? ''
      : isActive
      ? `url(${getAsset(stopLine)})`
      : `url(${getAsset(stopLineUnselected)})`,
    backgroundColor: isActive ? white : transparentGreyColor,
    cursor: isActive ? 'normal' : 'pointer',
  }),
);

const StyledStopDetails = styled.div({
  lineHeight: '1rem',
  padding: '0.2rem',
  color: secondaryTextColor,
  fontSize: small,
  backgroundColor: white,
  paddingBottom: '0.5rem',
  animation: 'EntryAnimation .3s 1',
  animationTimingFunction: 'ease-out',
  fontWeight: '700',
});

const StyledStopDetailsSpan = styled.div({
  textAlign: 'left !important',
  width: '100%',
});

const StyledStopDetailsSpanTruncated = styled(StyledInfoEllipsis)({
  textAlign: 'left !important',
  maxWidth: '4rem',
});

const StyledStopDetailsSpanHeader = styled.div({
  paddingTop: '0.6rem',
  paddingBottom: '0',
  textTransform: 'uppercase',
  textAlign: 'left !important',
  width: '100%',
  color: secondaryMarkerColor,
  animation: 'EntryAnimationHeader .3s 1 !important',
  animationTimingFunction: 'ease-out',
  fontWeight: '400',
});

const StopDetail = ({ order, isLastStop, isDemandVisible }) => (
  <>
    <StyledRouteSpan isActive isLastStop={isLastStop} />
    <StyledStopDetails>
      <StyledStopDetailsSpan title={order.Address}>{order.Name}</StyledStopDetailsSpan>
    </StyledStopDetails>
    <StyledStopDetails>{isDemandVisible ? order.Demand || 1 : ''}</StyledStopDetails>
    <StyledStopDetails>
      <StyledStopDetailsSpanTruncated title={order.ID}>
        {order.ID || '-'}
      </StyledStopDetailsSpanTruncated>
    </StyledStopDetails>
    <StyledStopDetails />
    <StyledStopDetails />
  </>
);

const StopDetails = withTranslation(
  ({
    orders,
    isLastStop,
    demandLabel,
    isDemandVisible,
    translations: {
      wizard: {
        toursDetails: { dropsByCustomer },
      },
    },
  }) => {
    return (
      <>
        <>
          <StyledRouteSpan isActive isLastStop={isLastStop} />
          <StyledStopDetails>
            <StyledStopDetailsSpanHeader>{dropsByCustomer}</StyledStopDetailsSpanHeader>
          </StyledStopDetails>
          <StyledStopDetails>
            <StyledStopDetailsSpanHeader>{demandLabel}</StyledStopDetailsSpanHeader>
          </StyledStopDetails>
          <StyledStopDetails>
            <StyledStopDetailsSpanHeader>ID</StyledStopDetailsSpanHeader>
          </StyledStopDetails>
          <StyledStopDetails />
          <StyledStopDetails />
        </>
        {orders &&
          orders.map((order, i) => (
            <StopDetail
              order={order}
              key={i}
              isLastStop={isLastStop}
              isDemandVisible={isDemandVisible}
            />
          ))}
      </>
    );
  },
);

export default withTranslation(StopDetails);
