import config from 'config';
import axios from 'axios';
import { get, isEmpty, uniqBy, slice } from 'lodash';
import { v4 } from 'uuid';
import { getStorageValue, STORAGE_IDS } from './localStorageHelpers';

const {
  geocoder: { url: geocoderUrl, autoSuggestUrl, discoverUrl, reverseUrl },
} = config;

const cache = {};

const getAtLocation = (user, tourPlanner) => {
  const defaultLocation = { lat: 52.530858, lng: 13.384744 };
  let atLocation = (tourPlanner && tourPlanner.location.value) || user.location || defaultLocation;
  if (atLocation.latitude) atLocation = { lat: atLocation.latitude, lng: atLocation.longitude };
  return atLocation;
};

const getAt = atLocation => {
  if (atLocation && atLocation.lat && atLocation.lng) return `${atLocation.lat},${atLocation.lng}`;
  const stored = getStorageValue(STORAGE_IDS.tourPlanner);
  const storedLoc = get(stored, 'location.value');
  return storedLoc ? `${storedLoc.lat},${storedLoc.lng}` : null;
};

const getUrl = (baseUrl, query, atLocation, limit = 1, extra) => {
  const storedUser = getStorageValue(STORAGE_IDS.userState);
  const lang = storedUser ? storedUser.language : 'en';
  const at = getAt(atLocation);
  let url = `${baseUrl}?limit=${limit}&lang=${lang}`;
  url = query ? `${url}&q=${encodeURIComponent(query)}` : url;
  url = at ? `${url}&at=${at}` : url;
  url = extra ? `${url}&${extra}` : url;
  return url;
};

const serializePosition = ({ lat, lng }) => {
  return `${lat.toFixed(4)} ${lng.toFixed(4)}`;
};

const getUniqueLocations = locations => {
  const positionMap = locations.reduce((result, item) => {
    const position = serializePosition(item.position);

    if (result.has(position)) {
      return result;
    }

    result.set(position, item);

    return result;
  }, new Map());

  return Array.from(positionMap.values());
};

export const getGeocodeRequestsByAddress = (addresses, { tokenType, accessToken }, atLocation) => {
  return addresses.map(query =>
    cache[`${query}`]
      ? Promise.resolve(cache[`${query}`])
      : axios.get(getUrl(geocoderUrl, query, atLocation), {
          headers: {
            Authorization: `${tokenType} ${accessToken}`,
          },
        }),
  );
};

export const addToCache = (key, object) => {
  if (!key || !object || isEmpty(object) || !object.data || isEmpty(object.data.items)) return;
  if (!cache[`${key}`]) cache[`${key}`] = object;
};

export const getAddressesLocation = (addresses, oAuth, atLocation) => {
  const requests = getGeocodeRequestsByAddress(addresses, oAuth, atLocation);
  return Promise.all(requests).then(res => {
    return res.map((response, i) => {
      const item = response.data.items[0];
      const label = addresses[Number(i)];
      addToCache(label, { data: response.data, request: response.request });
      return {
        label,
        position: item && item.position,
      };
    });
  });
};

export const getAddressLocation = (address, oAuth) =>
  getAddressesLocation([address], oAuth)
    .then(res => res[0])
    .catch(() => ({ label: address }));

export function getLocationAddress(location, oAuth) {
  return axios
    .get(getUrl(reverseUrl, null, location), {
      headers: {
        Authorization: `${oAuth.tokenType} ${oAuth.accessToken}`,
      },
    })
    .then(({ data }) => data.items[0]);
}

export const getItems = (
  baseUrl,
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
  limit,
  extra,
) => {
  const atLocation = getAtLocation(user, tourPlanner);
  return axios
    .get(getUrl(baseUrl, address, atLocation, limit * 3, extra), {
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    })
    .then(res => {
      if (!res || !res.data || isEmpty(res.data.items)) return [];
      const valid = res.data.items.filter(i => !!i.position);
      const unique = uniqBy(valid, item => item.title);
      return slice(unique, 0, limit);
    });
};

export const getAutoSuggestItems = (
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
  limit = 5,
) => {
  if (!address || !accessToken) return { then: () => [] };
  return getItems(
    autoSuggestUrl,
    address,
    { tokenType, accessToken },
    user,
    tourPlanner,
    limit,
    'show=position',
  )
    .then(res => {
      return res.map(item => ({
        label: item.title,
        value: { ...item.position },
      }));
    })
    .catch(() => getAddressesLocation([address], { tokenType, accessToken }));
};

export const getDiscoverItems = (
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
  limit = 20,
) => {
  return getItems(discoverUrl, address, { tokenType, accessToken }, user, tourPlanner, limit);
};

export const createIDFromNumber = (number, length = 3) => {
  if (!number) return v4();

  const start = '00000000n';
  const temp = start.replace('n', number);
  return temp.substr(temp.length - length);
};

export const getOrdersFromDiscoverItems = (
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
) => {
  return getDiscoverItems(address, { tokenType, accessToken }, user, tourPlanner)
    .then(res => {
      return getUniqueLocations(res).map((item, index) => ({
        Address: item.address.label.replace(item.title, '').replace(', ', ''),
        Name: item.title,
        Latitude: item.access[0].lat,
        Longitude: item.access[0].lng,
        Demand: 1,
        'Service time (min)': 5,
        ID: createIDFromNumber(index + 1),
        InternalID: v4(),
      }));
    })
    .catch(() => []);
};
