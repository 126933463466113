import React from "react";
import styled from '@emotion/styled';
import withTranslation from "../../../hoc/withTranslation";
import Button from "../../Form/Button";
import { StyledButtonContainer } from "./SurveyCommon";
import { StyledDoublePanel } from "../../Wizard/Global/WizardStyled";

export const StyledQuestionContainer = styled.div({
  padding: '0.75rem 0 0 0',
  p: {
    marginBottom: '2rem',
  },
})

const StyledEndContainer = styled.div({
  textAlign: 'center',
  paddingBottom: '1rem',
  p: {
    lineHeight: '1.3rem',
  },
})

const SurveyEnd = ({ onDone, onBack, hasContactInfo, translations }) => {
  const { survey: { surveyLabels } } = translations

  return (
    <StyledEndContainer>
      <StyledQuestionContainer>
        {hasContactInfo && (<p>{surveyLabels.thankYou}</p>)}
        <p>{surveyLabels.thankYouContact}</p>
        <p>
          <a href="mailto:wegodeliver@here.com" target="_blank" rel="noopener noreferrer">wegodeliver@here.com</a>
        </p>
      </StyledQuestionContainer>
      {hasContactInfo ? (
        <StyledButtonContainer>
          <Button text={surveyLabels.buttonDone} onClick={onDone} />
        </StyledButtonContainer>

      ) : (
        <StyledDoublePanel marginTop extremes>
          <div>
            <Button text={surveyLabels.buttonBack} inverted onClick={onBack} />
          </div>
          <div>
            <Button text={surveyLabels.buttonDone} onClick={onDone} />
          </div>
        </StyledDoublePanel>
      )}
    </StyledEndContainer>
  );
};

export default withTranslation(SurveyEnd);
