import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, keys, reduce, size } from 'lodash';
import { canDownloadCSV, getAsset, isMobileDevice, times } from 'utils/helpers';
import { getLastValidIteration, getTotalJobsInTours } from 'utils/SolutionHelpers';

import jobIcon from 'global/img/mini-order.svg';
import unassignedJobIcon from 'global/img/mini-order-unassigned.svg';
import vehicleIcon from 'global/img/truck-black.svg';
import unassignedVehicleIcon from 'global/img/unassigned-truck.svg';
import { StyledButtonSmall } from '../Global/WizardStyled';
import { saveUnassignedToCSVFile } from '../../../utils/SolutionExportToCsv';
import downloadIcon from '../../../global/img/download.svg';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { FLEET_MODES, increaseUserUsage } from '../../../actions';
import { exportToursToCSV } from '../../../utils/SolutionExport';
import { getUrlInitialTourIndex } from '../../../utils/urlHelpers';

const { small, normal } = fontSize;
const initialTourIndex = getUrlInitialTourIndex();

const StyledSummary = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  justify-content: space-between;
`;

const StyledTitle = styled.p`
  text-transform: uppercase;
  margin: 0 0.6rem 0 0;
  font-size: ${small};
  opacity: 0.75;
  display: inline-block;
`;

const StyledText = styled.strong`
  font-size: ${normal};
  line-height: 2rem;
  display: block;
`;

const StyledVehicleIconSize = '1.25rem';
const vehicleIconStyle = css`
  width: ${StyledVehicleIconSize};
  height: ${StyledVehicleIconSize};
  background-size: ${StyledVehicleIconSize} ${StyledVehicleIconSize};
  background-image: url(${getAsset(vehicleIcon)});
  margin-right: 0.3rem;
`;

const unassignedVehicleIconStyle = css`
  width: ${StyledVehicleIconSize};
  height: ${StyledVehicleIconSize};
  background-size: ${StyledVehicleIconSize} ${StyledVehicleIconSize};
  background-image: url(${getAsset(unassignedVehicleIcon)});
  margin-right: 0.3rem;
`;

const jobIconStyle = css`
  background-image: url(${getAsset(jobIcon)});
  width: 14px;
  height: 14px;
  background-size: 12px;
`;

const unassignedJobIconStyle = css`
  background-image: url(${getAsset(unassignedJobIcon)});
  width: 14px;
  height: 14px;
  background-size: 12px;
`;

const StyledIcon = styled.div(
  {
    width: '.9rem',
    height: '.9rem',
    backgroundSize: '.8rem .8rem',
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    marginRight: '0',
  },
  ({ icon }) => ({
    ...icon,
  }),
);

const StyledDetailContainer = styled.div(({ noMarginBottom }) => ({
  marginRight: '1rem',
  marginTop: '-0.8rem',
  marginBottom: !noMarginBottom && '2rem',
}));

const StyledDownloadContainer = styled.div(({ noMarginTop }) => ({
  marginTop: !noMarginTop && '0.8rem',
}));

const StyledReview = styled(StyledButtonSmall)`
  margin-right: 7px;
  text-transform: uppercase;
`;

const DownloadIconStyle = css`
  background-image: url(${getAsset(downloadIcon)});
  width: 1rem !important;
  height: 1rem !important;
  background-size: 0.85rem !important;
  background-position-y: 0px;
  float: right;
  margin-left: 11px;
`;

const SummaryWithDownload = ({
  orders,
  tourPlanner,
  user,
  solution,
  lastIterationTourData: { fleet, tours, unassigned, uploaded },
  translations: {
    tourSolution: { jobTrans, vehicleTrans, preposition },
    wizard: {
      download,
      tours: { unassignedDownloadTitle },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleIncreaseUsage = useCallback(data => dispatch(increaseUserUsage(data)), [dispatch]);
  const [numberOfVehicles, setNumberOfVehicles] = useState(0);
  const [totalNumberOfVehicles, setTotalNumberOfVehicles] = useState(0);
  const [numberOfUnassigned, setNumberOfUnassigned] = useState(0);
  const [numberOfAssigned, setNumberOfAssigned] = useState(0);

  const request = solution && getLastValidIteration(solution).request;

  useEffect(() => {
    const vehicles = size(
      keys(
        reduce(
          tours,
          (acc, { vehicleId }) => {
            acc[`${vehicleId}`] = true;
            return acc;
          },
          {},
        ),
      ),
    );
    const definedVehicles =
      uploaded || !fleet ? 0 : fleet.types.reduce((total, type) => total + type.amount, 0);
    const totalVehicles = tourPlanner.fleetMode === FLEET_MODES.SOLO ? vehicles : definedVehicles;

    setNumberOfVehicles(vehicles);
    setNumberOfUnassigned(size(keys(unassigned)));
    setNumberOfAssigned(getTotalJobsInTours(tours));
    setTotalNumberOfVehicles(totalVehicles);
  }, [setNumberOfAssigned, setNumberOfUnassigned, tours, unassigned, fleet, uploaded, tourPlanner]);

  const onClickDownloadAssigned = useCallback(() => {
    const ordersDispatched = getTotalJobsInTours(tours);
    const info = {
      dispatchMode: 'csv-all',
      origin: 'solution details',
      tours: size(tours),
      ordersInTour: ordersDispatched,
      isDemo: user.isLastOrdersAddedDemo,
    };

    AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, info);
    handleIncreaseUsage({ ordersDispatched, toursDispatched: size(tours), mode: 'csv-all' });

    exportToursToCSV(tours, request, tourPlanner, user, initialTourIndex);
  }, [tours, tourPlanner, user, request, handleIncreaseUsage]);

  const onClickDownloadUnassigned = useCallback(() => {
    const info = {
      assignedOrders: numberOfAssigned,
      unassignedOrders: numberOfUnassigned,
    };

    if (!isEmpty(unassigned)) {
      const grouped = {};
      unassigned.forEach(item => {
        item.reasons.forEach(reason => {
          if (!grouped[`${reason.code}`]) grouped[`${reason.code}`] = [];
          grouped[reason.code].push(item);
        });
      });
      keys(grouped).forEach(key => {
        info[`unassignedOrdersCode${key}`] = size(grouped[`${key}`]);
      });
    }

    AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DOWNLOAD_UNASSIGNED, info);
    saveUnassignedToCSVFile(unassigned, orders, user);
  }, [orders, unassigned, user, numberOfUnassigned, numberOfAssigned]);

  const MAX_ICONS = isMobileDevice() ? 20 : 80;
  const MAX_ICONS_VEHICLES = isMobileDevice() ? 10 : 20;

  const showIcons =
    numberOfAssigned + numberOfUnassigned <= MAX_ICONS &&
    totalNumberOfVehicles <= MAX_ICONS_VEHICLES;
  const vehiclesText =
    tourPlanner.fleetMode === FLEET_MODES.SOLO
      ? numberOfVehicles
      : `${numberOfVehicles} ${preposition} ${totalNumberOfVehicles}`;

  return (
    <StyledSummary>
      <StyledGroup>
        {numberOfAssigned > 0 && (
          <StyledDetailContainer>
            <div>
              <StyledTitle>{vehicleTrans}</StyledTitle>
              <StyledText>{vehiclesText}</StyledText>
            </div>
            {showIcons && (
              <div>
                <>
                  {times(numberOfVehicles, i => (
                    <StyledIcon icon={vehicleIconStyle} key={i} />
                  ))}
                </>
                <>
                  {times(totalNumberOfVehicles - numberOfVehicles, i => (
                    <StyledIcon icon={unassignedVehicleIconStyle} key={i} />
                  ))}
                </>
              </div>
            )}
          </StyledDetailContainer>
        )}
        {numberOfAssigned > 0 && (
          <StyledDetailContainer>
            <div>
              <StyledTitle>{jobTrans}</StyledTitle>
              <StyledText>
                {numberOfAssigned} {preposition} {numberOfAssigned + numberOfUnassigned}
              </StyledText>
            </div>
            {showIcons && (
              <div>
                {times(numberOfAssigned, i => (
                  <StyledIcon icon={jobIconStyle} key={i} />
                ))}
                {times(numberOfUnassigned, i => (
                  <StyledIcon icon={unassignedJobIconStyle} key={i} />
                ))}
              </div>
            )}
            {canDownloadCSV() && (
              <StyledDownloadContainer>
                <StyledReview
                  id="button-download-assigned"
                  title={`${download} CSV`}
                  onClick={onClickDownloadAssigned}
                >
                  <StyledIcon icon={DownloadIconStyle} mini />
                  <StyledIcon icon={jobIconStyle} mini />
                </StyledReview>
                {numberOfUnassigned > 0 && (
                  <StyledReview
                    id="button-download-unassigned"
                    title={unassignedDownloadTitle}
                    onClick={onClickDownloadUnassigned}
                  >
                    <StyledIcon icon={DownloadIconStyle} mini />
                    <StyledIcon icon={unassignedJobIconStyle} mini />
                  </StyledReview>
                )}
              </StyledDownloadContainer>
            )}
          </StyledDetailContainer>
        )}
      </StyledGroup>
    </StyledSummary>
  );
};

export default withTranslation(SummaryWithDownload);
