import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLoader,
  setError,
  submitProblem,
  setProblem,
  setMapData,
  clearMapData,
  clearSolution,
  increaseUserUsage,
  recordUsageEvent,
  USAGE_EVENTS,
  selectTourById,
  getRoutingTourInfo,
} from 'actions';
import { last, isEmpty, head } from 'lodash';
import { generateVRPPayload, convertParsedCSVToVRPJobs } from 'utils/VRPConverter';
import { convertProblemToGeoJSON } from 'utils/GeoJSONConverter';
import WizardHeader from './Global/WizardHeader';
import WizardFooter from './Global/WizardFooter';
import Step1Container from './Step1/Step1Container';
import Step2Container from './Step2/Step2Container';
import Step3Container from './Step3/Step3Container';
import Step4Container from './Step4/Step4Container';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../utils/amplitude';
import { getStorageValue, STORAGE_IDS } from '../../utils/localStorageHelpers';
import Notifications from './Global/Notifications';
import { getClusters } from '../../utils/csv/clusters';
import { tourToRoutingRequest } from '../../utils/RoutingConverter';
import { isSolutionMonoTour, isSolutionStored } from '../../utils/MemoryHelpers';

const getInitialStep = () => {
  if (isSolutionStored()) return isSolutionMonoTour() ? 4 : 3;

  const initialTourPlanner = getStorageValue(STORAGE_IDS.tourPlanner);
  return initialTourPlanner ? 2 : 1;
};

const initialStep = getInitialStep();

const StyledContainer = styled.div`
  height: 100%;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
`;

const StyledWizardContent = styled.div`
  position: relative;
  height: ${props => !props.noHeader ? 'calc(100% - 9rem)' : 'calc(100% - 4.5rem)'};
  box-sizing: border-box;
  overflow-y: ${props => props.noHeader ? 'hidden' : 'auto'};
  overflow-x: hidden;
  z-index: 200;
`;

const STEPS_IN_HEADER = 3;

const Wizard = ({ user, oAuth, tourData }) => {
  const dispatch = useDispatch();
  const tourPlanner = useSelector(({ tourPlanner: stateTourPlanner }) => stateTourPlanner);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const orders = useSelector(state => state.orders);
  const ordersNotLocated = useSelector(state => state.ordersNotLocated);
  const errorState = useSelector(state => state.error);
  const {
    display: { routeIds, sharedRouteIds },
  } = useSelector(({ mapContainer: stateMapContainer }) => stateMapContainer);
  const uploadedFile = useSelector(state => state.uploadedFile);
  const handleSetLoader = useCallback(data => dispatch(setLoader(data)), [dispatch]);
  const handleSubmitProblem = useCallback(problem => dispatch(submitProblem(problem)), [dispatch]);
  const handleSetError = useCallback(data => dispatch(setError(data)), [dispatch]);
  const handleIncreaseUsage = useCallback(data => dispatch(increaseUserUsage(data)), [dispatch]);
  const handleSetProblem = useCallback(problem => dispatch(setProblem(problem)), [dispatch]);
  const handleSetMapData = useCallback(mapData => dispatch(setMapData(mapData)), [dispatch]);
  const handleClearMapData = useCallback(() => dispatch(clearMapData()), [dispatch]);
  const handleClearSolution = useCallback(() => dispatch(clearSolution()), [dispatch]);
  const handleRecordUsage = useCallback(data => dispatch(recordUsageEvent(data)), [dispatch]);
  const handleSelectTourById = useCallback(index => dispatch(selectTourById(index)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest({ ...tour, routeId: index }),
        }),
      ),
    [dispatch, oAuth],
  );
  const wizardContentRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(initialStep);
  const [isConfigVisible, setIsConfigVisible] = useState(false);

  const handleSetStep = useCallback(
    toStep => {
      setStep(toStep);
      handleSetError(null);
      handleRecordUsage({ event: USAGE_EVENTS.WIZARD_SET_STEP, step: toStep });
      wizardContentRef.current.scrollTo(0, 0);
    },
    [setStep, handleSetError, handleRecordUsage],
  );

  const triggerVRPRequest = useCallback(
    (allOrders, config) => {
      const wizardDepot = !isEmpty(config.location.value) ? config.location.value : null;
      const wizardReturnLocation = !isEmpty(config.returnLocation)
        ? config.returnLocation.value
        : null;
      const returnLocationAddress = !isEmpty(config.returnLocation)
        ? config.returnLocation.label
        : null;
      const rows = convertParsedCSVToVRPJobs(allOrders);
      const depot = wizardDepot || rows[0];
      const newOrders = wizardDepot ? rows : rows.slice(1);
      const vrpPayload = generateVRPPayload(newOrders, depot, '', tourPlanner, allOrders);
      const depotAddress =
        (config.location.value && config.location.value.lat && config.location.label) ||
        (allOrders && allOrders[0] && allOrders[0].Address);
      const toSet = {
        tourPlanner,
        orders: allOrders,
        json: vrpPayload,
        uploaded: true,
      };

      const ordersToCluster = wizardDepot ? allOrders : allOrders.slice(1);
      const clusters = getClusters(ordersToCluster, tourPlanner);
      if (clusters) {
        const rowsCluster = convertParsedCSVToVRPJobs(clusters, true);
        toSet.clusters = clusters;
        toSet.json = generateVRPPayload(rowsCluster, depot, '', tourPlanner, allOrders);
      }

      handleSetProblem(toSet);
      const geo = convertProblemToGeoJSON(
        vrpPayload,
        depot,
        wizardReturnLocation,
        allOrders,
        depotAddress,
        returnLocationAddress,
        config.editedOrder,
      );
      if (geo) {
        handleSetMapData({ geo });
      } else {
        handleClearMapData();
      }

      return toSet;
    },
    [handleSetMapData, handleClearMapData, handleSetProblem, tourPlanner],
  );

  useEffect(() => {
    const allIterations = solution && solution.iterations ? solution.iterations : null;
    const lastIteration = allIterations ? last(allIterations) : null;

    if (lastIteration && !lastIteration.isProcessing) {
      handleSetLoader({ isLoading: false });
      setIsSubmitting(false);
    }
  }, [solution, handleSetLoader]);

  const wizardSubmitProblem = useCallback(
    (vrpReq, timeout = 500) => {
      if (!vrpReq) return;
      if (!orders || !orders.length) return;

      setIsSubmitting(true);
      handleSetLoader({ isLoading: true });
      setTimeout(() => {
        handleSubmitProblem({ ...vrpReq, user });
      }, timeout);
    },
    [setIsSubmitting, handleSetLoader, handleSubmitProblem, user, orders],
  );

  const onStepChange = useCallback(
    toStep => {
      let finalStep = toStep;
      if (step === 2) {
        if (toStep === 3 || toStep === 4) {
          const hasSolution = !!tourData;
          if (!hasSolution) {
            const config = { ...tourPlanner };
            delete config.editedOrder;
            const vrpReq = triggerVRPRequest(orders, config);
            wizardSubmitProblem(vrpReq);
            handleIncreaseUsage({ toursPlanned: 1 });
            AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_PLAN, {
              isDemo: user.isLastOrdersAddedDemo,
              orderNumber: orders.length,
            });
            return;
          }
        }
      } else if (step === 3) {
        if (toStep < step) handleClearSolution();
      } else if (step === 4) {
        if (toStep < step) {
          const isMonoSolution = isSolutionMonoTour(tourData);
          if (isMonoSolution) handleClearSolution();
          finalStep = isMonoSolution ? 2 : 3;
        }
      }

      handleSetStep(finalStep);
    },
    [
      user,
      step,
      wizardSubmitProblem,
      handleSetStep,
      handleClearSolution,
      orders,
      tourPlanner,
      tourData,
      triggerVRPRequest,
      handleIncreaseUsage,
    ],
  );

  const onClickNextTour = useCallback(() => {
    const current = routeIds[0];
    const next = current >= tourData.tours.length - 1 ? 0 : current + 1;
    handleSelectTourById(next);
    handleGetRoutingTourInfo(next, tourData.tours[`${next}`]);
  }, [routeIds, tourData, handleSelectTourById, handleGetRoutingTourInfo]);

  const onConfigToggle = useCallback(
    isVisible => {
      setIsConfigVisible(isVisible);
    },
    [setIsConfigVisible],
  );

  useEffect(() => {
    if (!errorState && !tourData) triggerVRPRequest(orders, tourPlanner);
  }, [orders, tourPlanner, tourData, errorState, triggerVRPRequest]);

  useEffect(() => {
    if (!tourData || !tourData.tours) return;
    const isMonoTour = isSolutionMonoTour(tourData);
    const toStep = isMonoTour ? 4 : 3;
    if (isMonoTour) {
      handleSelectTourById(0);
      handleGetRoutingTourInfo(0, head(tourData.tours));
    }
    handleSetStep(toStep);
  }, [tourData, handleSetStep, handleSelectTourById, handleGetRoutingTourInfo]);

  const onTourChange = useCallback(() => handleSetStep(4), [handleSetStep]);
  const showHeader = step !== STEPS_IN_HEADER + 1;
  const hasSolution = !!tourData;

  return (
    <StyledContainer>
      <Notifications user={user} />
      {showHeader && <WizardHeader step={step} onChange={onStepChange} hasSolution={hasSolution} />}
      <StyledWizardContent noHeader={!showHeader} ref={wizardContentRef}>
        {step === 1 && <Step1Container oAuth={oAuth} tourPlanner={tourPlanner} user={user} />}
        {step === 2 && (
          <Step2Container
            orders={orders}
            ordersNotLocated={ordersNotLocated}
            errorState={errorState}
            tourPlanner={tourPlanner}
            oAuth={oAuth}
            user={user}
            uploadedFile={uploadedFile}
            onConfigToggle={onConfigToggle}
          />
        )}
        {step === 3 && (
          <Step3Container
            user={user}
            oAuth={oAuth}
            tourData={tourData}
            solution={solution}
            onTourChange={onTourChange}
            selectedTours={routeIds}
            sharedTours={sharedRouteIds}
            orders={orders}
            tourPlanner={tourPlanner}
          />
        )}
        {step === 4 && (
          <Step4Container
            tourData={tourData}
            solution={solution}
            selectedTours={routeIds}
            tourPlanner={tourPlanner}
            user={user}
            orders={orders}
          />
        )}
      </StyledWizardContent>
      <WizardFooter
        step={step}
        onClick={onStepChange}
        onClickNextTour={onClickNextTour}
        isSubmitting={isSubmitting}
        orders={orders}
        tourData={tourData}
        tourPlanner={tourPlanner}
        disabled={isConfigVisible}
      />
    </StyledContainer>
  );
};

export default Wizard;
