import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'utils/PropTypes';

const StyledSection = styled.div`
  margin-bottom: 1.5rem;
`;

const Section = ({ children }) => <StyledSection>{children}</StyledSection>;

Section.propTypes = {
  children: PropTypes.children,
};

Section.defaultProps = {
  children: null,
};

export default Section;
