import { keys, isEmpty, cloneDeep, clone, size } from 'lodash';
import { sortAlpha } from '../helpers';
import { getStorageValue, STORAGE_IDS } from '../localStorageHelpers';
import { createIDFromNumber } from '../GeoCoder';
import { FLEET_MODES } from '../../actions';

const takeFirstCharFrom = ['Apartment'];

const MAX_CLUSTER_SIZE = 20;

export const sortOrdersByComplexAddress = orders => {
  if (!orders || isEmpty(orders)) return orders;

  const toClusterProp = keys(orders[0]).filter(key => takeFirstCharFrom.includes(key));
  if (isEmpty(toClusterProp)) return sortAlpha(orders, 'Address');

  return orders.sort((a, b) => {
    const addA = a.ClusterID ? a.ClusterID.toUpperCase() : '';
    const addB = b.ClusterID ? b.ClusterID.toUpperCase() : '';
    if (addA < addB) return -1;
    if (addA > addB) return 1;

    const prop = toClusterProp[0];
    const aProp = a[`${prop}`];
    const bProp = b[`${prop}`];
    if (!aProp || !bProp) return 0;

    const valA = Number(aProp);
    const valB = Number(bProp);
    if (!Number.isNaN(valA) && !Number.isNaN(valB)) return valA - valB;

    const alphaA = aProp.replace(valA, '').toUpperCase();
    const alphaB = bProp.replace(valB, '').toUpperCase();
    if (alphaA < alphaB) return -1;
    if (alphaA > alphaB) return 1;
    return 0;
  });
};

export const getOrderCluster = (order, includeNumber) => {
  if (!order) return null;

  const toClusterProp = keys(order).filter(key => takeFirstCharFrom.includes(key));
  if (isEmpty(toClusterProp)) return null;

  const stored = getStorageValue(STORAGE_IDS.tourPlanner, { csvConfig: {} });
  const csvConfig = stored.csvConfig;
  const clusteringProperties = keys(csvConfig).filter(key => csvConfig[`${key}`] === 'address');

  const props = keys(order).filter(key => clusteringProperties.includes(key));
  if (isEmpty(props)) return null;

  if (includeNumber) {
    const number = parseInt(order[toClusterProp[0]], 10);
    if (Number.isNaN(number)) return `${order.ClusterID}-${order[toClusterProp[0]]}`;

    const id = createIDFromNumber(number, 4);
    return `${order.ClusterID}-${id.substr(0, 2)}`;
  }

  const values = props.map(p => {
    const takeFull = !takeFirstCharFrom.includes(p);
    if (takeFull) return order[`${p}`].replace(/ /gi, '_').toUpperCase();
    return '';
  });
  return values.join('-');
};

const getNewClusterID = (clusters, baseClusterID) => {
  let index = 1;
  let newID;
  while (!newID) {
    if (clusters[`${baseClusterID}___${index}`] === undefined)
      newID = `${baseClusterID}___${index}`;
    index++;
  }
  return newID;
};

const getAddressClusters = (orders, maxClusterSize) => {
  const clusters = {};
  const unclustered = [];
  const sortedOrders = sortOrdersByComplexAddress(orders);
  sortedOrders.forEach(order => {
    if (!order.ClusterID) {
      unclustered.push(order);
    } else {
      if (!clusters[order.ClusterID]) clusters[order.ClusterID] = [];
      const totalDemand = clusters[order.ClusterID].reduce((acc, val) => {
        return acc + val.Demand;
      }, 0);
      if (totalDemand + order.Demand > maxClusterSize) {
        const newID = getNewClusterID(clusters, order.ClusterID);
        clusters[`${newID}`] = [...clusters[`${order.ClusterID}`]];
        clusters[`${order.ClusterID}`] = [];
      }
      clusters[`${order.ClusterID}`].push(order);
    }
  });
  return { clusters, unclustered };
};

const getAddressDetailClusters = (clusters, maxClusterSize) => {
  const detailedClusters = {};
  keys(clusters).forEach(key => {
    const cluster = clusters[`${key}`];
    const totalDemand = cluster.reduce((acc, val) => {
      return acc + val.Demand;
    }, 0);
    if (totalDemand >= maxClusterSize) {
      detailedClusters[`${key}`] = cluster;
    } else {
      cluster.forEach(order => {
        const newID = getOrderCluster(order, true);
        if (!detailedClusters[`${newID}`]) detailedClusters[`${newID}`] = [];
        detailedClusters[`${newID}`].push(order);
      });
    }
  });
  return detailedClusters;
};

const breakAddressDetailClusters = (clusters, maxClusterSize) => {
  const smallClusters = {};
  keys(clusters).forEach(key => {
    const cluster = clusters[`${key}`];
    const totalDemand = cluster.reduce((acc, val) => {
      return acc + val.Demand;
    }, 0);
    let newID = getNewClusterID(smallClusters, key);
    if (totalDemand >= maxClusterSize) {
      smallClusters[`${newID}`] = cluster;
    } else {
      smallClusters[`${newID}`] = [];
      cluster.forEach(order => {
        const newTotalDemand = smallClusters[`${newID}`].reduce((acc, val) => {
          return acc + val.Demand;
        }, 0);
        if (newTotalDemand >= maxClusterSize / 4) {
          newID = getNewClusterID(smallClusters, key);
          smallClusters[`${newID}`] = [];
        }
        smallClusters[`${newID}`].push(order);
      });
    }
  });
  return smallClusters;
};

const getClustersArray = clusters => {
  return keys(clusters).map(key => {
    const cluster = clusters[`${key}`];
    if (isEmpty(cluster)) return null;
    const first = cluster[0];
    const totalDemand = cluster.reduce((acc, val) => {
      return acc + val.Demand;
    }, 0);
    const totalTime = cluster.reduce((acc, val) => {
      return acc + val.ServiceTime;
    }, 0);
    return {
      Address: first.Address,
      Demand: totalDemand,
      ServiceTime: totalTime,
      Latitude: first.Latitude,
      Longitude: first.Longitude,
      InternalID: key,
      ClusterID: key,
      Orders: cluster,
    };
  });
};

const getMaxClusterSize = tourPlanner => {
  if (!tourPlanner) return MAX_CLUSTER_SIZE;
  if (tourPlanner.fleetMode === FLEET_MODES.SOLO) return MAX_CLUSTER_SIZE;
  return tourPlanner.vehicle.capacity[0];
};

export const getClusters = (orders, tourPlanner) => {
  if (!orders || isEmpty(orders)) return null;

  const maxClusterSize = getMaxClusterSize(tourPlanner);
  const { clusters, unclustered } = getAddressClusters(orders, maxClusterSize);
  const detailedClusters = getAddressDetailClusters(clusters, maxClusterSize);
  const finalClusters = breakAddressDetailClusters(detailedClusters, maxClusterSize);
  const clustersArr = getClustersArray(finalClusters);
  const totalClusters = unclustered.concat(clustersArr.filter(c => !!c));
  if (orders.length === totalClusters.length) return null;
  return sortAlpha(totalClusters, 'ClusterID');
};

const getOrdersByClusterID = (clusters, clusterID) => {
  if (!clusters || !clusterID) return null;
  const cluster = clusters.filter(c => c.ClusterID === clusterID);
  return isEmpty(cluster) ? null : cluster[0].Orders;
};

export const unclusterJobs = (response, clusters) => {
  if (!response || !clusters) return response;

  const unclustered = cloneDeep(response);
  unclustered.tours.forEach(tour => {
    tour.stops.forEach(stop => {
      const activities = stop.activities;
      const newActivities = [];
      activities.forEach(act => {
        const clusterOrders = getOrdersByClusterID(clusters, act.jobId);
        if (isEmpty(clusterOrders)) {
          newActivities.push(act);
        } else {
          clusterOrders.forEach(order => {
            const actFromOrder = {
              jobId: order.InternalID,
              location: { lat: order.Latitude, lng: order.Longitude },
              time: clone(act.time),
              type: act.type,
              ClusterID: order.ClusterID,
              Apartment: order.Apartment,
            };
            newActivities.push(actFromOrder);
          });
        }
      });
      stop.originalActivities = stop.activities;
      stop.activities = sortOrdersByComplexAddress(newActivities);
    });
  });

  unclustered.tours = unclustered.tours.sort((a, b) => {
    const stopsA = size(a.stops);
    const stopsB = size(b.stops);
    if (stopsA !== stopsB) return stopsA - stopsB;

    const firstStopA = stopsA > 1 ? a.stops[1] : a.stops[0];
    const firstStopB = stopsB > 1 ? b.stops[1] : b.stops[0];
    const clusterA = firstStopA.activities[0].ClusterID;
    const clusterB = firstStopB.activities[0].ClusterID;
    if (clusterA < clusterB) return -1;
    if (clusterA > clusterB) return 1;

    const aptA = firstStopA.activities[0].Apartment;
    const aptB = firstStopB.activities[0].Apartment;
    if (aptA < aptB) return -1;
    if (aptA > aptB) return 1;

    return 0;
  });

  const unassigned = unclustered.unassigned;
  if (!isEmpty(unassigned)) {
    const totalUnassigned = [];
    unclustered.unassigned.forEach(job => {
      const clusterOrders = getOrdersByClusterID(clusters, job.jobId);
      clusterOrders.forEach(order => {
        const newUnassigned = { jobId: order.InternalID, reasons: job.reasons };
        totalUnassigned.push(newUnassigned);
      });
    });
    unclustered.unassigned = totalUnassigned;
  }

  return unclustered;
};
