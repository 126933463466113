import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapContainer from 'components/Map/MapContainer';
import Footer from './components/Map/Footer';
import Menu from './components/FloatingPanels/Menu/Menu';
import { getOAuth, setUserParam } from './actions';
import { initWebworker } from './utils/vrp/vrpSolver';

import 'leaflet/dist/leaflet.css';
import { getUrlTest } from './utils/urlHelpers';
import getUserLocation from './utils/getUserLocation';
import TopBar from './components/TopBar/TopBar';
import { isMobileDevice } from './utils/helpers';
import { getStorageValue, STORAGE_IDS } from './utils/localStorageHelpers';
import IntroWizard from './components/Wizard/IntroWizard';
import FeedbackContainer from './components/FloatingPanels/Feedback/FeedbackContainer';
import { isSolutionStored } from './utils/MemoryHelpers';
import SurveyBanner from "./components/FloatingPanels/Survey/SurveyBanner";
import Survey from "./components/FloatingPanels/Survey/Survey";
import SurveyPopup from "./components/FloatingPanels/Survey/SurveyPopup";

const INTRO_VERSION = 2;
const isTest = getUrlTest();
const isMobile = isMobileDevice();

const App = () => {
  const dispatch = useDispatch();
  const oAuth = useSelector(({ oAuth: stateOAuth }) => stateOAuth);
  const user = useSelector(({ user: stateUser }) => stateUser);
  const handleGetOAuth = useCallback(data => dispatch(getOAuth(data)), [dispatch]);
  const handleSetUserParam = useCallback(location => dispatch(setUserParam({ location })), [
    dispatch,
  ]);
  const [showIntro, setShowIntro] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMapMobile, setShowMapMobile] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(true);

  const handleResize = useCallback(() => {
    setIsMenuActive(false);
    setTimeout(() => setIsMenuActive(true), 1000);
  }, [setIsMenuActive]);

  useEffect(() => {
    handleGetOAuth();
    initWebworker();

    const ls = getStorageValue(STORAGE_IDS.introVersion);
    const version = ls ? parseFloat(ls) : 0;
    const displayIntro = !isTest && !isSolutionStored() && version < INTRO_VERSION;
    setShowIntro(displayIntro);
    window.addEventListener('resize', handleResize);
  }, [handleGetOAuth, handleResize]);

  useEffect(() => {
    if (showIntro === null || showIntro) return;

    getUserLocation().then(
      loc => {
        if (loc && loc.coords) handleSetUserParam(loc.coords);
      },
      () => {},
    );
  }, [showIntro, handleSetUserParam]);

  const onIntroDismiss = useCallback(() => {
    setShowIntro(false);
  }, [setShowIntro]);

  const onToggleMobileView = useCallback(() => {
    setShowMapMobile(!showMapMobile);
  }, [setShowMapMobile, showMapMobile]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      {showIntro && <IntroWizard onChange={onIntroDismiss} introVersion={INTRO_VERSION} />}
      {(!showIntro || !isMobile) && (
        <TopBar
          onClickMenu={toggleMenu}
          onToggleMobileView={onToggleMobileView}
          isVisible={!showMapMobile}
        />
      )}
      <MapContainer
        user={user}
        oAuth={oAuth}
        showIntro={showIntro}
        showMapMobile={showMapMobile}
      />
      {!showIntro && !isMobile && <Footer user={user} />}
      {!showIntro && <FeedbackContainer />}

      {isMenuActive && (
        <Menu user={user} isOpen={isMenuOpen} onClickClose={toggleMenu} />
      )}
      {!showIntro && <SurveyBanner isVisible={user.survey.isBannerVisible} />}
      <Survey isVisible={user.survey.isSurveyVisible} />
      <SurveyPopup user={user} />
    </>
  );
};

export default App;
