function digest(algorithm, data) {
  return window.crypto.subtle.digest(algorithm, data);
}

function toAscii(data) {
  return window.btoa(data);
}

function digestMessage(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  return digest('SHA-256', data);
}

export default function createHash(text) {
  return digestMessage(text).then(digestValue => toAscii(digestValue));
}
