import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { size } from 'lodash';
import { colors, fontSize } from 'global/variables';
import { Swipeable } from '../Form/Swipeable';
import Button from '../Form/Button';
import { StyledFullPanelContainer } from './Global/WizardStyled';
import { setStorageValue, STORAGE_IDS } from '../../utils/localStorageHelpers';
import arrowRight from '../../global/img/arrow-right.svg';
import playIcon from '../../global/img/play.svg';
import imgStep1 from '../../global/img/intro-step-1.jpg';
import imgStep2 from '../../global/img/intro-step-2.jpg';
import imgStep3 from '../../global/img/intro-step-3.jpg';
import Modal from '../Presentation/Modal';
import { getAsset } from '../../utils/helpers';

const {
  aqua,
  primaryColorHovered,
  disabledBackground,
  primaryTextColor,
  white,
  transparentWhiteColor,
  black,
} = colors;
const { normal, medium, small } = fontSize;

const StyledIntroHeader = styled.div({
  position: 'absolute',
  top: '0.35rem',
  right: '0.4rem',
  left: '0.4rem',
  padding: '0.6rem 1rem',
  color: primaryTextColor,
  fontSize: medium,
  zIndex: '999',
  backgroundColor: transparentWhiteColor,
  backdropFilter: 'blur(10px)',
  borderRadius: '0.1rem',
  textAlign: 'left',
  display: 'grid',
  gridTemplateColumns: '4rem 1fr',
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
  transition: 'all 0.5s cubic-bezier(.43,.01,.22,1)',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.95)',
  },
});

const StyledIntroVideoLink = styled.div({
  paddingTop: '0.2rem',
  lineHeight: '1.25rem',
});

const StyledIntroVideoLinkDesc = styled.div({
  fontSize: '0.85rem',
  fontWeight: 700,
  color: black,
  position: 'relative',
  top: '-2px',
});

const StyledNamePrefix = styled.div({
  display: 'inline-block',
  color: primaryTextColor,
  fontWeight: 500,
});

const StyledName = styled.div({
  display: 'inline-block',
  color: aqua,
  fontWeight: 700,
});

const StyledIntroContent = styled.div({
  position: 'absolute',
  bottom: '4.5rem',
  backgroundColor: white,
  height: '12.5rem',
  overflowY: 'scroll',
  width: '100%',
  '@media screen and (max-width: 850px)': {
    height: '11rem',
  },
});

const StyledIntroImageContainer = styled.div({
  position: 'absolute ',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  boxSizing: 'border-box',
  marginBottom: '0.8rem',
});

const StyledIntroImage = styled.div(({ image }) => ({
  width: '100%',
  height: '30rem',
  backgroundImage: `url(${getAsset(image)})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
}));

const StyledStepIndicatorContainer = styled.div({
  width: '100%',
  boxSizing: 'border-box',
  margin: '1.5rem 0 0rem 0',
  textAlign: 'center',
  '@media screen and (max-width: 850px)': {
    margin: '0.4rem 0',
  },
});

const StyledStepIndicator = styled.div(
  {
    display: 'inline-block',
    cursor: 'pointer',
  },
  ({ isActive }) => ({
    width: '1rem',
    height: '1rem',
    padding: '0 0.6rem',
    div: {
      position: 'relative',
      transform: 'translate(-50%, -50%)',
      transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
      width: isActive ? '12px' : '8px',
      height: isActive ? '12px' : '8px',
      backgroundColor: isActive ? aqua : disabledBackground,
      borderRadius: '1rem',
      lineHeight: isActive ? '0.9rem' : '0.8rem',
      verticalAlign: 'middle',
      '&:hover': {
        backgroundColor: !isActive ? disabledBackground : 'none',
      },
    },
  }),
);

const StyledNextContainer = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  lineHeight: '2.5rem',
  padding: '1rem',
  height: '4.5rem',
  backgroundColor: white,
  boxSizing: 'border-box',
  borderRadius: '0.25rem 0.25rem 2rem 2rem',
});

const StyledNextContainerDouble = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  'div:nth-of-type(1)': {
    justifySelf: 'start',
  },
  'div:nth-of-type(2)': {
    justifySelf: 'end',
  },
});

const StyledNoShowContainer = styled.div({
  lineHeight: '1.2rem',
  marginTop: '1rem',
});

const StyledIntroTextContainer = styled.div({
  position: 'relative',
  textAlign: 'center',
  padding: '0 0.5rem',
});

const StyledTextTitle = styled.div({
  color: aqua,
  fontSize: normal,
  fontWeight: 700,
  marginBottom: '0.2rem',
  '@media screen and (max-width: 850px)': {
    fontSize: medium,
    lineHeight: '1.2rem',
  },
});

const StyledIntroText = styled.div({
  color: primaryTextColor,
  fontSize: medium,
  lineHeight: '1.4rem',
  fontWeight: 400,
  padding: '0 2rem',
  '@media screen and (max-width: 850px)': {
    fontSize: small,
    lineHeight: '1.2rem',
  },
});

const StyledTextLink = styled.div({
  color: aqua,
  fontSize: medium,
  marginLeft: '1rem',
  cursor: 'pointer',
  '&:hover': {
    color: primaryColorHovered,
  },
});

const IntroWizard = ({
  introVersion,
  onChange,
  translations: {
    intro: {
      step1Title,
      step1Text,
      step2Title,
      step2Text,
      step3Title,
      step3Text,
      skipLabel,
      buttonText,
      wizardTitle,
      showAgainText,
      videoLink,
    },
  },
}) => {
  const [noShowAgain, setNoShowAgain] = useState(false);
  const [step, setStep] = useState(1);

  const wizardData = useMemo(() => {
    return [
      { title: step1Title, text: step1Text, img: imgStep1 },
      { title: step2Title, text: step2Text, img: imgStep2 },
      { title: step3Title, text: step3Text, img: imgStep3 },
    ];
  }, [step1Text, step1Title, step2Title, step2Text, step3Text, step3Title]);

  const onDone = useCallback(() => {
    if (noShowAgain) {
      setStorageValue(STORAGE_IDS.introVersion, introVersion);
    }
    onChange();
  }, [onChange, introVersion, noShowAgain]);

  const onNextStep = useCallback(() => {
    if (step < size(wizardData)) setStep(step + 1);
    else onDone();
  }, [setStep, step, wizardData, onDone]);

  const onPreviousStep = useCallback(() => {
    if (step > 1) setStep(step - 1);
  }, [setStep, step]);

  const goToStep = useCallback(toStep => setStep(toStep + 1), [setStep]);

  const onToggleChange = useCallback(
    e => {
      setNoShowAgain(e.target.checked);
    },
    [setNoShowAgain],
  );

  return (
    <Modal isFull maxHeight="48rem" isVisible>
      <Swipeable onSwipedLeft={onNextStep} onSwipedRight={onPreviousStep}>
        <StyledFullPanelContainer>
          <StyledIntroHeader>
            <a
              href="https://www.youtube.com/watch?v=I-1cSeZ7A8w"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button small icon={playIcon} inverted />
            </a>
            <StyledIntroVideoLink>
              <div>
                <StyledNamePrefix>{wizardTitle}&nbsp;</StyledNamePrefix>
                <StyledName>HERE WeGo Deliver</StyledName>
              </div>
              <StyledIntroVideoLinkDesc>{videoLink}</StyledIntroVideoLinkDesc>
            </StyledIntroVideoLink>
          </StyledIntroHeader>
          <StyledIntroImageContainer>
            <StyledIntroImage image={wizardData[step - 1].img} />
          </StyledIntroImageContainer>
          <StyledIntroContent>
            <StyledStepIndicatorContainer>
              {wizardData.map((s, index) => (
                <StyledStepIndicator
                  isActive={index === step - 1}
                  key={index}
                  onClick={() => goToStep(index)}
                >
                  <div>&nbsp;</div>
                </StyledStepIndicator>
              ))}
            </StyledStepIndicatorContainer>
            <StyledIntroTextContainer>
              <StyledTextTitle>{wizardData[step - 1].title}</StyledTextTitle>
              <StyledIntroText>{wizardData[step - 1].text}</StyledIntroText>
            </StyledIntroTextContainer>
            {step === wizardData.length && (
              <StyledNoShowContainer>
                <input
                  type="checkbox"
                  id="dont-show-anymore"
                  onChange={onToggleChange}
                  defaultChecked={noShowAgain}
                />{' '}
                <label className="custom-switcher" htmlFor="dont-show-anymore">
                  T
                </label>
                <span className="label-custom-switcher">{showAgainText}</span>
              </StyledNoShowContainer>
            )}
          </StyledIntroContent>
          <StyledNextContainer>
            {step < wizardData.length && (
              <StyledNextContainerDouble>
                <StyledTextLink onClick={onDone}>{skipLabel}</StyledTextLink>
                <Button icon={arrowRight} onClick={onNextStep} />
              </StyledNextContainerDouble>
            )}
            {step === wizardData.length && <Button text={buttonText} onClick={onDone} />}
          </StyledNextContainer>
        </StyledFullPanelContainer>
      </Swipeable>
    </Modal>
  );
};

export default withTranslation(IntroWizard);
