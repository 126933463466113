import { mapValues, values, isEmpty } from 'lodash';
import { composeOrderField } from '../IngestOrdersHelpers';

export const CSV_HEADERS = Object.freeze({
  id: {
    columnName: 'ID',
    internalColumnName: 'ID',
    isRequired: false,
    trans: 'id',
  },
  name: {
    columnName: 'Name',
    internalColumnName: 'Name',
    isRequired: false,
    trans: 'name',
  },
  careOf: {
    columnName: 'c/o',
    internalColumnName: 'CO',
    isRequired: false,
    trans: 'careOf',
  },
  address: {
    columnName: 'Address',
    internalColumnName: 'Address',
    isRequired: false,
    trans: 'address',
  },
  phone: {
    columnName: 'Phone',
    internalColumnName: 'Phone',
    isRequired: false,
    trans: 'phone',
  },
  notes: {
    columnName: 'Notes',
    internalColumnName: 'Notes',
    isRequired: false,
    trans: 'notes',
  },
  email: {
    columnName: 'Email',
    internalColumnName: 'Email',
    isRequired: false,
    trans: 'email',
  },
  latitude: {
    columnName: 'Latitude',
    internalColumnName: 'Latitude',
    isRequired: false,
    trans: 'latitude',
  },
  longitude: {
    columnName: 'Longitude',
    internalColumnName: 'Longitude',
    isRequired: false,
    trans: 'longitude',
  },
  startTime: {
    columnName: 'Start Time',
    internalColumnName: 'StartTime',
    isRequired: false,
    trans: 'startTime',
  },
  endTime: {
    columnName: 'End Time',
    internalColumnName: 'EndTime',
    isRequired: false,
    trans: 'endTime',
  },
  serviceTime: {
    columnName: 'Service time (min)',
    internalColumnName: 'ServiceTime',
    isRequired: false,
    trans: 'serviceTime',
  },
  demand: {
    columnName: 'Demand',
    internalColumnName: 'Demand',
    isRequired: false,
    trans: 'demand',
  },
});

export const CSV_META = Object.freeze({
  defaultDelimeter: ';',
  timeFormat: 'HH:mm',
  columnNames: values(mapValues(CSV_HEADERS, 'columnName')),
  requiredFields: values(CSV_HEADERS)
    .filter(({ isRequired, conditional }) => isRequired && !conditional)
    .map(({ columnName }) => columnName),
  hasNoCoordinates: row =>
    isEmpty(composeOrderField(row, CSV_HEADERS.latitude)) ||
    isEmpty(composeOrderField(row, CSV_HEADERS.longitude)),
  freeTextAddress: row => composeOrderField(row, CSV_HEADERS.address),
});
