import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, size } from 'lodash';
import directions from 'global/img/directions.svg';
import moment from 'moment';
import stopIcon from 'global/img/med-stop.svg';
import depotIcon from 'global/img/med-home.svg';
import stopLine from 'global/img/selected-route-line.svg';
import stopLineUnselected from 'global/img/unselected-route-line.svg';
import returnLocationIcon from 'global/img/med-return.svg';
import {
  getOrdersAddress,
  getOrdersByLocation,
  getOrdersByStop,
} from '../../../utils/OrdersHelpers';
import Button from '../../Form/Button';
import { makeShareNavLink, makeDesktopNavLink } from '../../../utils/SolutionExport';
import { isMobileDevice, getAsset, isSameLocation } from '../../../utils/helpers';
import StopDetails from './StopDetails';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';

const { secondaryTextColor, transparentBlackColor, secondaryBorderColor, white } = colors;
const { small, medium, smaller } = fontSize;

const StyledStop = styled.div(
  {
    lineHeight: '3rem',
    padding: '0rem 0.2rem',
    textRendering: 'geometricPrecision',
    color: secondaryTextColor,
    fontSize: medium,
    borderTop: `1px solid ${secondaryBorderColor}`,
    minHeight: '3.8rem',
  },
  ({ isActive }) => ({
    backgroundColor: isActive ? white : 'transparent',
    cursor: isActive ? 'normal' : 'pointer',
  }),
);

const StyledBtn = styled.div({
  transition: 'all 0.35s cubic-bezier(0.600, 0.005, 0.320, 1.000)',
  position: 'relative',
  top: '10px',
});

const StyledNumber = styled.div(
  {
    position: 'relative',
    margin: 'auto',
    fontSize: smaller,
    fontFamily: 'FiraGO',
    fontWeight: '700',
  },
  ({ isLastStop }) => ({
    top: isLastStop ? '6px' : '1px',
  }),
);

const StyledStopSpan = styled.div(
  {
    height: '100%',
    color: white,
    fontFamily: 'FiraGO',
    fontWeight: '400',
    textAlign: 'center',
    minHeight: '3.8rem',
  },
  ({ isActive, isDepot, isLastStop, isReturnLocation, tourPlanner }) => {
    let icon = isDepot ? depotIcon : stopIcon;

    if (isReturnLocation) {
      icon = isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
        ? depotIcon
        : returnLocationIcon;
    }

    return {
      color: isDepot || isReturnLocation ? 'transparent' : white,
      backgroundImage: isActive
        ? `url(${getAsset(icon)}), url(${getAsset(stopLine)})`
        : `url(${getAsset(icon)}), url(${getAsset(stopLineUnselected)})`,
      backgroundSize: isDepot ? 'auto, 100% 50%' : 'auto, auto',
      backgroundRepeat:
        isDepot || isLastStop || isReturnLocation ? 'no-repeat, no-repeat' : 'no-repeat, repeat-y',
      backgroundPosition:
        isDepot || isLastStop || isReturnLocation
          ? isDepot
            ? 'center center, center 100%'
            : isReturnLocation
            ? 'center center, center -10px'
            : 'center 13px, center -18px'
          : 'center 8px, center center',
      backgroundColor: isActive ? white : 'transparent',
      cursor: isActive || isDepot || isReturnLocation ? 'normal' : 'pointer',
    };
  },
);

const StyledAddressWrapper = styled.div(
  {
    lineHeight: '1rem',
    textAlign: 'left !important',
    fontWeight: `400`,
  },
  ({ isActive, isDepot, isReturnLocation }) => ({
    fontWeight: isActive ? `700` : `400`,
    position: 'relative',
    padding: isDepot || isReturnLocation ? '1.4rem 0' : '1rem 0',
    top: 0,
  }),
);

const StyledTimes = styled.div(
  {
    position: 'relative',

    lineHeight: '1rem',
    padding: '0.5rem 0',
    color: transparentBlackColor,
    textAlign: 'center',
    fontSize: small,
    fontWeight: '400',
    backgroundImage: 'none',
    div: {
      display: 'block',
      color: secondaryTextColor,
    },
  },
  ({ isActive, isDepot, isReturnLocation }) => ({
    backgroundColor: isActive ? white : 'transparent',
    cursor: isActive || isDepot || isReturnLocation ? 'normal' : 'pointer',
    top: isDepot || isReturnLocation ? '16px' : '8px',
  }),
);

const StyledTimesWrapper = styled(StyledTimes)`
  div:last-of-type {
    color: ${transparentBlackColor};
    font-weight: 400;
  }
`;

const Stop = withTranslation(
  ({
    user,
    index,
    stop,
    prevStop,
    request,
    isDepot,
    isReturnLocation,
    isLastStop,
    isStopActive,
    onStopClick,
    tourPlanner,
    demandLabel,
    isDemandVisible,
    translations: {
      map: { depotMarkerTrans },
      tourSolution: {
        tourDetails: { arrivalTrans, stopTrans },
      },
      wizard: {
        toursDetails: { getDirections },
      },
    },
  }) => {
    let tourPlannerLabel = null;
    if (isDepot) {
      tourPlannerLabel = tourPlanner && tourPlanner.value && tourPlanner.location.label;
      if (!tourPlannerLabel) {
        const ordersForDepot = getOrdersByLocation(request.orders, stop.location);
        tourPlannerLabel = !isEmpty(ordersForDepot) ? ordersForDepot[0].Address : null;
      }
    }

    if (isReturnLocation) {
      tourPlannerLabel = isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
        ? arrivalTrans
        : tourPlanner.returnLocation.label;
    }

    const ordersInStop = getOrdersByStop(request.orders, stop);
    const address = getOrdersAddress(ordersInStop, tourPlanner);
    const defaultVal = isDepot ? null : `${stopTrans} ${index}`;
    const defaultLabel = defaultVal || (isDepot ? depotMarkerTrans : arrivalTrans);
    const label = address || tourPlannerLabel || defaultLabel;
    const linkUrl = isMobileDevice()
      ? makeShareNavLink(stop, user.language, request.orders, tourPlanner)
      : makeDesktopNavLink(stop, prevStop, user.language, request.orders, tourPlanner);
    const totalDemand =
      !isDemandVisible || !ordersInStop
        ? 0
        : ordersInStop.reduce((acc, order) => acc + (parseInt(order.Demand, 10) || 1), 0);
    const hasJobs = !!totalDemand;
    const isActive = isStopActive;

    const onClick = useCallback(
      i => {
        if (!isDepot && !isReturnLocation) onStopClick(i);
        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_STOP_CLICK);
      },
      [onStopClick, isDepot, isReturnLocation],
    );

    const onClickGetDirections = useCallback(() => {
      AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_STOP_TO_WEGO);
      if (isMobileDevice()) {
        const amount = !isEmpty(ordersInStop) ? size(ordersInStop) : 1;
        const stopInfo = {
          stopsInTour: 1,
          ordersInTour: amount,
          dropsInTour: totalDemand,
          dispatchMode: 'linkWeGo',
          isDemo: user.isLastOrdersAddedDemo,
        };

        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, stopInfo);
      }
    }, [ordersInStop, totalDemand, user]);

    return (
      <>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-0`}>
          <StyledStopSpan
            isActive={isActive}
            isDepot={isDepot}
            isLastStop={isLastStop}
            isReturnLocation={isReturnLocation}
            tourPlanner={tourPlanner}
          >
            <StyledNumber isLastStop={isLastStop}>{index}</StyledNumber>
          </StyledStopSpan>
        </StyledStop>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-1`}>
          <StyledAddressWrapper
            isDepot={isDepot}
            isReturnLocation={isReturnLocation}
            isActive={isActive}
            title={label}
          >
            {label}
          </StyledAddressWrapper>
        </StyledStop>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-2`}>
          {index > 0 && hasJobs && <>{totalDemand}</>}
        </StyledStop>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-3`}>
          <StyledTimesWrapper isDepot={isDepot} isReturnLocation={isReturnLocation}>
            <div>{isDepot ? '' : `${moment(stop.time.arrival).format('LT')}`}</div>
            <div>{isReturnLocation ? '' : `${moment(stop.time.departure).format('LT')}`}</div>
          </StyledTimesWrapper>
        </StyledStop>
        <StyledStop isActive={isActive} key={`stop-${index}-4`}>
          <StyledBtn isActive={isActive}>
            {linkUrl && (
              <a
                id={`button-wego-${index}`}
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                title={getDirections}
                onClick={() => onClickGetDirections(stop)}
              >
                <Button inverted={!isActive} icon={directions} />
              </a>
            )}
          </StyledBtn>
        </StyledStop>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-5`} />
        {isActive && !isDepot && ordersInStop && (
          <StopDetails
            orders={ordersInStop}
            isLastStop={isLastStop}
            isDemandVisible={isDemandVisible}
            demandLabel={demandLabel}
          />
        )}
      </>
    );
  },
);

export default withTranslation(Stop);
