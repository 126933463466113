import React, { useCallback, useState, useEffect } from "react";
import styled from '@emotion/styled';
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import withTranslation from 'hoc/withTranslation';
import { useDispatch } from "react-redux";
import { getBounds } from '../../utils/map/MapCalculations';
import config from "../../config";
import MapMarkers from "./MapMarkers";
import MapTourLines from "./MapTourLines";
import { getMapSettings, animateFlyTo } from "../../utils/map/MapUtils";
import { getGeoInfoFromObjects } from "../../utils/map/MapTourLineUtils";
import { getRoutingTourInfo, selectTourById } from "../../actions";
import { tourToRoutingRequest } from "../../utils/RoutingConverter";

const { map: { url } } = config;

const MAX_MAP_ZOOM = 18;
const MIN_MAP_ZOOM = 2;

const StyledMap = styled.div({
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
});

const Map = ({ mapData, oAuth, user, display, routingData, isSolution, tourData, translations }) => {
  const dispatch = useDispatch();
  const handleSelectTourById = useCallback(index => dispatch(selectTourById(index)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest({ ...tour, routeId: index }),
        }),
      ),
    [dispatch, oAuth],
  );
  const [routingRoutes, setRoutingRoutes] = useState(null);
  const [map, setMap] = useState(null);

  const getMapUrl = useCallback(() => `${url}&lg=${translations.map.language}`);

  const activateTour = useCallback((index, tour) => {
    handleSelectTourById(index);
    handleGetRoutingTourInfo(index, tour);
  }, [handleSelectTourById, handleGetRoutingTourInfo]);

  useEffect(() => {
    const bounds = getBounds(mapData, user, display, routingRoutes);
    if (map) map.fitBounds(bounds, getMapSettings(), animateFlyTo);
  }, [mapData, user, display, routingRoutes, map])

  useEffect(() => {
    const routeRequestError = routingData && routingData.requestError;
    const routes = routeRequestError
      ? null
      : getGeoInfoFromObjects(routingData);
    setRoutingRoutes(routes);
  }, [routingData, setRoutingRoutes]);

  return (
    <StyledMap user={user} translations={translations}>
      <MapContainer
        attributionControl={false}
        zoomControl={false}
        bounds={getBounds(mapData, user, display, routingRoutes)}
        boundsOptions={getMapSettings()}
        useFly
        maxZoom={MAX_MAP_ZOOM}
        minZoom={MIN_MAP_ZOOM}
        whenCreated={setMap}
      >
        <TileLayer attribution="Here Maps" url={getMapUrl()} />
        <ZoomControl position="topright" zoomInTitle="" zoomOutTitle="" />
        {mapData && (
          <MapMarkers
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            tourData={tourData}
            isSolution={isSolution}
            activateTour={activateTour}
          />
        )}
        {isSolution && (
          <MapTourLines
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            tourData={tourData}
            activateTour={activateTour}
          />
        )}
      </MapContainer>
    </StyledMap>
  );
};

export default withTranslation(Map);
