import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { size } from 'lodash';
import { setUserDisplaySurvey } from "../../../actions";
import { AMPLITUDE_EVENTS, AmplitudeService } from "../../../utils/amplitude";
import { APP_MODES, getAppMode } from "../../../utils/urlHelpers";

const appMode = getAppMode()

const SurveyPopup = ({ user }) => {
  const dispatch = useDispatch();
  const handleSetUserDisplaySurvey =
    useCallback(() => dispatch(setUserDisplaySurvey(true)), [dispatch]);
  const usageContext = useSelector(({ usageContext: stateUsageContext }) => stateUsageContext);
  const [lastContext, setLastContext] = useState({ dispatchModes: [] });

  const shouldInform = (context, last) => size(context.today.dispatchModes) !== size(last.dispatchModes)

  useEffect(() => {
    if (appMode !== APP_MODES.SURVEY) {
      const isFullyDismissed = user.survey && user.survey.isFullyDismissed
      if (isFullyDismissed || user.usage.days <= 2 || user.isDemo) return
    }

    if (shouldInform(usageContext, lastContext)) {
      setLastContext({ dispatchModes: usageContext.today.dispatchModes });
      setTimeout(() => {
        const info = { action: 'openSurveyPopup' }
        AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);
        handleSetUserDisplaySurvey()
      }, 5000)
    }
  }, [usageContext, lastContext, user]);

  return null
};

export default SurveyPopup;
