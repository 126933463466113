import { GET_SOLUTION, CLEAR_SOLUTION, SET_PROBLEM } from 'actions/index';
import { isEmpty, last } from 'lodash';
import { convertToGeoJSON } from 'utils/GeoJSONConverter';
import { unclusterJobs } from '../utils/csv/clusters';
import { STORAGE_IDS } from '../utils/localStorageHelpers';
import { getStateFromMemory, setStateToMemory } from '../utils/MemoryHelpers';

const defaultState = { iterations: [] };
const stateID = STORAGE_IDS.solution;
const initialState = getStateFromMemory(stateID, defaultState);
const transformStateToStore = state => {
  if (!state || !state.iterations) return state;
  return { iterations: [last(state.iterations)] };
};

export default (state = initialState, { error, payload = {}, type }) => {
  switch (type) {
    case SET_PROBLEM: {
      const completeIterations = [...state.iterations];
      completeIterations.push({ request: payload, isProcessing: true });
      const newState = { iterations: completeIterations };
      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case GET_SOLUTION: {
      if (error) {
        const { iterations: [{ request }] = [{}] } = state;
        const newState = { iterations: [{ request }] };
        setStateToMemory(stateID, newState, transformStateToStore);
        return newState;
      }

      const iterations = state.iterations.length === 0 ? [{ request: {} }] : [...state.iterations];

      const jobs =
        state.iterations.length === 0 || !iterations[iterations.length - 1].request.json
          ? []
          : iterations[iterations.length - 1].request.json.plan.jobs;
      const orders =
        state.iterations.length === 0 || !iterations[iterations.length - 1].request.orders
          ? []
          : iterations[iterations.length - 1].request.orders || [];
      const tourPlanner =
        state.iterations.length === 0 || !iterations[iterations.length - 1].request.tourPlanner
          ? null
          : iterations[iterations.length - 1].request.tourPlanner;
      const clusters =
        state.iterations.length === 0 || !iterations[iterations.length - 1].request.clusters
          ? []
          : iterations[iterations.length - 1].request.clusters;
      const { data } = payload;
      const unclustered = !isEmpty(clusters) ? unclusterJobs(data, clusters) : data;

      iterations[iterations.length - 1] = {
        ...iterations[iterations.length - 1],
        response: unclustered,
        geoJSON: convertToGeoJSON(unclustered, jobs, orders, tourPlanner),
        isProcessing: false,
      };

      const newState = { iterations };
      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case CLEAR_SOLUTION: {
      const { iterations: [{ request }] = [{}] } = state;
      const newState = { iterations: [{ request }] };
      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    default: {
      return state;
    }
  }
};
