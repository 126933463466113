import React, { useEffect } from 'react';
import withTranslation from 'hoc/withTranslation';
import { size, isEmpty, keys } from 'lodash';
import {
  StyledWizardContent,
  StyledWizardH1,
  StyledFullWidthContainer,
  StyledText,
} from '../Global/WizardStyled';
import Tours from './Tours';
import Section from '../../Form/Section';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getTotalJobsInTours } from '../../../utils/SolutionHelpers';
import SummaryWithDownload from './SummaryWithDownload';

const Step3Container = ({
  user,
  oAuth,
  tourData,
  onTourChange,
  selectedTours,
  sharedTours,
  solution,
  orders,
  tourPlanner,
  translations: {
    wizard: {
      tours: { toursTitle, noTours },
    },
  },
}) => {
  useEffect(() => {
    const slnInfo = {
      assignedOrders: getTotalJobsInTours(tourData.tours),
      unassignedOrders: size(tourData.unassigned),
      totalTours: size(tourData.tours),
      totalDuration: tourData.statistic.duration,
      totalDistance: tourData.statistic.distance,
      totalCost: tourData.statistic.cost,
    };

    if (!isEmpty(tourData.unassigned)) {
      const grouped = {};
      tourData.unassigned.forEach(item => {
        item.reasons.forEach(reason => {
          if (!grouped[reason.code]) grouped[reason.code] = [];
          grouped[reason.code].push(item);
        });
      });
      keys(grouped).forEach(key => {
        slnInfo[`unassignedOrdersCode${key}`] = size(grouped[`${key}`]);
      });
    }

    AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_OVERVIEW, slnInfo);
  }, [tourData]);

  return (
    <StyledWizardContent>
      <StyledWizardH1>{toursTitle}</StyledWizardH1>
      <Section>
        <SummaryWithDownload
          lastIterationTourData={tourData}
          user={user}
          tourPlanner={tourPlanner}
          orders={orders}
          solution={solution}
        />
      </Section>
      {tourData.tours.length > 0 && (
        <StyledFullWidthContainer>
          <Tours
            id="solutionTours"
            oAuth={oAuth}
            lastIterationTourData={tourData}
            onTourChange={onTourChange}
            selectedTours={selectedTours}
            sharedTours={sharedTours}
            solution={solution}
            user={user}
            tourPlanner={tourPlanner}
          />
        </StyledFullWidthContainer>
      )}
      {tourData.tours.length === 0 && (
        <Section>
          <StyledText>{noTours}</StyledText>
        </Section>
      )}
    </StyledWizardContent>
  );
};

export default withTranslation(Step3Container);
