import React, { useCallback, useEffect } from "react";
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import { useDispatch } from "react-redux";
import withTranslation from "../../../hoc/withTranslation";
import Button from "../../Form/Button";
import { setUserDisplaySurvey, setUserDisplaySurveyBanner } from "../../../actions";
import close from '../../../global/img/close-contrast.svg';
import { isMobileDevice } from "../../../utils/helpers";
import { AMPLITUDE_EVENTS, AmplitudeService } from "../../../utils/amplitude";

const { aqua } = colors;
const { medium } = fontSize;

const StyledSurveyBanner = styled.div(({ isVisible}) => ({
  backgroundColor: aqua,
  position: 'absolute',
  bottom: isVisible ? 0 : '-4rem',
  left: 0,
  right: 0,
  width: '100%',
  height: '3.5rem',
  zIndex: '3000000',
  padding: '0.5rem 2rem',
  boxSizing: 'border-box',
  lineHeight: '2.5rem',
  textAlign: 'center',
  transition: 'all 350ms ease-in-out',

  '@media screen and (max-width: 850px)': {
    top: isVisible ? 0 : '-4rem',
    bottom: 'unset',
    fontSize: medium,
    padding: '0.5rem',
    lineHeight: '1.2rem',
  },
}))

const StyledInnerContainer = styled.div({
  maxWidth: '1024px',
  margin: '0 auto',
  width: '100%',
  minHeight: '100%',
  padding: '0 2rem',
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '4fr 200px',
  gridGap: '1rem',
  fontSize: '18px',

  '@media screen and (max-width: 850px)': {
    maxWidth: '90%',
    gridTemplateColumns: '4fr 2fr',
    padding: '0 1rem',
    fontSize: '14px',
  },
})

const StyledButtonContainer = styled.div({
  position: 'relative',
})

const StyledCloseButton = styled.button({
  backgroundColor: 'transparent',
  backgroundImage: `url(${close})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 0.8,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',
  position: 'absolute',
  top: '1rem',
  right: '1rem',

  '&:hover': {
    opacity: 1,
  },

  '@media screen and (max-width: 850px)': {
    top: '0.5rem',
    right: '0.5rem',
    width: '1rem',
    height: '1rem',
  },

});

const SurveyBanner = ({ isVisible, translations }) => {
  const dispatch = useDispatch();
  const handleCloseSurveyBanner =
    useCallback(() => dispatch(setUserDisplaySurveyBanner(false, true)), [dispatch]);
  const handleSetUserDisplaySurvey =
    useCallback(() => dispatch(setUserDisplaySurvey(true)), [dispatch]);

  const { survey: { bannerLabels } } = translations
  const isMobile = isMobileDevice()
  const buttonLabel = isMobile ? bannerLabels.buttonMobile : bannerLabels.button
  const message = isMobile ? bannerLabels.messageMobile : bannerLabels.message

  useEffect(() => {
    if (!isVisible) return
    const info = { action: 'openSurveyBanner' }
    AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);
  }, [])

  const handleOnGoToSurvey = useCallback(() => {
    const info = { action: 'openSurveyFromBanner' }
    AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);
    handleSetUserDisplaySurvey()
  })

  const handleOnClose = useCallback(() => {
    const info = { action: 'closeSurveyBanner' }
    AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);
    handleCloseSurveyBanner()
  })

  return (
    <StyledSurveyBanner isVisible={isVisible}>
      <StyledInnerContainer>
        <div>{message}</div>
        <StyledButtonContainer>
          <Button inverted text={buttonLabel} onClick={handleOnGoToSurvey} withBackground />
        </StyledButtonContainer>
      </StyledInnerContainer>
      <StyledCloseButton onClick={handleOnClose} />
    </StyledSurveyBanner>
  );
};

export default withTranslation(SurveyBanner);
