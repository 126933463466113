import { SET_LOADER, SET_ERROR } from 'actions';

const initialState = { isLoading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return initialState;
    case SET_LOADER:
      return { ...action.payload };
    default:
      return state;
  }
};
