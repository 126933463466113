import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUserParam } from '../../../actions';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';

const LanguageSelector = ({ user }) => {
  const dispatch = useDispatch();
  const handleSetUserParam = useCallback(language => dispatch(setUserParam({ language })), [
    dispatch,
  ]);

  const onChange = useCallback(
    e => {
      handleSetUserParam(e.target.value);
    },
    [handleSetUserParam],
  );

  return (
    <StyledSelectInput contrast sizeL onChange={onChange} value={user.language}>
      <option value="de">Deutsch</option>
      <option value="nl">Dutch</option>
      <option value="el">Ελληνικά</option>
      <option value="en">English</option>
      <option value="es">Español</option>
      <option value="fr">Français</option>
      <option value="hi">हिन्दी</option>
      <option value="it">Italiano</option>
      <option value="pt">Português</option>
      <option value="th">ภาษาไทย</option>
      <option value="ru">Русский</option>
    </StyledSelectInput>
  );
};

export default LanguageSelector;
