import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { size } from 'lodash';
import { clearOrdersNotLocated } from 'actions';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import iconNotification from '../../../global/img/notification.svg';
import iconDownload from '../../../global/img/download-contrast.svg';
import { StyledButtonSmall, StyledIcon, StyledText } from '../Global/WizardStyled';
import { saveNotLocatedOrdersToCSVFile } from '../../../utils/SolutionExportToCsv';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { isMobileDevice } from '../../../utils/helpers';

const { darkSolidBackground, darkerSolidBackground, white } = colors;
const { small } = fontSize;
const isMobile = isMobileDevice();

const StyledInlineNotificationContainer = styled.div({
  position: 'relative',
  backgroundColor: darkSolidBackground,
  width: '100%',
  minHeight: '9.7rem',
  display: 'grid',
  gridTemplateColumns: '4rem 1fr 5rem',
  borderRadius: '4px',
  overflow: 'hidden',
  '@media screen and (max-width : 850px)': {
    gridTemplateColumns: '2.5rem 1fr 5rem',
  },
});

const StyledContainerCenterVertical = styled.div(({ dark, onClick }) => ({
  paddingTop: '3.8rem',
  textAlign: 'center',
  lineHeight: '2rem',
  verticalAlign: 'center',
  backgroundColor: dark && darkerSolidBackground,
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  cursor: onClick && 'pointer',
}));

const StyledTextContainer = styled.div({
  padding: '1.5rem 1rem 1rem 0',
  color: white,
});

const StyledTextDescription = styled.div({
  padding: '0.2rem 0 1rem',
  color: white,
  fontSize: small,
  opacity: '0.7',
});

const OrdersNotLocated = ({
  ordersNotLocated,
  user,
  translations: {
    wizard: {
      orders: {
        notLocated: { panelTitle, panelDescription, buttonClose, buttonDownload },
      },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleClear = useCallback(() => dispatch(clearOrdersNotLocated()), [dispatch]);

  const onDismiss = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD_NOT_LOCATED, { action: 'close' });
    handleClear();
  }, [handleClear]);

  const onReview = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD_NOT_LOCATED, { action: 'review' });
    saveNotLocatedOrdersToCSVFile(ordersNotLocated, user);
  }, [ordersNotLocated, user]);

  return (
    <StyledInlineNotificationContainer>
      <StyledContainerCenterVertical>
        <StyledIcon iconUrl={iconNotification} XL />
      </StyledContainerCenterVertical>
      <StyledTextContainer>
        <StyledText bold contrast>
          {panelTitle} {size(ordersNotLocated)}
        </StyledText>
        <StyledTextDescription>{panelDescription}</StyledTextDescription>
        <StyledButtonSmall contrast onClick={onReview}>
          {buttonDownload}&nbsp;&nbsp;
          {!isMobile && <StyledIcon iconUrl={iconDownload} mini />}
        </StyledButtonSmall>
      </StyledTextContainer>
      <StyledContainerCenterVertical onClick={onDismiss} dark>
        <StyledText contrast>{buttonClose}</StyledText>
      </StyledContainerCenterVertical>
    </StyledInlineNotificationContainer>
  );
};

export default withTranslation(OrdersNotLocated);
