import config from 'config';
import { head, isObject, last, split, isEmpty } from 'lodash';

const {
  asset: { isExternal, url: assetUrl },
} = config;

export const times = (length, fn) => Array.from({ length }, (_, i) => fn(i));

export const sprintf = (template, params) => {
  if (!isObject(params) || !template) {
    return template;
  }
  let output = template;
  Object.keys(params).forEach(p => {
    // eslint-disable-next-line  security/detect-non-literal-regexp
    output = output.replace(new RegExp(`%\\(${p}\\)`, 'g'), params[`${p}`]);
  });
  return output;
};

export const getAsset = (url, ext = 'svg') =>
  isExternal !== 'true' ? url : `${assetUrl}${head(split(last(split(url, '/')), '.'))}.${ext}`;

export const roundTo = (num, round = 2) => +`${Math.round(`${num}e+${round}`)}e-${round}`;

export const pxToRem = value => `${value / 16}rem`;

export const copyToClipboard = str => {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const isIPhone = () => navigator.userAgent.toLowerCase().indexOf('iphone') > -1;

export const isMobileDevice = () => isIPhone() || window.innerWidth < 851;

export const isSafari = () => {
  const isChrome = navigator.userAgent.toLowerCase().indexOf('crios/') > -1;
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('fxios/') > -1;
  const isSafariUA = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
  return isSafariUA && !isChrome && !isFirefox;
};

export const canDownloadCSV = () => !isMobileDevice() || !isIPhone() || isSafari();

export const isSameLocation = (l1, l2) =>
  l1.label !== '' && l2.label !== '' && l1.label === l2.label;

export const sortAlpha = (items, property) => {
  return items.sort((a, b) => {
    const addA = a[`${property}`] ? a[`${property}`].toUpperCase() : '';
    const addB = b[`${property}`] ? b[`${property}`].toUpperCase() : '';
    if (addA < addB) return -1;
    if (addA > addB) return 1;
    return 0;
  });
};

export const isValidPhone = phone => {
  if (isEmpty(phone)) return false;
  return !phone.match(/[a-zA-Z]/);
};


export const validateEmail = email => {
  // eslint-disable-next-line
  return email.match(/[a-z]+@\S+$/);
};

