import React, { useCallback, useState } from "react";
import styled from '@emotion/styled';
import { colors } from 'global/variables';
import { useDispatch } from "react-redux";
import { first, isEmpty, keys } from "lodash";
import withTranslation from "../../../hoc/withTranslation";
import { setUserDisplaySurvey, setUserSurveyCompleted } from "../../../actions";
import { StyledWizardH1 } from "../../Wizard/Global/WizardStyled";
import SurveyQuestion from "./SurveyQuestion";
import SurveyFooter from "./SurveyFooter";
import SurveyIntro from "./SurveyIntro";
import SurveyEnd from "./SurveyEnd";
import { AMPLITUDE_EVENTS, AmplitudeService } from "../../../utils/amplitude";
import { Swipeable } from "../../Form/Swipeable";
import { validateEmail } from "../../../utils/helpers";
import { StyledCloseButton, StyledInnerContainer, StyledSurveyHeader, StyledSurveyMainContainer } from "./SurveyCommon";
import SurveyContactInfo from "./SurveyContactInfo";

const STEPS_AMOUNT = 9
const QUESTIONS_AMOUNT = 7


const stepTopics = [
  'intro',
  'industry',
  'fleetSize',
  'ordersAmount',
  'vehicleTypes',
  'comments',
  'contactMe',
  'english',
]

const StyledQuestionsContainer = styled.div(({ step }) => ({
  height: step > 0 && step < STEPS_AMOUNT && '22rem',
  overflowY: 'scroll',

  '@media screen and (max-width: 850px)': {
    minHeight: 'calc(100% - 8rem)',
  },
}))

const getProgressPercentage = step => {
  const per = step / (QUESTIONS_AMOUNT)
  return `${per * 100}%`
}

const StyledSurveyProgress = styled.div(({ step, visible }) => ({
  display: visible ? 'visible' : 'none',
  borderRadius: '0.4rem',
  height: '10px',
  width: '100%',
  border: `1px solid ${colors.aqua}`,
  boxSizing: 'border-box',
  div: {
    backgroundColor: colors.aqua,
    width: getProgressPercentage(step),
    height: '100%',
    transition: 'all 200ms ease-in-out',
  },
}))

const Survey = ({ isVisible, translations }) => {
  const dispatch = useDispatch();
  const handleSetUserDisplaySurvey = useCallback(() => dispatch(setUserDisplaySurvey(false)), [dispatch]);
  const handleSetUserDisplayDone = useCallback(responses => dispatch(setUserSurveyCompleted(responses)), [dispatch]);

  const [responses, setResponses] = useState({})
  const [step, setStep] = useState(0)
  const [isNextEnabled, setIsNextEnabled] = useState()
  const [hasContactInfo, setHasContactInfo] = useState()

  const { survey: { surveyLabels } } = translations

  const handleOnClose = useCallback(() => {
    const info = { action: 'surveyClosed' }
    AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);
    handleSetUserDisplaySurvey()
  })

  const isValidValue = useCallback((answer, question) => {
    return question.isEmail ? validateEmail(answer) : true
  }, [stepTopics, step, surveyLabels])

  const getAnswerText = (question, answer) => {
    const text = !question.options && answer
    if (!isEmpty(text)) return text

    const selected = keys(question.options)
      .filter(key => key === answer || answer.includes(key))
      .map(key => question.options[`${key}`])
    return question.isUnique ? first(selected) : selected
  }

  const handleOnChangeQuestion = useCallback((topic, answer, extra) => {
    if (!topic) return

    const newResponses = {...responses}
    const hasQuestion = step > 0 && step < STEPS_AMOUNT
    const question = hasQuestion && surveyLabels.questions[`question${step}`]
    const isStepOptional = question && question.isOptional
    const isValidAnswer = isValidValue(answer, question)
    const text = getAnswerText(question, answer)
    newResponses[`${topic}`] = { answer, error: !isValidAnswer, extra, text }
    const nextEnabled = isValidAnswer && !isEmpty(newResponses[`${topic}`].answer) || isStepOptional

    setResponses(newResponses)
    setIsNextEnabled(nextEnabled)
  }, [step, surveyLabels])

  const handleOnNext = useCallback(() => {
    if (step > 0 && !isNextEnabled) return

    // On Next step after contact details, just go to the end
    if (step === 8) {
      AmplitudeService.setUserPropertiesFromSurvey(responses)
      const infoEnd = { action: 'surveyCompleted' }
      AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, infoEnd, true);
      setStep(step + 1)
      return
    }

    let nextStep = step + 1
    if (step > 0) {
      const topic = stepTopics[`${step}`] || ''
      const value = responses[`${topic}`] || ''
      const question = surveyLabels.questions[`question${step}`]
      const info = { action: 'answerQuestion' }
      info[`answer-${topic}`] = getAnswerText(question, value.answer)
      if (value.extra)  info[`answer-${topic}-extra`] = value.extra
      AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);

      const isPossibleEnd = question.possibleEnd
      if (isPossibleEnd && value.answer === isPossibleEnd) {
        AmplitudeService.setUserPropertiesFromSurvey(responses)
        const infoEnd = { action: 'surveyCompleted' }
        AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, infoEnd, true);
        setHasContactInfo(false)
        nextStep = STEPS_AMOUNT
      }
    }

    const question = surveyLabels.questions[`question${step + 1}`]
    const isStepOptional = question && question.isOptional
    const nextResponse = responses[`${stepTopics[`${step + 1}`]}`] || {}
    const nextEnabled = isStepOptional || (!nextResponse.error && !isEmpty(nextResponse.answer))

    setIsNextEnabled(nextEnabled)
    setStep(nextStep)
  }, [step, responses, surveyLabels, isNextEnabled, stepTopics])

  const handleOnBack = useCallback((toStep) => {
    const nextStep = toStep || step - 1
    setStep(nextStep)
    setIsNextEnabled(true)
  }, [step])

  const handleOnContactInfoValid = useCallback((name, email) => {
    if (!email || !email) {
      setIsNextEnabled(false)
      return
    }

    setIsNextEnabled(true)
    const newResponses = {...responses}
    newResponses.name = { answer: name, error: false, text: name }
    newResponses.email = { answer: email, error: false, text: email }
    setResponses(newResponses)

    const info = {
      action: 'answerQuestion',
      'answer-contact': `${name}, ${email}`,
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.SURVEY_ACTION, info, true);
    setHasContactInfo(true)
  }, [responses])

  const handleOnDone = useCallback(() => {
    handleSetUserDisplayDone(responses)
  }, [responses])

  const stepHasQuestion = step > 0 && step < STEPS_AMOUNT
  const showPrevious = step > 1
  let surveyTitle
  switch (step) {
    case 0:
      surveyTitle = surveyLabels.title
      break
    case STEPS_AMOUNT:
      surveyTitle = surveyLabels.titleThankYou
      break
    default: surveyTitle = surveyLabels.stepsTitle
  }

  return (
    <StyledSurveyMainContainer isVisible={isVisible}>
      <Swipeable onSwipedLeft={handleOnNext} onSwipedRight={handleOnBack}>
        <StyledInnerContainer>
          <StyledSurveyHeader>
            <StyledWizardH1>{surveyTitle}</StyledWizardH1>
          </StyledSurveyHeader>
          <StyledSurveyProgress
            step={step - 1}
            visible={stepHasQuestion && step < QUESTIONS_AMOUNT}>
            <div>&nbsp;</div>
          </StyledSurveyProgress>
          <StyledQuestionsContainer step={step}>
            {step === 0 && (
              <SurveyIntro onNext={handleOnNext} />
            )}
            {stepHasQuestion && step <= QUESTIONS_AMOUNT && (
              <SurveyQuestion
                index={step}
                question={surveyLabels.questions[`question${step}`]}
                topic={stepTopics[`${step}`]}
                onChange={handleOnChangeQuestion}
                onNext={handleOnNext}
                value={responses[stepTopics[`${step}`]] && responses[stepTopics[`${step}`]].answer}
                valueExtra={responses[stepTopics[`${step}`]] && responses[stepTopics[`${step}`]].extra}
                QUESTIONS_AMOUNT={QUESTIONS_AMOUNT}
              />
            )}
            {step > QUESTIONS_AMOUNT && step < STEPS_AMOUNT && (
              <SurveyContactInfo onNext={handleOnNext} onInfoValid={handleOnContactInfoValid} />
            )}
            {step === STEPS_AMOUNT && (
              <SurveyEnd onDone={handleOnDone} onBack={() => handleOnBack(QUESTIONS_AMOUNT - 1)} hasContactInfo={hasContactInfo} />
            )}
          </StyledQuestionsContainer>
          {stepHasQuestion && (
            <SurveyFooter
              showPrevious={showPrevious}
              onNext={handleOnNext}
              onBack={handleOnBack}
              isNextEnabled={isNextEnabled}
            />
          )}
          <StyledCloseButton onClick={handleOnClose} />
        </StyledInnerContainer>
      </Swipeable>
    </StyledSurveyMainContainer>
  );
};

export default withTranslation(Survey);
