import { includes } from "lodash";
import moment from "moment";
import { getTourEnd, getTourStart } from "../SolutionHelpers";

export const getGeoInfoFromObjects = objList =>
  objList && {
    type: 'FeatureCollection',
    features: Object.keys(objList)
      .map(key => objList[`${key}`].geo)
      .filter(f => f),
  };

export const onEachFeature = (feature, layer, mapData, display, tourData, onTourLineClick) => {
  if (feature.geometry.type === 'LineString') {
    const tour = mapData.geo.features.find(
      f =>
        f.geometry.type === 'LineString' &&
        includes(display.routeIds, f.properties.routeId),
    );
    if (tour) {
      layer.bindPopup(`
          <table>
           <tr><td>Tour</td><td>${tour.properties.routeId + 1}</td></tr>
           <tr><td>Start</td><td>${moment(
        getTourStart(tour.properties.vehicle.id, tourData.tours),
      ).format('LT')}</td></tr>
           <tr><td>End</td><td>${moment(
        getTourEnd(tour.properties.vehicle.id, tourData.tours),
      ).format('LT')}</td></tr>
          </table>
        `);
      layer.on({ click: () => onTourLineClick(feature) });
    }
  }
};
