import React, { useEffect, useState } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import {
  StyledFullWidthContainer,
  StyledText,
  StyledWizardContent,
  StyledWizardH1,
} from '../Global/WizardStyled';
import Stops from './Stops';
import Label from '../../Form/Label';
import { getTourDemand } from '../../../utils/OrdersHelpers';
import {
  getNumberOfDepotStops,
  getLastValidIteration,
  isDemandVisible,
} from '../../../utils/SolutionHelpers';
import TourDispatch from '../TourDispatch/TourDispatch';
import { colors } from '../../../global/variables';
import { canDownloadCSV, isMobileDevice } from '../../../utils/helpers';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';

const StyledGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;

const firstGroupDetailStyle = {
  flex: '1 1 0',
  marginRight: '2rem',
};

const { secondaryColor, primaryColorHovered } = colors;

const bgImage = `linear-gradient(
    to right,
    ${secondaryColor} 0%,
    ${primaryColorHovered} 100%
  )`;

const StyledTourDispatcherContainer = styled.div({
  position: 'absolute',
  top: '1.2rem',
  right: '1.2rem',
  backgroundImage: bgImage,
  borderRadius: '0.2rem',
  width: canDownloadCSV() ? '9rem' : '6rem',
});

const Step4Container = ({
  user,
  tourData: { tours = [] },
  selectedTours,
  solution,
  tourPlanner,
  orders,
  helpers: { formatDuration },
  translations,
}) => {
  const [demandLabel, setDemandLabel] = useState('');
  const [isDemandVisibleLocal, setIsDemandVisibleLocal] = useState('');
  const {
    tourSolution: { tourHeaders },
    wizard: {
      toursDetails: { toursDetailsTitle, stopsTrans, durationTrans, dropsTrans },
    },
  } = translations;

  useEffect(() => {
    const demandVisible = isDemandVisible(orders, tourPlanner);
    let label = '';
    if (demandVisible && !isMobileDevice()) {
      label = getCSVConfigLastDemandLabel(tourPlanner, 'demand', dropsTrans);
    }

    setIsDemandVisibleLocal(demandVisible);
    setDemandLabel(label);
  }, [orders, tourPlanner, dropsTrans, setDemandLabel, setIsDemandVisibleLocal]);

  const tour = tours[selectedTours[0]];
  const formattedDuration = tour.statistic.duration
    ? formatDuration(Math.floor(tour.statistic.duration / 60))
    : 'Unknown';

  const request = getLastValidIteration(solution).request;

  return (
    <StyledWizardContent>
      <StyledWizardH1>{toursDetailsTitle}</StyledWizardH1>
      {tour && request && (
        <StyledTourDispatcherContainer>
          <TourDispatch
            user={user}
            selectedTours={selectedTours}
            solution={solution}
            tourPlanner={tourPlanner}
            tour={tour}
            request={request}
            origin="tour details"
          />
        </StyledTourDispatcherContainer>
      )}
      <StyledGroup>
        <div style={firstGroupDetailStyle}>
          <Label>{stopsTrans}</Label>
          <StyledText>{tour.stops.length - getNumberOfDepotStops(tour.stops)}</StyledText>
        </div>
        <div style={firstGroupDetailStyle}>
          <Label>{isDemandVisibleLocal ? demandLabel : tourHeaders[1]}</Label>
          <StyledText>{getTourDemand(tour, request)}</StyledText>
        </div>
        <div style={firstGroupDetailStyle}>
          <Label>{durationTrans}</Label>
          <StyledText>{formattedDuration}</StyledText>
        </div>
      </StyledGroup>
      <StyledFullWidthContainer>
        <Stops
          stops={tour.stops}
          request={request}
          tourPlanner={tourPlanner}
          user={user}
          demandLabel={demandLabel}
          isDemandVisible={isDemandVisibleLocal}
        />
      </StyledFullWidthContainer>
    </StyledWizardContent>
  );
};

export default withTranslation(Step4Container);
