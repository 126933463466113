import React, { useCallback, useState } from "react";
import { isEmpty } from "lodash";
import styled from "@emotion/styled";
import withTranslation from "../../../hoc/withTranslation";
import { StyledTextInput } from "../../Wizard/Global/WizardStyled";
import Label from "../../Form/Label";
import {
  StyledQuestionContainer,
  StyledQuestionLabelContainer,
  StyledQuestionTextBoxContainer,
} from "./SurveyCommon";
import { validateEmail } from "../../../utils/helpers";


const StyledLabelsContainer = styled.div({
  '*': {
    display: "inline-block",
    marginRight: '1rem',
  },
})
const SurveyContactInfo = ({ onInfoValid, translations }) => {
  const [hasError, setHasError] = useState({})
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emailRepeat, setEmailRepeat] = useState('')
  const { survey: { surveyLabels: { contactInfo } } } = translations

  const isValidValue = useCallback(field => {
    let isValid = false
    if (field === 'name') isValid = !isEmpty(name)
    if (field === 'email') isValid = validateEmail(email)
    if (field === 'emailRepeat') isValid = email === emailRepeat

    return isValid
  }, [name, email, emailRepeat])

  const handleOnChange = useCallback((evt, field) => {
    const value = evt.target.value
    if (field === 'name') setName(value)
    else if (field === 'email') setEmail(value)
    else if (field === 'emailRepeat') setEmailRepeat(value)

    const newHasError = {...hasError}
    delete newHasError[`${field}`]
    setHasError(newHasError)

    if (onInfoValid) {
      const nameLocal = field === 'name' ? value : name
      const emailLocal = field === 'email' ? value : email
      const emailRepeatLocal = field === 'emailRepeat' ? value : emailRepeat
      const isValidCombo = isEmpty(newHasError) && !isEmpty(nameLocal) &&
        !isEmpty(emailLocal) && !isEmpty(emailRepeatLocal) &&
        emailLocal === emailRepeatLocal
      if (isValidCombo) onInfoValid(nameLocal, emailLocal)
      else onInfoValid()
    }
  }, [hasError, name, email, emailRepeat, onInfoValid])

  const handleOnBlur = useCallback(field => {
    const isValid = isValidValue(field)
    const newHasError = {...hasError}
    if (isValid) delete newHasError[`${field}`]
    else newHasError[`${field}`] = true
    setHasError(newHasError)

    if (onInfoValid) {
      const isValidCombo = isEmpty(newHasError) && !isEmpty(name) &&
        !isEmpty(email) && !isEmpty(emailRepeat) &&
        email === emailRepeat
      if (isValidCombo) onInfoValid(name, email)
      else onInfoValid()
    }
  }, [hasError, name, email, emailRepeat, onInfoValid])

  return (
    <StyledQuestionContainer>
      <StyledQuestionLabelContainer />
      <StyledQuestionLabelContainer>
        <p>{contactInfo.label}</p>
      </StyledQuestionLabelContainer>
      <StyledQuestionTextBoxContainer>
        <StyledLabelsContainer>
          <Label direction="vertical">{contactInfo.fieldNameLabel}</Label>
          {hasError && hasError.name && (<Label error direction="vertical">{contactInfo.fieldNameError}</Label>)}
        </StyledLabelsContainer>
        <StyledTextInput
          withMarginBottom
          value={name}
          hasError={hasError.name}
          onBlur={() => handleOnBlur('name')}
          onChange={(evt) => handleOnChange(evt, 'name')}
        />
      </StyledQuestionTextBoxContainer>
      <StyledQuestionTextBoxContainer>
        <StyledLabelsContainer>
          <Label direction="vertical">{contactInfo.fieldEmailLabel}</Label>
          {hasError && hasError.email && (<Label error direction="vertical">{contactInfo.fieldEmailError}</Label>)}
        </StyledLabelsContainer>
        <StyledTextInput
          withMarginBottom
          hasError={hasError.email}
          value={email}
          onBlur={() => handleOnBlur('email')}
          onChange={(evt) => handleOnChange(evt, 'email')}
        />
      </StyledQuestionTextBoxContainer>
      <StyledQuestionTextBoxContainer>
        <StyledLabelsContainer>
          <Label direction="vertical">{contactInfo.fieldEmailRepeatLabel}</Label>
          {hasError && hasError.emailRepeat && (<Label error direction="vertical">{contactInfo.fieldEmailRepeatError}</Label>)}
        </StyledLabelsContainer>
        <StyledTextInput
          withMarginBottom
          value={emailRepeat}
          hasError={hasError.emailRepeat}
          onBlur={() => handleOnBlur('emailRepeat')}
          onChange={(evt) => handleOnChange(evt, 'emailRepeat')}
        />
      </StyledQuestionTextBoxContainer>
    </StyledQuestionContainer>
  );
};

export default withTranslation(SurveyContactInfo);
