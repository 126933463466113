import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import styled from '@emotion/styled';
import { colors, USE_ALTERNATIVE_THEME } from 'global/variables';
import FeedbackPanel from './FeedbackPanel';
import feedbackIconNormal from '../../../global/img/feedback-access.svg';
import feedbackIconActiveNormal from '../../../global/img/feedback-access-active.svg';
import feedbackIconActiveBlinkNormal from '../../../global/img/feedback-access-active-blink.svg';
import feedbackIconActiveXmas from '../../../global/img/feedback-access-active-xmas.svg';
import feedbackIconActiveBlinkXmas from '../../../global/img/feedback-access-active-blink-xmas.svg';
import Modal from '../../Presentation/Modal';
import { getAsset } from '../../../utils/helpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';

const { white } = colors;

const feedbackIcon = !USE_ALTERNATIVE_THEME ? feedbackIconNormal : feedbackIconActiveXmas;
const feedbackIconActive = !USE_ALTERNATIVE_THEME
  ? feedbackIconActiveNormal
  : feedbackIconActiveXmas;
const feedbackIconActiveBlink = !USE_ALTERNATIVE_THEME
  ? feedbackIconActiveBlinkNormal
  : feedbackIconActiveBlinkXmas;

const steps = [
  { length: 5000, icon: `url(${getAsset(feedbackIconActive)})` },
  { length: 500, icon: `url(${getAsset(feedbackIconActiveBlink)})` },
  { length: 5000, icon: `url(${getAsset(feedbackIconActive)})` },
  { length: 500, icon: `url(${getAsset(feedbackIconActiveBlink)})` },
  { length: 5000, icon: `url(${getAsset(feedbackIconActive)})` },
];

const StyledFeedbackAccessContainer = styled.div(({ flaggingStep }) => ({
  position: 'fixed',
  top: '4rem',
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '2rem',
  backgroundColor: white,
  cursor: 'pointer',
  backgroundImage: flaggingStep >= 0 ? steps[Number(flaggingStep)].icon : `url(${getAsset(feedbackIcon)})`,
  animationName: flaggingStep >= 0 ? 'Pulse' : 'none',
  animationDuration: '.8s',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'border-box',
  backgroundPosition: '.645rem .5rem',
  zIndex: 10099,
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  '@media screen and (max-width: 850px)': {
    top: '.2rem',
    right: '.4rem',
    boxShadow: 'none',
    backgroundColor: flaggingStep >= 0 ? white : 'transparent',
  },
  '@media screen and (min-width: 851px)': {
    left: '28rem',
    ':hover': {
      top: '3.75rem',
      left: '27.75rem',
      width: '3rem',
      backgroundPosition: '.85rem .75rem',
      height: '3rem',
      backgroundImage: `url(${getAsset(feedbackIconActiveBlink)})`,
    },
  },
}));

const StyledFeedbackPanelContainer = styled.div({
  textAlign: 'center',
  padding: '1rem',
  verticalAlign: 'middle',
  maxHeight: 'calc(100% - 2rem)',
  overflowY: 'scroll',
  borderRadius: '0.25rem 0.25rem 2rem 2rem',
});

const FeedbackContainer = () => {
  const usageContext = useSelector(({ usageContext: stateUsageContext }) => stateUsageContext);
  const [showPanel, setShowPanel] = useState(false);
  const [lastContext, setLastContext] = useState({ dispatchModes: [] });
  const [flaggingStep, setFlaggingStep] = useState(-1);

  const shouldInform = (context, last) => {
    if (size(context.today.dispatchModes) !== size(last.dispatchModes)) return true;
    return false;
  };

  const timeToNext = useCallback(
    step => {
      if (step === -1 && flaggingStep === -1) return;
      setTimeout(() => {
        const next = step >= size(steps) - 1 ? -1 : step + 1;
        setFlaggingStep(next);
        timeToNext(next);
      }, steps[Number(step)].length);
    },
    [setFlaggingStep, flaggingStep],
  );

  const togglePanel = useCallback(() => {
    const action = showPanel ? 'close' : 'open';
    const info = { action };
    AmplitudeService.log(AMPLITUDE_EVENTS.FEEDBACK_ACTION, info);
    setShowPanel(!showPanel);
    setFlaggingStep(-1);
  }, [setShowPanel, showPanel, setFlaggingStep]);

  useEffect(() => {
    if (shouldInform(usageContext, lastContext)) {
      setFlaggingStep(0);
      setLastContext({ dispatchModes: usageContext.today.dispatchModes });
      timeToNext(0);
    }
  }, [usageContext, lastContext, setLastContext, timeToNext]);

  return (
    <>
      <Modal isDark isVisible={showPanel} isFull fromTop>
        <StyledFeedbackPanelContainer>
          <FeedbackPanel onDone={togglePanel} usageContext={usageContext} />
        </StyledFeedbackPanelContainer>
      </Modal>
      <StyledFeedbackAccessContainer onClick={togglePanel} flaggingStep={flaggingStep} />
    </>
  );
};

export default FeedbackContainer;
