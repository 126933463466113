import { SET_ORDERS_NOT_LOCATED, CLEAR_ORDERS_NOT_LOCATED } from 'actions/index';

export default (state = [], action) => {
  switch (action.type) {
    case SET_ORDERS_NOT_LOCATED: {
      return state.concat(action.payload);
    }
    case CLEAR_ORDERS_NOT_LOCATED: {
      return [];
    }
    default: {
      return state;
    }
  }
};
