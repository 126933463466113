import { filter, size, some, includes, isEmpty } from 'lodash';

const isValidIteration = iteration => iteration.response && iteration.request && iteration.geoJSON;

export function getLastValidIteration(solution) {
  const valid =
    solution &&
    solution.iterations &&
    solution.iterations.filter(iteration => isValidIteration(iteration));
  return valid && valid.length > 0 ? valid[valid.length - 1] : null;
}

export function getSequenceNumbersOfJobs(plannedJobs = []) {
  const result = {};
  plannedJobs.forEach((job, index) => {
    result[job.id] = index;
  });
  return result;
}

export function getToursDataFromIteration(iteration) {
  return iteration && iteration.response
    ? {
        ...iteration.response,
        ...iteration.request.json,
        problemUploaded: !!iteration.request.uploaded,
        timestamp: iteration.request.timestamp,
        jobNumbers: iteration.request.json
          ? getSequenceNumbersOfJobs(iteration.request.json.plan.jobs)
          : {},
      }
    : null;
}

export function getLastValidIterationToursData(solution) {
  const lastValidSolution = getLastValidIteration(solution);
  return getToursDataFromIteration(lastValidSolution);
}

export function getOnlyValidIterations(solution) {
  return solution && solution.iterations
    ? solution.iterations.filter(
        iteration => iteration.request && iteration.response && iteration.geoJSON,
      )
    : [];
}

export function getVehicleTypeById(typeId, fleet) {
  return fleet ? fleet.types.find(type => type.id === typeId) : null;
}

export function getJobIdsForStops(stops) {
  return stops.reduce(
    (acc, stop) =>
      acc.concat(stop.activities.map(activity => `${activity.jobId}#${activity.jobTag}`)),
    [],
  );
}

export function getDemand(tour, plan) {
  if (!tour || !plan) {
    return null;
  }
  const jobIds = getJobIdsForStops(tour.stops);

  const totalDemand = [];
  const multiJobs = plan.jobs.filter(job => job.demand === undefined);
  const justJobs = plan.jobs.filter(job => job.demand !== undefined);

  justJobs.push(
    ...multiJobs.reduce((jobs, multiJob) => {
      let flatJobs = [];
      flatJobs = flatJobs.concat(
        multiJob.places.pickups.map(pickup => ({
          id: multiJob.id,
          tag: pickup.tag,
          demand: pickup.demand,
        })),
      );
      flatJobs = flatJobs.concat(
        multiJob.places.deliveries.map(delivery => ({
          id: multiJob.id,
          tag: delivery.tag,
          demand: delivery.demand,
        })),
      );

      return jobs.concat(flatJobs);
    }, []),
  );

  justJobs.forEach(job => {
    if (includes(jobIds, `${job.id}#${job.tag}`) && job.demand.length) {
      job.demand.forEach((demand, index) => {
        totalDemand[Number(index)] = totalDemand[Number(index)] || 0;
        totalDemand[Number(index)] += demand;
      });
    }
  });

  return totalDemand;
}

export function getTourStart(vehicleId, tours) {
  const tour = tours.find(t => t.vehicleId === vehicleId);
  if (!tour) {
    return undefined;
  }
  const departure = tour.stops.find(s => s.activities.some(i => i.jobId === 'departure'));
  return (departure || tour.stops[0]).time.departure;
}

export function getTourEnd(vehicleId, tours) {
  const tour = tours.find(t => t.vehicleId === vehicleId);
  if (!tour) {
    return undefined;
  }
  const arrival = tour.stops.find(s => s.activities.some(i => i.jobId === 'arrival'));
  return (arrival && arrival.time.arrival) || tour.stops[tour.stops.length - 1].time.departure;
}

export const getNumberOfDepotStops = (stops = []) =>
  size(
    filter(stops, ({ activities }) =>
      some(activities, ({ jobId }) => jobId === 'arrival' || jobId === 'departure'),
    ),
  );

export function getLoadPercentage(tourDemand, vehicleType) {
  if (!tourDemand || !vehicleType) {
    return ['-'];
  }
  return vehicleType.capacity.map(
    (item, index) => `${(Math.min(tourDemand[Number(index)] / item, 1) * 100).toFixed(0)}%`,
  );
}

const getNewJobs = (requestA, requestB) => {
  if (!requestA || !requestB) {
    return [];
  }

  const idsA = requestA.plan.jobs.map(j => j.id);
  const idsB = requestB.plan.jobs.map(j => j.id);
  return idsB.filter(v => !includes(idsA, v));
};

const getFixedJobs = request => {
  if (!request || !request.plan || !request.plan.relations) {
    return [];
  }

  return request.plan.relations
    .filter(r => r.type === 'sequence')
    .map(r => r.jobs)
    .reduce((acc, jobs) => acc.concat(jobs), []);
};

const getAffectedTours = (iterationResponse, newJobIds) => {
  if (
    newJobIds.length === 0 ||
    !iterationResponse ||
    !iterationResponse.tours ||
    iterationResponse.tours.length === 0
  ) {
    return [];
  }

  return iterationResponse.tours
    .filter(tour => {
      const jobIds = getJobIdsForStops(tour.stops);
      return newJobIds.some(id => includes(jobIds, id));
    })
    .map(t => t.vehicleId);
};

export function getChangesForAllIterations(solution) {
  const iterations = solution && solution.iterations;

  return iterations.map((_, i, a) => {
    const thisRequest = a[Number(i)] && a[Number(i)].request && a[Number(i)].request.json;
    const previousRequest = a[i - 1] && a[i - 1].request && a[i - 1].request.json;
    const thisResponse = a[Number(i)] && a[Number(i)].response;
    const newJobsId = getNewJobs(previousRequest, thisRequest);

    return {
      fixedJobs: getFixedJobs(thisRequest),
      newJobIds: newJobsId,
      affectedTours: getAffectedTours(thisResponse, newJobsId),
    };
  });
}

export function getTotalJobsInStop(stop) {
  return stop.activities.filter(a => includes(['delivery', 'pickup'], a.type)).length;
}

export function getTotalJobsInTour(stops) {
  return stops.reduce((total, stop) => {
    return total + getTotalJobsInStop(stop);
  }, 0);
}

export function getTotalJobsInTours(tours) {
  return tours && tours.reduce((total, tour) => total + getTotalJobsInTour(tour.stops), 0);
}

export function isDemandVisible(orders, tourPlanner) {
  if (isEmpty(orders)) return false;
  if (tourPlanner && !isEmpty(tourPlanner.lastDemandLabel)) return true;
  const totalDemand = orders.reduce((t, o) => t + o.Demand, 0);
  return size(orders) !== totalDemand;
}

export const isIterationSolution = iteration => {
  if (!iteration || isEmpty(iteration)) return false;
  return !!(iteration && iteration.response && !isEmpty(iteration.response.tours));
};
