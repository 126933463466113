import styled from '@emotion/styled';
import { getAsset } from 'utils/helpers';
import { colors, fontSize } from '../../../global/variables';
import check from '../../../global/img/checkmark-fill.svg';
import exclamation from '../../../global/img/exclamation-fill.svg';

const {
  darkSolidBackground,
  darkerSolidBackground,
  white,
  secondaryTextColorDarkBg,
  placeholderTextColor,
  aqua,
} = colors;
const { small, smaller } = fontSize;

export const StyledContrastPanelContainer = styled.div({
  width: '100%',
  height: '100%',
  backgroundColor: white,
  position: 'absolute',
  top: 0,
  left: 0,
  textAlign: 'center',
  zIndex: 999,
});

export const StyledContrastPanel = styled.div({
  borderRadius: '0.2rem',
  backgroundColor: darkSolidBackground,
  width: 'calc(100% - 2rem)',
  height: 'calc(100% - 0.4rem)',
  margin: 'auto',
  textAlign: 'left',
  boxSizing: 'border-box',
});

export const StyledContrastPanelHeader = styled.div(({ size }) => ({
  padding: '1rem 1rem 0.6rem 1rem',
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.3)',
  height: size === 'S' ? '5.4rem' : '8.2rem',
  position: 'relative',
}));

export const StyledContrastPanelHeaderText = styled.div(
  ({ darker, paddingBottom, found, icon }) => ({
    backgroundImage: icon ? `url( ${found ? getAsset(check) : getAsset(exclamation)})` : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 5px',
    padding: paddingBottom
      ? `0.2rem 0 1.4rem ${icon ? '16px' : '0'}`
      : `0.2rem 0 0 ${icon ? '16px' : '0'}`,
    color: found ? aqua : darker ? white : secondaryTextColorDarkBg,
    fontSize: small,
    fontWeight: found ? 700 : 400,
    opacity: '1',
    transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  }),
);

export const StyledContrastPanelTableHeader = styled.div(({ hasAction }) => ({
  display: 'grid',
  gridTemplateColumns: hasAction ? '1fr 1.5fr 1.6rem' : '1fr 1.5fr',
  div: {
    textTransform: 'uppercase',
    fontSize: smaller,
    color: secondaryTextColorDarkBg,
  },
  position: 'absolute',
  bottom: '0.4rem',
  left: '1rem',
  right: hasAction ? '0.5rem' : '1rem',
}));

export const StyledContrastPanelFooter = styled.div(({ isDouble }) => ({
  display: isDouble && 'grid',
  gridTemplateColumns: '1fr 1rem 1fr',
  position: 'absolute',
  bottom: '1.4rem',
  left: '2rem',
  right: '2rem',
  backgroundColor: darkSolidBackground,
}));

export const StyledContrastPanelColumnsContainer = styled.div({
  backgroundColor: darkerSolidBackground,
  height: 'calc(100% - 14.4rem)',
  overflowY: 'scroll',
  padding: '0',
});

export const StyledContrastPanelColumn = styled.div(({ hasAction }) => ({
  display: 'grid',
  gridTemplateColumns: hasAction ? '1fr 1.5fr 1.6rem' : '1fr 1.5fr',
  lineHeight: '3rem',
  verticalAlign: 'middle',
  borderBottom: `solid 1px ${placeholderTextColor}`,
  fontSize: small,
  color: secondaryTextColorDarkBg,
  padding: hasAction ? '0.25rem 0.5rem 0.25rem 1rem' : '0.25rem 1rem',
}));

export const StyledCategory = styled.div({
  width: '100%',
});
