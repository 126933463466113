import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from 'lodash';
import withTranslation from 'hoc/withTranslation';
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { useDispatch } from "react-redux";
import {
  StyledContrastPanel,
  StyledContrastPanelContainer, StyledContrastPanelFooter,
  StyledContrastPanelHeader, StyledContrastPanelHeaderText,
} from "../Global/WizardStyledContrastPanel";
import { StyledText } from "../Global/WizardStyled";
import Button from "../../Form/Button";
import { colors, fontSize } from '../../../global/variables';
import { getAddressLocation } from "../../../utils/GeoCoder";
import { setOrders, setTourParameter } from "../../../actions";
import { APP_MODES, getAppMode } from "../../../utils/urlHelpers";

const { darkerSolidBackground, aqua, white } = colors;
const { small } = fontSize;
const appMode = getAppMode();

export const StyledOCRWordContainer = styled.div({
  backgroundColor: darkerSolidBackground,
  height: 'calc(100% - 13.4rem)',
  overflowY: 'scroll',
  padding: '1rem',
});

const StyledOCRWord = styled.div(({ isWordSelected }) => ({
  lineHeight: '1.5rem',
  backgroundColor: isWordSelected ? aqua : 'grey',
  borderRadius: '0.8rem',
  margin: '0 0.5rem 0.5rem 0',
  padding: '0 .6rem',
  display: 'inline-block',
  cursor: 'pointer',
  fontSize: small,
  '&:hover': {
    color: !isWordSelected && white,
    fontWeight: 700,
  },
}));

const OrderOCRPanel = ({
    OCRResult,
    onClose,
    oAuth,
    tourPlanner,
    editedProperty,
    translations: { wizard: { orders: { csvConfigTrans } } },
}) => {
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(p => dispatch(setTourParameter(p)), [dispatch]);
  const handleSetOrder = useCallback(
    order => {
      dispatch(setOrders([order]));
    },
    [dispatch],
  );

  const [order, setOrder] = useState(null);
  const [selectedWordsArr, setSelectedWordsArr] = useState([]);
  const [selectedText, setSelectedText] = useState('');
  const [hasValidAddress, setHasValidAddress] = useState(-1);

  console.log("OCR", OCRResult);

  const getOrder = useCallback((text, pos, name, phone) => {
    if (tourPlanner && tourPlanner.editedOrder) {
      const newOrder = assign({}, tourPlanner.editedOrder);
      if (!isEmpty(text)) newOrder.Address = text;
      if (pos){
        newOrder.Latitude = pos.lat;
        newOrder.Longitude = pos.lng;
      }
      if (!isEmpty(name)) newOrder.Name = name;
      if (!isEmpty(phone)) newOrder.Phone = phone;
      return newOrder;
    }

    return  {
      Address: text,
      Name: name || '',
      Demand: 1,
      'Service time (min)': 5,
      Latitude: pos.lat,
      Longitude: pos.lng,
      InternalID: v4(),
      Phone: phone || '',
      isDetectedInFile: true,
    };
  }, [tourPlanner])

  const validateText = useCallback(text => {
    setSelectedText(text);
    const wordsCount = text.split(' ').length;
    if (isEmpty(text) || wordsCount <= 3) {
      setHasValidAddress(0);
      handleSetTourParameter({ editedOrder: {} });
      return;
    }
    if (editedProperty === 'name' || editedProperty === 'phone') {
      const name = editedProperty === 'name' ? text : '';
      const phone = editedProperty === 'phone' ? text : '';
      const newOrder = getOrder('', null, name, phone);
      setOrder(newOrder);
      handleSetTourParameter({ editedOrder: { ...newOrder } });
      return;
    }

    getAddressLocation(text, oAuth).then(result => {
      const isValidAddress = result && result.position;
      setHasValidAddress(isValidAddress ? 1 : 0);

      console.log("RESULT", result);

      const newOrder = isValidAddress ? getOrder(text, result.position) : null;
      setOrder(newOrder);
      handleSetTourParameter({ editedOrder: { ...newOrder } });
    });

  }, [oAuth, setSelectedText, setHasValidAddress, handleSetTourParameter]);

  const getText = useCallback(indexes => {
    if (isEmpty(indexes)) return '';
    const filtered = OCRResult.words.filter((word, index) => indexes.includes(index));
    return filtered.map(w => w.text).join(' ');
  }, [OCRResult]);

  const onWordClick = useCallback(wordIndex => {
    let arr;
    if (selectedWordsArr.includes(wordIndex)) {
      arr = selectedWordsArr.filter(i => i !== wordIndex);
      setSelectedWordsArr(arr);
    } else {
      arr = selectedWordsArr;
      arr.push(wordIndex);
      setSelectedWordsArr(arr);
    }
    validateText(getText(arr));
  }, [setSelectedText, selectedWordsArr, setSelectedWordsArr]);

  const isWordSelected = useCallback(wordIndex => {
    return selectedWordsArr.includes(wordIndex);
  }, [selectedWordsArr]);

  const onCancel = useCallback(() => {
    validateText('');
    if (onClose) onClose();
  }, [onClose]);

  const onSave = useCallback(() => {
    if (appMode === APP_MODES.OCR_AWS_EDIT) {
      console.log("CHANGE  MODE", order)
      handleSetTourParameter({ ordersMode: 'manual', editedOrder: order });
    } else {
      validateText('');
      handleSetOrder(order);
    }

    if (onClose) onClose(order);
  }, [order, onClose]);

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) onCancel();
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  const title = appMode === APP_MODES.OCR_AWS_ALL_FIELDS ?
    'Click all words included in the selected category' :
    'Click all words included in the address'

  return (
    <StyledContrastPanelContainer>
      <StyledContrastPanel>
        <StyledContrastPanelHeader size="S">
          <StyledText bold contrast>
            {title}
          </StyledText>
          {selectedText && (
            <StyledContrastPanelHeaderText paddingBottom found={hasValidAddress} icon>
              {selectedText}
            </StyledContrastPanelHeaderText>
          )}
        </StyledContrastPanelHeader>
        <StyledOCRWordContainer>
          {OCRResult.words.map((word, index) => (
            <StyledOCRWord isWordSelected={isWordSelected(index)} key={`${index}-a`} onClick={() => onWordClick(index)}>{word.text}</StyledOCRWord>
          ))}
        </StyledOCRWordContainer>
        <StyledContrastPanelFooter isDouble>
          <Button inverted contrast text={csvConfigTrans.configButtonCancel} onClick={onCancel} />
          <div />
          <Button id="button-ocr-confirm" text={csvConfigTrans.configButtonSave} onClick={onSave} disabled={hasValidAddress !== 1} />
        </StyledContrastPanelFooter>
      </StyledContrastPanel>
    </StyledContrastPanelContainer>
  );
};

export default withTranslation(OrderOCRPanel);
