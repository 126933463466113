import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from '@emotion/styled';
import Tesseract from 'tesseract.js';
import { FileDrop } from 'react-file-drop';
import withTranslation from 'hoc/withTranslation';
import { setError, setLoader, setUploadedFile, setUploadedImage } from 'actions';
import { isEmpty, size } from 'lodash';
import { css } from '@emotion/react';
import { AnalyzeDocumentCommand, TextractClient } from "@aws-sdk/client-textract";
import config from 'config';
import { colors, fontSize } from '../../../global/variables';
import uploadIcon from '../../../global/img/upload-file.svg';
import { getAsset, isMobileDevice } from '../../../utils/helpers';
import { StyledIcon } from '../Global/WizardStyled';
import { APP_MODES, getAppMode } from "../../../utils/urlHelpers";
import OrderOCRPanel from "./OrderOCRPanel";
import { getTableDataFromOCR } from "../../../utils/ocr/processOCR";
import { getCSVFromTable } from "../../../utils/ocr/ordersOCR";

const { primaryBorderColor, aqua, primaryTextColor, secondaryTextColor } = colors;
const { small } = fontSize;
const appMode = getAppMode();

const StyledUploader = styled.div`
  height: ${props => (props.hasOrders ? '5rem' : '10rem')};
  padding: ${props => (props.hasOrders ? '1.4rem 0.4rem' : '3.8rem 1rem')};
  line-height: 2rem;
  text-align: center;
  border-radius: 0.4rem;
  border: dashed 1px ${primaryBorderColor};
  box-shadow: ${props =>
    props.isDragging ? `inset 0px 0px 0px 3px ${aqua}` : `inset 0px 0px 0px 0px ${aqua}`};
  border: ${props =>
    props.isDragging ? `solid 1.5px ${aqua}` : `dashed 1.5px ${primaryBorderColor}`};
  background-color: ${props => (props.isDragging ? `rgba(255,255,255,.5)` : `rgba(255,255,255,0)`)};
  box-sizing: border-box;
  background-size: 200% auto;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  strong,
  div {
    text-align: center;
    margin: 0 auto;
  }

  @media screen and (max-width: 850px) {
    height: ${props => (props.hasOrders ? '5rem' : '10rem')};
    padding: ${props => (props.hasOrders ? '1.5rem' : '4rem 3rem')};
  }
`;

const StyledDropText = styled.span({
  fontWeight: '700',
  height: '1.75rem',
  fontSize: small,
  color: secondaryTextColor,
  marginRight: '0.5rem',
});

const StyledDropTextLight = styled.span({
  fontWeight: '300',
  height: '1.75rem',
  textTransform: 'uppercase',
  fontSize: small,
  color: primaryTextColor,
  marginRight: '0.5rem',
  opacity: 0.75,
});

const StyleFileUpload = styled.input({
  width: '0.01rem',
  height: '0.01rem',
});

const StyleFileUploadLabel = styled.label({
  display: 'inline-block',
  fontWeight: '700',
  height: '1.5rem',
  cursor: 'pointer',
  fontSize: small,
  color: 'rgba(15, 22, 33, .8)',
  marginRight: '0.175rem',
});

const StyledIconDownloadStyle = css`
  background-image: url(${getAsset(uploadIcon)});
  background-position-y: 0.1rem;
`;

const FileUploadArea = ({
  orders,
  errorState,
  oAuth,
  translations: {
    error,
    global: { orTrans },
    wizard: {
      orders: { dropFileDescription, addMore, browseFiles },
    },
  },
}) => {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const [OCRResult, setOCRResult] = useState(null);
  const [isOCRVisible, setIsOCRVisible] = useState(false);
  const handleSetLoader = useCallback(data => dispatch(setLoader(data)), [dispatch]);
  const handleSetError = useCallback(data => dispatch(setError(data)), [dispatch]);
  const handleSetFile = useCallback(data => dispatch(setUploadedFile(data)), [dispatch]);
  const handleSetUploadedImage = useCallback(data => dispatch(setUploadedImage(data)), [dispatch]);
  const browseEl = useRef(null);
  const uploadedImage = useSelector(state => state.uploadedImage);

  const onAreaClick = () => {
    browseEl.current.click();
  };

  useEffect(() => {
    handleSetLoader({ isLoading: false });
  }, [orders, errorState, handleSetLoader]);

  const handleDrop = useCallback(
    (files, mode) => {
      setIsDragging(false);
      if (files.length !== 1) {
        handleSetError(error.invalidFileLength);
        return;
      }
      if (appMode === APP_MODES.OCR) {
        if (!/\.(csv|jpg|jpeg|png|gif)$/.test(files[0].name)) {
          handleSetError(error.invalidFileExtension);
          return;
        }
      } else if (appMode === APP_MODES.OCR_AWS || appMode === APP_MODES.OCR_AWS_EDIT) {
          if (!/\.(csv|jpg|jpeg|png|gif|pdf)$/.test(files[0].name)) {
            handleSetError(error.invalidFileExtension);
            return;
          }
      } else if (!/\.(csv)$/.test(files[0].name)) {
        handleSetError(error.invalidFileExtension);
        return;
      }

      const reader = new FileReader();
      reader.readAsText(files[0]);

      reader.addEventListener('loadend', () => {
        const { result } = reader;
        const filename = files[0].name;
        if (files[0].name.endsWith('.csv')) {
          handleSetFile({ file: result, mode, filename });
        } else if (appMode === APP_MODES.OCR || appMode === APP_MODES.OCR_AWS || appMode === APP_MODES.OCR_AWS_EDIT) {
          handleSetLoader({ isLoading: true });
          if (appMode === APP_MODES.OCR) {
            Tesseract.recognize(
              files[0],
              'eng',
              { logger: m => console.log('Processing...', m) },
            ).then(({ data }) => {
              handleSetLoader({ isLoading: false });
              setOCRResult(data);
            })
          } else {

            console.log("UPLOADED IMAGGE", uploadedImage, filename)

            if (uploadedImage && uploadedImage.filename === filename) {
              if (uploadedImage.isTable) {
                handleSetFile({ file: uploadedImage.data, mode, filename });
                handleSetLoader({ isLoading: false });
              } else {
                setOCRResult({ words: uploadedImage.data });
                setIsOCRVisible(true);
                handleSetLoader({ isLoading: false });
              }
              return;
            }

            const creds = {
              accessKeyId: config.ocr.short,
              secretAccessKey: config.ocr.long,
            };
            const client = new TextractClient({
              region: 'us-east-1',
              credentials: creds,
            });
            files[0].arrayBuffer().then(bufferArr => {
              const params = {
                Document: {
                  Bytes: Buffer.from(bufferArr, 'base64'),
                },
                FeatureTypes: ['TABLES','FORMS'],
              };
              const command = new AnalyzeDocumentCommand(params);
              client.send(command).then(
                (data) => {

                  const tableData = getTableDataFromOCR(data);
                  if (!isEmpty(tableData) && size(tableData) === 1) {
                    const csvData = getCSVFromTable(tableData[0]);
                    if (!isEmpty(csvData)) {
                      handleSetFile({ file: csvData, mode, filename });
                      handleSetUploadedImage({ filename, file: result, isTable: true, data: csvData })
                      handleSetLoader({ isLoading: false });
                      return;
                    }
                  }

                  const wordsArr = data && data.Blocks && data.Blocks.filter(block => block.BlockType === 'WORD');
                  const words = wordsArr.map(w => ({ text: w.Text }));
                  setOCRResult({ words });
                  handleSetUploadedImage({ filename, file: result, isTable: false, data: words })
                  setIsOCRVisible(true);
                  handleSetLoader({ isLoading: false });
                },
                (e) => {
                  console.log('Error while AWS OCR', e);
                  handleSetLoader({ isLoading: false });
                  handleSetError('It seems that your handwriting is very refined or maybe you are a doctor');
                },
              );
            })
          }
        }
        browseEl.current.value = '';
      });
    },
    [handleSetError, error, handleSetFile, handleSetUploadedImage, uploadedImage],
  );

  const onChangeHandler = useCallback(
    e => {
      if (size(e.target.files)) {
        handleDrop(e.target.files, 'upload');
      }
    },
    [handleDrop],
  );

  const onHandleDrop = useCallback(
    file => {
      handleDrop(file, 'drop');
    },
    [handleDrop],
  );

  const onCloseOCRPanel =  useCallback(order => {
    console.log("ORDER", order);
    setOCRResult();
    setIsOCRVisible(false);
  }, [setOCRResult]);

  const onDragEnter = () => setIsDragging(true);
  const onDragLeave = () => setIsDragging(false);
  const hasOrders = orders && orders.length > 0;

  return (
    <FileDrop
      id="file-orders"
      onDrop={onHandleDrop}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <StyleFileUpload
        ref={browseEl}
        id="input-file-upload"
        type="file"
        onChange={onChangeHandler}
      />
      <StyledUploader
        id="react-file-drop-demo"
        isDragging={isDragging}
        hasOrders={hasOrders}
        onClick={onAreaClick}
      >
        {!isMobileDevice() && !hasOrders && <StyledDropText>{dropFileDescription}</StyledDropText>}
        {!isMobileDevice() && hasOrders && <StyledDropText>{addMore}</StyledDropText>}
        {!isMobileDevice() && <StyledDropTextLight>{orTrans}</StyledDropTextLight>}
        <StyleFileUploadLabel>{browseFiles}</StyleFileUploadLabel>
        <StyledIcon icon={StyledIconDownloadStyle} />
      </StyledUploader>
      {isOCRVisible && <OrderOCRPanel OCRResult={OCRResult} onClose={onCloseOCRPanel} oAuth={oAuth} />}
    </FileDrop>
  );
};

export default withTranslation(FileUploadArea);
