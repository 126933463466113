import FileSaver from 'file-saver';
import moment from 'moment';
import { isEmpty, keys, uniqBy } from 'lodash';
import { isSameLocation } from './helpers';
import {
  getLabelsFromOrders,
  getOrdersAddress,
  getOrdersByLocation,
  getOrdersByStop,
} from './OrdersHelpers';
import config from '../config';

export const getGenericFilename = (user, prefix, base, ext) => {
  const original = user && user.lastImport && user.lastImport.filename;
  const startContent = (original || base).replace('.csv', '');
  const start = !isEmpty(startContent) ? ` ${startContent}` : '';
  const suffix = original ? '' : ` ${moment().format('YYYY-MM-DD')}`;
  return `${prefix}${start}${suffix}.${ext}`;
};

export const getFileName = (user, index, base, ext) => {
  const tour = index > 0 ? index : 'Plan';
  const prefix = `Tour ${tour} -`;
  return getGenericFilename(user, prefix, base, ext);
};

export const getPDFFileName = (user, index) => {
  return getFileName(user, index, 'WeGo Deliver', 'pdf');
};

export const getFileDelimiter = user => {
  const original = user && user.lastImport && user.lastImport.delimiter;
  return original || ',';
};

function getStopPhone(stop, orders) {
  const stopOrders = getOrdersByStop(orders, stop);
  if (!stopOrders || isEmpty(stopOrders)) return null;
  if (stopOrders.length === 1) return stopOrders[0].Phone;
  const withPhone = uniqBy(stopOrders, o => o.Phone).filter(o => o.Phone);
  return withPhone && withPhone.length === 1 && withPhone[0].Phone;
}

export function saveToJsonFile(solution, name) {
  const blob = new Blob([JSON.stringify(solution)], { type: 'application/json' });
  return FileSaver.saveAs(blob, `${name}`);
}

function makeNavLink(endLocation, startLocation, language, stop, orders, tourPlanner) {
  if (!startLocation || !endLocation) return null;

  const baseUrl = config.share.url;
  const params = { m: 'd', lang: language };
  const phone = getStopPhone(stop, orders);
  if (phone && !isEmpty(phone)) params.tel = phone;
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) params.company = tourPlanner.companyName;
  const mappedParams = keys(params).map(k => `${k}=${encodeURIComponent(params[`${k}`])}`);
  return startLocation
    ? `${baseUrl}/${startLocation}/${endLocation}?${mappedParams.join('&')}`
    : null;
}

export function makeDesktopNavLink(stop, prevStop, language, orders, tourPlanner) {
  const stopLoc = `${stop.location.lat},${stop.location.lng}`;
  const prevStopLoc = prevStop ? `${prevStop.location.lat},${prevStop.location.lng}` : null;
  return makeNavLink(stopLoc, prevStopLoc, language, stop, orders, tourPlanner);
}

export function makeShareNavLink(stop, language, orders, tourPlanner) {
  const stopLoc = `${stop.location.lat},${stop.location.lng}`;
  return makeNavLink(stopLoc, 'mylocation', language, stop, orders, tourPlanner);
}

export function createTourShareText(tour, request, translations, tourPlanner, user) {
  if (!tour || !request) return null;

  const stopsText = tour.stops.map((stop, index) => {
    const isDepot = index === 0;
    const isReturnLocation =
      index === tour.stops.length - 1 && tourPlanner && tourPlanner.returnLocation.value !== null;
    let tourPlannerLabel = null;
    if (isDepot) {
      tourPlannerLabel = tourPlanner && tourPlanner.value && tourPlanner.location.label;
      if (!tourPlannerLabel) {
        const ordersForDepot = getOrdersByLocation(request.orders, stop.location);
        tourPlannerLabel = !isEmpty(ordersForDepot) ? ordersForDepot[0].Address : null;
      }
    }
    if (isReturnLocation) {
      tourPlannerLabel = isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
        ? translations.arrivalTrans
        : tourPlanner.returnLocation.label;
    }
    const orders = getOrdersByStop(request.orders, stop);
    const labels = getLabelsFromOrders(orders, true, translations);
    const address = getOrdersAddress(orders);
    const defaultVal = isDepot ? null : `${translations.stopTrans} ${index}`;
    const defaultLabel =
      defaultVal || (isDepot ? translations.depotMarkerTrans : translations.arrivalTrans);
    const label = address || tourPlannerLabel || defaultLabel;
    const details = (labels && labels.join('\n')) || '';
    const shareLink =
      !isDepot && makeShareNavLink(stop, user.language, request.orders, tourPlanner);
    const time = moment(stop.time.arrival).format('LT');
    const order = !isDepot && !isReturnLocation ? `${index}.  ` : '';
    const header = `${order}${time}`;
    const elements = [header, label, shareLink, details].filter(e => !!e);
    return elements.join('\n');
  });

  const titleArr = [translations.toursDetailsTitle];
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) titleArr.unshift(tourPlanner.companyName);
  const title = titleArr.join(' - ');

  return `${title}\n\n${stopsText.join('\n')}`;
}

export function createTourShareData(tour, request, translations, tourPlanner, user) {
  const maxLinkLength = 1800;
  const titleArr = ['HERE WeGo Deliver'];
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) titleArr.unshift(tourPlanner.companyName);
  const title = titleArr.join(' & ');

  const text = createTourShareText(tour, request, translations, tourPlanner, user);
  let link = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(text)}`;
  if (link.length > maxLinkLength) {
    link = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
      translations.pasteTextHint,
    )}`;
  }
  return {
    link,
    text,
  };
}

const orderHeadersToExport = [
  'tour',
  'stop',
  'id',
  'name',
  'address',
  'phone',
  'email',
  'notes',
  'demand',
  'eta',
  'latitude',
  'longitude',
  'shareLink',
];

function getTourRowsToExport(tour, request, tourPlanner, user, tourIndex) {
  if (!tour || !request) return null;

  const toExport = [];
  tour.stops.forEach((stop, index) => {
    const isArrival =
      index === tour.stops.length - 1 && tourPlanner && tourPlanner.returnLocation.value !== null;
    if (index === 0 || isArrival) return;

    const orders = getOrdersByStop(request.orders, stop);
    const shareLink = makeShareNavLink(stop, user.language, request.orders, tourPlanner);
    const eta = moment(stop.time.arrival).format('LT');
    orders.forEach(order => {
      const orderToExport = {
        tour: tourIndex,
        stop: index,
        id: order.ID,
        name: order.Name,
        address: order.Address,
        phone: order.Phone,
        email: order.Email,
        notes: order.Notes,
        demand: order.Demand,
        eta,
        latitude: order.Latitude,
        longitude: order.Longitude,
        shareLink,
      };
      toExport.push(orderToExport);
    });
  });
  return toExport;
}

function getToursToExport(tours, request, tourPlanner, user, initialTourIndex) {
  let toExport = [];
  tours.forEach((tour, index) => {
    const tourRows = getTourRowsToExport(
      tour,
      request,
      tourPlanner,
      user,
      index + initialTourIndex,
    );
    if (tourRows) toExport = toExport.concat(tourRows);
  });
  return toExport;
}

export const prepareValueToExport = value => {
  if (!value) return '';
  return `"${value
    .toString()
    .replace(/(?:\r\n|\r|\n)/g, '. ')
    .replace(/"/g, "'")}"`;
};

export function exportTourToCSV(tour, request, tourPlanner, user, tourIndex) {
  const toExport = getTourRowsToExport(tour, request, tourPlanner, user, tourIndex);
  if (!toExport) return null;

  const headers = orderHeadersToExport;
  const delimiter = getFileDelimiter(user);
  const rows = toExport.map(order => {
    const values = headers.map(h => prepareValueToExport(order[`${h}`]));
    return `${values.join(delimiter)}\n`;
  });

  const csv = [`${headers.join(delimiter)}\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = getFileName(user, tourIndex, 'WeGo Deliver', 'csv');
  return FileSaver.saveAs(blob, fileName);
}

export function exportToursToCSV(tours, request, tourPlanner, user, initialTourIndex) {
  const toExport = getToursToExport(tours, request, tourPlanner, user, initialTourIndex);
  if (!toExport) return null;

  const headers = orderHeadersToExport;
  const delimiter = getFileDelimiter(user);
  const rows = toExport.map(order => {
    const values = headers.map(h => prepareValueToExport(order[`${h}`]));
    return `${values.join(delimiter)}\n`;
  });

  const csv = [`${headers.join(delimiter)}\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = getFileName(user, -1, 'WeGo Deliver', 'csv');
  return FileSaver.saveAs(blob, fileName);
}
