import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { getAsset } from 'utils/helpers';
import { colors } from 'global/variables';
import download from 'global/img/download.svg';
import { saveToJsonFile } from '../../../utils/SolutionExport';
import { exportToDriverApp } from '../../../utils/SolutionExportToApp';
import { exportToWeGo } from '../../../utils/SolutionExportToWeGo';

const { primaryColor, secondaryColor } = colors;

const StyledDownloadButton = styled.div`
  position: absolute;
  right: ${props => `${(parseInt(props.index, 10) - 1) * 5 + 1}rem`};
  bottom: 5rem;
  cursor: pointer;
  box-shadow: 0px 10px 29px 0px rgba(0, 0, 0, 0.22);
  z-index: 500;
  height: 3.5rem;
  width: 3.5rem;
  line-height: 3.5rem;
  padding: 0.5rem;
  vertical-align: middle;
  border-radius: 2rem;
  text-align: center;
  box-sizing: border-box;
  transition: 0.5s ease;
  background-image: linear-gradient(
    to right,
    ${secondaryColor} 0%,
    ${primaryColor} 51%,
    ${secondaryColor} 100%
  );
  background-size: 200% auto;

  span {
    display: inline-block;
    background-image: url(${getAsset(download)});
    background-size: 1.1rem 1.6rem;
    background-repeat: no-repeat;
    width: 1.1rem;
    height: 1.6rem;
  }

  &:hover {
    background-position: right center;
  }
`;

const DownloadSolution = ({ solution, tourID, index = 1, mode }) => {
  const onDownloadClick = useCallback(() => {
    const iteration = solution && solution.iterations[solution.iterations.length - 1];
    let toDownload;
    let fileName;
    if (tourID === undefined) {
      toDownload = iteration.response ? iteration.response : iteration.request.json;
      fileName = iteration.response ? 'solution.json' : 'problem.json';
    } else {
      toDownload =
        mode === 'app' ? exportToDriverApp(solution, tourID) : exportToWeGo(solution, tourID);
      fileName = `plan-${tourID}.wegodeliver`;
    }
    if (toDownload) saveToJsonFile(toDownload, fileName);
  }, [solution, tourID, mode]);

  const label = mode || 'api';

  return (
    <StyledDownloadButton onClick={onDownloadClick} index={index}>
      <span>{label}</span>
    </StyledDownloadButton>
  );
};

export default DownloadSolution;
