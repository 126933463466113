import moment from 'moment';
import config from 'config';
import { size, isEmpty, minBy, maxBy } from 'lodash';
import { CSV_META } from './csv/config';
import { FLEET_MODES } from '../actions';

const {
  scenario: {
    vehicle,
    times: [{ value }],
  },
  shortTimeFormat,
  maxValues: { defaultVehicleCapacity },
} = config;

const defaultVehiclesAssigned = jobs => {
  return Math.max(Math.ceil(size(jobs) / defaultVehicleCapacity), 1);
};

const getTimeWindowsRange = orders => {
  if (isEmpty(orders)) return null;

  const withTW = orders.filter(j => j.StartTime && j.EndTime);
  return isEmpty(withTW)
    ? null
    : {
        start: minBy(withTW, j => j.StartTime).StartTime,
        end: maxBy(withTW, j => j.EndTime).EndTime,
      };
};

export function getVRPTimes(tourPlanner, orders) {
  const defaultTimes = {
    start: moment(value.start, shortTimeFormat),
    end: moment(23, shortTimeFormat),
  };

  if (!tourPlanner) return defaultTimes;

  if (tourPlanner.fleetMode === FLEET_MODES.CUSTOM) {
    const end = tourPlanner.time.value.end !== 24 ? tourPlanner.time.value.end : '23:55';
    return {
      start: moment(tourPlanner.time.value.start, shortTimeFormat),
      end: moment(end, shortTimeFormat),
    };
  }

  const start = moment();
  const end = moment(23, shortTimeFormat);
  const tw = getTimeWindowsRange(orders);
  if (tw) {
    const knownDateFormats = [CSV_META.timeFormat];
    const twStart = moment(tw.start, knownDateFormats);
    if (twStart < start) return { start: twStart, end };
  }

  if (size(orders) > 100) return defaultTimes;

  const difHours = (end - start) / 60000 / 60;
  const vehicles = defaultVehiclesAssigned(orders);
  const maxStopsPerTour = vehicles > 1 ? defaultVehicleCapacity : size(orders);
  const aproxHoursReq = maxStopsPerTour / 5;
  if (difHours < aproxHoursReq) return defaultTimes;

  return { start: start.add(5, 'minutes'), end };
}

export function getVRPVehicleDefinition(tourPlanner) {
  const def = { ...vehicle };
  if (!tourPlanner) return def;

  if (tourPlanner.fleetMode === FLEET_MODES.CUSTOM) {
    def.amount = tourPlanner.vehicle.amount;
    def.capacity = tourPlanner.vehicle.capacity;
  } else {
    def.amount = 100;
    def.capacity = [100];
  }

  return def;
}
