import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import HereLogo from '../../Presentation/HereLogo';
import close from '../../../global/img/close.svg';
import LanguageSelector from './LanguageSelector';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import video from '../../../global/img/video.svg';
import globe from '../../../global/img/globe.svg';
import lock from '../../../global/img/shake-hands.svg';
import mail from '../../../global/img/contact.svg';
import MenuItemHeader from './MenuItemHeader';
import { colors } from '../../../global/variables';
import { setUserCookies, setUserParam } from '../../../actions';

const StyledMenu = styled.div(({ isOpen }) => ({
  position: 'fixed',
  top: '0',
  bottom: '0',
  backgroundColor: 'rgb(50, 58, 70)',
  color: colors.secondaryTextColorDarkBg,
  width: '20rem',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10103,
  transitionProperty: 'transform',
  transitionDuration: '0.5s',
  transitionTimingFunction: 'cubic-bezier(0.635, 0.000, 0.000, 1.000)',
  '@media screen and (max-width: 850px)': {
    width: '80%',
    left: '0',
    transform: isOpen ? '0' : 'translateX(-100%)',
  },
  '@media screen and (min-width: 851px)': {
    right: '0',
    transform: isOpen ? '0' : 'translateX(100%)',
  },
}));

const StyledMenuBackdrop = styled.div(({ isOpen }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: isOpen ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0)',
  backdropFilter: `blur(${isOpen ? '4px' : '0'})`,
  zIndex: 10100,
  visibility: isOpen ? 'visible' : 'hidden',
  transitionProperty: 'background-color backdrop-filter',
  transitionDuration: '0.5s',
  transitionTimingFunction: 'cubic-bezier(0.635, 0.000, 0.000, 1.000)',
}));

const StyledHeader = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexShrink: 0,
  height: '3rem',
  padding: '0 1rem',
  marginBottom: '1rem',
  '@media screen and (max-width: 850px)': {
    justifyContent: 'space-between',
  },
});

const StyledBody = styled.div({
  overflowY: 'auto',
  height: '100%',
});

const StyledCloseButton = styled.button({
  backgroundColor: 'transparent',
  backgroundImage: `url(${close})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 0.8,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },
});

const StyledVideoIcon = styled.div({
  backgroundImage: `url(${video})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
  position: 'relative',
  // Compensate visual center of mass shift
  top: '-0.1rem',
});

const StyledLanguageIcon = styled.div({
  backgroundImage: `url(${globe})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
});

const StyledLockIcon = styled.div({
  backgroundImage: `url(${lock})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
  position: 'relative',
  // Compensate visual center of mass shift
  top: '-0.1rem',
});

const StyledMailIcon = styled.div({
  backgroundImage: `url(${mail})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
});

const StyledMenuItem = styled.div({
  padding: '1.2rem',

  '& + &': {
    borderTop: '1px solid rgba(0,0,0,0.2)',
  },

  a: {
    textDecoration: 'none',
  },
});

const StyledSelectLabel = styled.div({
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  marginBottom: '0.3rem',
});

const StyledCookiesBody = styled.div({
  marginBottom: '1rem',
  lineHeight: '1.2rem',
  fontSize: '.9rem',
});

const StyledLink = styled.a({
  color: '#01b6b2',
  textDecorationLine: 'none',
  marginBottom: '1.2rem',
  display: 'inline-block',
  ':hover': {
    color: '#00a39f',
    textDecorationLine: 'underline',
  },
});

const StyledAdaptableContainer = styled.div({
  display: 'none',
  '@media screen and (max-width: 850px)': {
    display: 'block',
  },
});

const StyledSwitchContainer = styled.div({
  marginTop: '1rem',
});

const Menu = ({ user, translations, isOpen, onClickClose, linkLang }) => {
  const dispatch = useDispatch();
  const tourPlanner = useSelector(({ tourPlanner: stateTourPlanner }) => stateTourPlanner);
  const handleSetUserCoookies = useCallback(data => dispatch(setUserCookies(data)), [dispatch]);
  const handleSetUserParam = useCallback(data => dispatch(setUserParam(data)), [dispatch]);

  const onClickHelp = () => {
    AmplitudeService.log(AMPLITUDE_EVENTS.CLICK_LINK, { link: 'Help menu' });
  };

  const setCookieParam = useCallback(
    value => {
      AmplitudeService.setOptIn(value);
      handleSetUserCoookies(value);
      if (value) {
        AmplitudeService.setFleetConfiguration(tourPlanner);
        AmplitudeService.setUserParams(user);
      }
    },
    [handleSetUserCoookies, tourPlanner, user],
  );

  const handleSwitch = useCallback(
    e => {
      const approved = e.target.checked;
      setCookieParam(approved);
    },
    [setCookieParam],
  );

  const handleSwitchStateMemory = useCallback(
    e => {
      const hasStateMemory = e.target.checked;
      handleSetUserParam({ hasStateMemory });
    },
    [handleSetUserParam],
  );

  return (
    <>
      <StyledAdaptableContainer>
        <StyledMenuBackdrop isOpen={isOpen} />
      </StyledAdaptableContainer>
      <StyledMenu isOpen={isOpen}>
        <StyledHeader>
          <StyledAdaptableContainer>
            <HereLogo backgroundTheme="dark" />
          </StyledAdaptableContainer>
          <StyledCloseButton onClick={onClickClose} />
        </StyledHeader>

        <StyledBody>
          <StyledMenuItem>
            <a
              href="https://www.youtube.com/watch?v=I-1cSeZ7A8w"
              rel="noopener noreferrer"
              target="_blank"
            >
              <MenuItemHeader
                icon={<StyledVideoIcon />}
                title={translations.menu.videoTitle}
                description={translations.menu.videoDescription}
              />
            </a>
          </StyledMenuItem>

          <StyledMenuItem>
            <MenuItemHeader icon={<StyledLanguageIcon />} title={translations.menu.language} />
            <StyledSelectLabel>{translations.menu.selectLabel}</StyledSelectLabel>
            <LanguageSelector user={user} />
          </StyledMenuItem>

          <StyledMenuItem>
            <MenuItemHeader icon={<StyledLockIcon />} title={translations.cookieNotice.header} />
            <StyledCookiesBody>{translations.cookieNotice.body}</StyledCookiesBody>
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href={`https://legal.here.com/${linkLang}/privacy/cookies`}
            >
              {translations.cookieNotice.moreInfo}
            </StyledLink>
            <div>
              <input
                id="input-cookies-enable"
                type="checkbox"
                checked={user.cookies.accept}
                onChange={handleSwitch}
              />{' '}
              <label className="custom-switcher" htmlFor="input-cookies-enable">
                T
              </label>
              <span className="label-custom-switcher inverted big">
                {user.cookies.accept
                  ? translations.cookieNotice.enable
                  : translations.cookieNotice.disable}
              </span>
            </div>
            {!isEmpty(translations.menu.rememberState) && user.cookies.accept && (
              <StyledSwitchContainer>
                <input
                  id="input-remember-state"
                  type="checkbox"
                  checked={user.hasStateMemory}
                  onChange={handleSwitchStateMemory}
                />{' '}
                <label className="custom-switcher" htmlFor="input-remember-state">
                  T
                </label>
                <span className="label-custom-switcher inverted big">
                  {translations.menu.rememberState}
                </span>
              </StyledSwitchContainer>
            )}
          </StyledMenuItem>

          <StyledMenuItem>
            <a href="mailto:wegodeliver@here.com" onClick={onClickHelp}>
              <MenuItemHeader icon={<StyledMailIcon />} title={translations.contact.linkTitle} />
            </a>
          </StyledMenuItem>
        </StyledBody>
      </StyledMenu>
    </>
  );
};

export default withTranslation(Menu);
