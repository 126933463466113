import React from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from '../../../global/variables';

const StyledMenuItemHeader = styled.div({
  display: 'flex',
  alignItems: 'center',

  '& + *': {
    marginTop: '0.7rem',
  },
});

const StyledMenuItemText = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '0.8rem',
});

const StyledMenuItemTitle = styled.div({
  color: colors.white,
  fontWeight: 500,
});

const StyledMenuItemDescription = styled.div({
  color: colors.secondaryTextColorDarkBg,
  fontSize: fontSize.small,
  marginTop: '0.2rem',
});

const MenuItemHeader = ({ icon, title, description }) => {
  const descriptionElement =
    description !== undefined ? (
      <StyledMenuItemDescription>{description}</StyledMenuItemDescription>
    ) : null;

  return (
    <StyledMenuItemHeader>
      {icon}
      <StyledMenuItemText>
        <StyledMenuItemTitle>{title}</StyledMenuItemTitle>
        {descriptionElement}
      </StyledMenuItemText>
    </StyledMenuItemHeader>
  );
};

export default MenuItemHeader;
