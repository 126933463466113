import React from "react";
import styled from '@emotion/styled';
import withTranslation from "../../../hoc/withTranslation";
import { StyledDoublePanel } from "../../Wizard/Global/WizardStyled";
import Button from "../../Form/Button";

const StyledFooterContainer = styled.div({
  textAlign: 'center',

  '@media screen and (max-width: 850px)': {
    margin: '0 -0.5rem',
  },
})

const StyledButtonsContainer = styled.div({
  maxWidth: '40rem',
  margin: '0 auto 1rem auto',
})

const SurveyFooter = ({ onNext, onBack, translations, showPrevious, isNextEnabled }) => {
  const { survey: { surveyLabels } } = translations

  return (
    <StyledFooterContainer>
      <StyledButtonsContainer>
        <StyledDoublePanel marginTop extremes>
          <div>
            {showPrevious && <Button text={surveyLabels.buttonBack} inverted onClick={() => onBack()} />}
          </div>
          <div>
            <Button text={surveyLabels.buttonNext} onClick={onNext} disabled={!isNextEnabled} />
          </div>
        </StyledDoublePanel>
      </StyledButtonsContainer>
    </StyledFooterContainer>
  );
};

export default withTranslation(SurveyFooter);
