import React, { useCallback, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledDoublePanel,
  StyledIcon,
  StyledSelectBigInput,
  StyledText,
  StyledTextArea,
} from '../../Wizard/Global/WizardStyled';
import Label from '../../Form/Label';
import moodIconBad from '../../../global/img/mood-bad.png';
import moodIconSad from '../../../global/img/mood-sad.png';
import moodIconMeh from '../../../global/img/mood-meh.png';
import moodIconHappy from '../../../global/img/mood-happy.png';
import moodIconLove from '../../../global/img/mood-love.png';
import Button from '../../Form/Button';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { functionalities, getInitialContext } from '../../../utils/FeedbackHelpers';
import { recordUsageEvent, USAGE_EVENTS } from '../../../actions';

const { white, aqua, primaryTextColor, secondaryTextColor } = colors;
const { medium, small } = fontSize;

const StyledFeedbackPanel = styled.div({
  top: '10%',
  width: '100%',
  padding: '2rem 1rem 1rem 1rem',
  borderRadius: '0.25rem 0.25rem 2rem 2rem',
  backdropFilter: 'blur(10px)',
  backgroundColor: white,
  boxSizing: 'border-box',
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
});

const StyledMoodContainer = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  marginTop: '1.4rem',
});

const StyledMoodItem = styled.div(({ isSelected }) => ({
  textAlign: 'center',
  border: isSelected ? `solid 2px ${aqua}` : 'solid 2px transparent',
  borderRadius: '0.4rem',
  padding: '0.4rem',
  cursor: 'pointer',
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
}));

const StyledMoodLabel = styled.div(({ isSelected }) => ({
  color: isSelected ? secondaryTextColor : primaryTextColor,
  fontWeight: isSelected ? 700 : 500,
  marginTop: '0.4rem',
  fontSize: small,
  lineHeight: '0.8rem',
}));

const StyledQuestionContainer = styled.div({
  textAlign: 'left',
  marginTop: '1rem',
  width: '100%',
  a: {
    fontSize: small,
    color: primaryTextColor,
    textdecoration: 'underline',
    ':hover': {
      transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
      color: secondaryTextColor,
    },
  },
});

const MOODS = [
  { type: 'Bad', icon: moodIconBad, rating: 1 },
  { type: 'Sad', icon: moodIconSad, rating: 2 },
  { type: 'Meh', icon: moodIconMeh, rating: 3 },
  { type: 'Happy', icon: moodIconHappy, rating: 4 },
  { type: 'Love', icon: moodIconLove, rating: 5 },
];

const FeedbackPanel = ({ usageContext, onDone, translations: { feedback }, linkLang }) => {
  const dispatch = useDispatch();
  const handleRecordUsage = useCallback(data => dispatch(recordUsageEvent(data)), [dispatch]);
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState('');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const initialFn = getInitialContext(usageContext);
  const [functionality, setFunctionality] = useState(initialFn);

  useEffect(() => {
    const isEnabled = !isEmpty(reason) || rating > 0;
    setIsSubmitEnabled(isEnabled);
  }, [rating, reason, setIsSubmitEnabled]);

  const onMoodClick = useCallback(
    r => {
      const info = { action: 'setRating', rating: r };
      AmplitudeService.log(AMPLITUDE_EVENTS.FEEDBACK_ACTION, info);
      setRating(r);
    },
    [setRating],
  );

  const onFunctionalityChange = useCallback(
    e => {
      const info = { action: 'setFunctionality', functionality: e.target.value };
      AmplitudeService.log(AMPLITUDE_EVENTS.FEEDBACK_ACTION, info);
      setFunctionality(e.target.value);
    },
    [setFunctionality],
  );

  const onReasonBlur = useCallback(e => {
    const info = { action: 'setReason', reason: e.target.value };
    AmplitudeService.log(AMPLITUDE_EVENTS.FEEDBACK_ACTION, info);
  }, []);

  const onReasonChange = useCallback(
    e => {
      setReason(e.target.value);
    },
    [setReason],
  );

  const onCancel = useCallback(() => {
    const info = { action: 'cancel' };
    AmplitudeService.log(AMPLITUDE_EVENTS.FEEDBACK_ACTION, info);
    onDone();
  }, [onDone]);

  const onSubmit = useCallback(() => {
    const info = { action: 'submit', functionality, rating, reason };
    AmplitudeService.log(AMPLITUDE_EVENTS.FEEDBACK_ACTION, info, true);
    handleRecordUsage({ event: USAGE_EVENTS.FEEDBACK_ADD, functionality });
    onDone();
  }, [onDone, functionality, rating, reason, handleRecordUsage]);

  return (
    <StyledFeedbackPanel>
      <StyledText bold big>
        {feedback.mainQuestion}
      </StyledText>
      <StyledQuestionContainer>
        <Label>{feedback.functionalityTitle}</Label>
        <StyledSelectBigInput
          fullWidth
          onChange={onFunctionalityChange}
          value={functionality}
          fSize={medium}
          bold
        >
          {functionalities.map(fn => (
            <option key={fn} value={fn}>
              {feedback[`functionality${fn}`]}
            </option>
          ))}
        </StyledSelectBigInput>
      </StyledQuestionContainer>
      <StyledMoodContainer>
        {MOODS.map(m => (
          <StyledMoodItem
            key={m.type}
            isSelected={rating === m.rating}
            onClick={() => onMoodClick(m.rating)}
          >
            <StyledIcon iconUrl={m.icon} huge />
            <StyledMoodLabel isSelected={rating === m.rating}>
              {feedback[`mood${m.type}`]}
            </StyledMoodLabel>
          </StyledMoodItem>
        ))}
      </StyledMoodContainer>
      <StyledQuestionContainer>
        <StyledTextArea
          placeholder={feedback.textQuestionPlaceholder}
          onChange={onReasonChange}
          onBlur={onReasonBlur}
        />
        <a
          href={`https://legal.here.com/${linkLang}/privacy/policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {feedback.privacyLabel}
        </a>
      </StyledQuestionContainer>
      <StyledDoublePanel marginTop extremes>
        <Button text={feedback.buttonCancelLabel} inverted onClick={onCancel} />
        <div>
          <Button
            text={feedback.buttonSubmitLabel}
            onClick={onSubmit}
            disabled={!isSubmitEnabled}
          />
        </div>
      </StyledDoublePanel>
    </StyledFeedbackPanel>
  );
};

export default withTranslation(FeedbackPanel);
