import { includes, isEmpty } from 'lodash';

export const PARAM_LANGUAGE = 'l';
export const PARAM_MODE = 'm';
export const PARAM_TEST = 'test';
export const PARAM_USER_SOURCE = 'cid';
export const PARAM_TOUR_INDEX = 'tourIndex';

export const APP_MODES = {
  NORMAL: 'n',
  DEVELOPER: 'dev',
  WIP: 'wip',
  OCR: 'ocr',
  OCR_AWS: 'ocr-aws',
  OCR_AWS_EDIT: 'ocr-aws-edit',
  OCR_AWS_ALL_FIELDS: 'ocr-aws-all-fields',
  SURVEY: 'survey',
};

export function getQueryParams(url) {
  const urlParts = url ? url.split('?') : null;
  const fullSearch = urlParts ? urlParts[1] : window.location.search.substr(1);
  const search = fullSearch ? fullSearch.split('#')[0] : null;
  if (isEmpty(search)) return {};

  const params = search.split('&');
  const pairs = params.map(v => v.split('='));
  const values = {};
  pairs.forEach(v => {
    const value = decodeURIComponent(v[1]);
    values[v[0]] = includes(value, ',') ? value.split(',') : value;
  });
  return values;
}

export function setUrlParam(url = window.location.origin, paramName, value) {
  if (!value) return url;
  const urlParts = url ? url.split('?') : null;
  const toAdd = urlParts[1] ? '&' : '?';
  return `${url}${toAdd}${paramName}=${encodeURIComponent(value)}`;
}

export function getUrlParam(url = window.location.href, paramName, defaultValue) {
  const params = getQueryParams(url);
  return params[`${paramName}`] || defaultValue;
}

export function getUrlLanguage(url) {
  return getUrlParam(url, PARAM_LANGUAGE);
}

export function getUrlMode(url) {
  return getUrlParam(url, PARAM_MODE, APP_MODES.NORMAL);
}

export function getAppMode(url) {
  const key = Object.keys(APP_MODES).find(k => APP_MODES[`${k}`] === getUrlMode(url));
  return APP_MODES[`${key}`] || APP_MODES.NORMAL;
}

export function getUrlTest(url) {
  return getUrlParam(url, PARAM_TEST);
}

export function getUrlUserSource(url) {
  return getUrlParam(url, PARAM_USER_SOURCE);
}

export function getUrlInitialTourIndex(url) {
  const value = getUrlParam(url, PARAM_TOUR_INDEX, 1);
  return parseInt(value, 10);
}
