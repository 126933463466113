import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { keys, assign, size } from 'lodash';
import { setUploadedFile, setTourParameter, parseAndProcessCSVFile } from 'actions';
import withTranslation from 'hoc/withTranslation';
import { StyledInfoEllipsis, StyledSelectInput, StyledText } from '../Global/WizardStyled';
import Button from '../../Form/Button';
import {
  getInitialFieldsConfig,
  isAddressConfigured,
} from '../../../utils/csv/ManualConfigHelpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import {
  StyledContrastPanel,
  StyledContrastPanelColumn,
  StyledContrastPanelColumnsContainer,
  StyledContrastPanelContainer,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
  StyledContrastPanelHeaderText,
  StyledContrastPanelTableHeader,
  StyledCategory,
} from '../Global/WizardStyledContrastPanel';
import OrdersCSVConfigConfirm from './OrdersCSVConfigConfirm';
import { getCSVHeaders } from '../../../utils/csv/parser';

const notShowCategories = ['location', 'careOf'];

const OrdersCSVConfig = ({
  uploadedFile,
  tourPlanner,
  orders,
  onClose,
  oAuth,
  user,
  translations: {
    csv,
    error,
    wizard: {
      orders: { csvConfigTrans },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleSetFile = useCallback(data => dispatch(setUploadedFile(data)), [dispatch]);
  const handleSetTourParameter = useCallback(parameter => dispatch(setTourParameter(parameter)), [
    dispatch,
  ]);
  const handleProcessCSVFile = useCallback(
    (data, errorTrans, currentOrders, mode) =>
      dispatch(parseAndProcessCSVFile(data, errorTrans, currentOrders, mode)),
    [dispatch],
  );
  const [fieldsConfig, setFieldsConfig] = useState({});
  const [hasAddress, setHasAddress] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const allCategories = keys(csv).map(field => field.replace('Trans', ''));
  const categories = allCategories.filter(cat => !notShowCategories.includes(cat));

  const openPanel = useCallback(
    file => {
      if (!file) {
        setFieldsConfig(tourPlanner.csvConfig);
        return;
      }

      getCSVHeaders(file.file).then(headers => {
        const config = getInitialFieldsConfig(headers, tourPlanner, user, csv);
        setFieldsConfig(config);
        AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, { action: 'open' });
      });
    },
    [setFieldsConfig, tourPlanner, user, csv],
  );

  useEffect(() => openPanel(uploadedFile), [uploadedFile, openPanel]);

  useEffect(() => setHasAddress(isAddressConfigured(fieldsConfig)), [fieldsConfig, setHasAddress]);

  const onCancel = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, { action: 'cancel' });
    handleSetFile();
    onClose();
  }, [handleSetFile, onClose]);

  const onConfirm = useCallback(() => {
    const csvConfig = tourPlanner ? assign({}, tourPlanner.csvConfig, fieldsConfig) : fieldsConfig;
    const demandLabel = keys(fieldsConfig).filter(key => csvConfig[`${key}`] === 'demand');
    handleSetTourParameter({ csvConfig, lastDemandLabel: demandLabel[0] });
    if (uploadedFile) {
      handleProcessCSVFile(uploadedFile.file, error, orders, uploadedFile.mode);
      handleSetFile();
    }
    onClose();
  }, [
    onClose,
    uploadedFile,
    tourPlanner,
    error,
    orders,
    fieldsConfig,
    handleSetTourParameter,
    handleProcessCSVFile,
    handleSetFile,
  ]);

  const onSave = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, {
      action: 'save',
      totalColumns: size(keys(fieldsConfig)),
      configuredColumns: size(keys(fieldsConfig).filter(key => !!fieldsConfig[`${key}`])),
    });
    if (uploadedFile) {
      setIsConfirming(true);
      return;
    }
    onConfirm();
  }, [fieldsConfig, uploadedFile, setIsConfirming, onConfirm]);

  const onChange = useCallback(
    (e, key) => {
      const newConfig = assign({}, fieldsConfig);
      const value = e.target.value;
      newConfig[`${key}`] = value;
      setFieldsConfig(newConfig);
      AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, {
        action: 'map',
        header: key,
        category: value,
      });
    },
    [setFieldsConfig, fieldsConfig],
  );

  const onCloseConfirm = useCallback(() => {
    setIsConfirming(false);
  }, [setIsConfirming]);

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) onCancel();
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  const canSubmit = hasAddress;

  return (
    <StyledContrastPanelContainer>
      {!isConfirming && (
        <StyledContrastPanel>
          <StyledContrastPanelHeader>
            <StyledText bold contrast>
              {csvConfigTrans.configTitle}
            </StyledText>
            <StyledContrastPanelHeaderText paddingBottom>
              {csvConfigTrans.configDesc}
            </StyledContrastPanelHeaderText>
            <StyledContrastPanelHeaderText darker>
              {csvConfigTrans.configMustDesc}
            </StyledContrastPanelHeaderText>
            <StyledContrastPanelHeaderText paddingBottom found={hasAddress} icon>
              {csvConfigTrans.configMustAddress}
            </StyledContrastPanelHeaderText>
            <StyledContrastPanelTableHeader>
              <div>{csvConfigTrans.configFieldsTitleColumn}</div>
              <div>{csvConfigTrans.configFieldsTitleCategory}</div>
            </StyledContrastPanelTableHeader>
          </StyledContrastPanelHeader>
          <StyledContrastPanelColumnsContainer>
            {Object.keys(fieldsConfig).map(key => (
              <StyledContrastPanelColumn key={key}>
                <StyledInfoEllipsis alt={key} title={key}>
                  {key}
                </StyledInfoEllipsis>
                <StyledCategory>
                  <StyledSelectInput
                    contrast
                    unselected={!fieldsConfig[`${key}`]}
                    value={fieldsConfig[`${key}`] || ''}
                    onChange={e => onChange(e, key)}
                  >
                    <option value="">{csvConfigTrans.configUnassignedColumn}</option>
                    {categories.map(category => (
                      <option value={category} key={category}>
                        {csv[`${category}Trans`]}
                      </option>
                    ))}
                  </StyledSelectInput>
                </StyledCategory>
              </StyledContrastPanelColumn>
            ))}
          </StyledContrastPanelColumnsContainer>
          <StyledContrastPanelFooter isDouble>
            <Button inverted contrast text={csvConfigTrans.configButtonCancel} onClick={onCancel} />
            <div />
            <Button id="button-csv-config-save" text={csvConfigTrans.configButtonSave} onClick={onSave} disabled={!canSubmit} />
          </StyledContrastPanelFooter>
        </StyledContrastPanel>
      )}
      {isConfirming && (
        <OrdersCSVConfigConfirm
          uploadedFile={uploadedFile}
          csvConfig={fieldsConfig}
          onConfirm={onConfirm}
          onClose={onCloseConfirm}
          oAuth={oAuth}
          user={user}
          tourPlanner={tourPlanner}
        />
      )}
    </StyledContrastPanelContainer>
  );
};

export default withTranslation(OrdersCSVConfig);
