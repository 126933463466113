import config from 'config';
import { head, map, values } from 'lodash';
import { roundTo } from 'utils/helpers';
import {
  formatPrice,
  formatPricePerDistance,
  formatPricePerTime,
  formatPricePerTour,
} from 'utils/formatter';

const {
  scenario: {
    vehicle: { costs },
  },
} = config;

const configCosts = values(costs);
/*
 * distance - m -> km: 1000m = 1km
 * time - s -> h: 3600s = 1h
 * fix - amount -> 1
 */
const costsConvertValue = [1000, 3600, 1];
const costsConvertFormatter = [formatPricePerDistance, formatPricePerTime, formatPricePerTour];
const calculate = (value = 0, cost = 0) => {
  const result = value * cost;
  return result < 1 ? roundTo(result) : Math.round(result);
};

export const calculateCost = (costValues = []) =>
  map(costValues, (value, index) => calculate(value, configCosts[Number(index)] || head(configCosts)));

export const calculateFormattedCost = (costValues = []) => unitTrans =>
  map(calculateCost(costValues), value => formatPrice(value)(unitTrans));

export const getConfigCost = () =>
  map(configCosts, (cost, index) => calculate(cost, costsConvertValue[Number(index)]));

export const getFormattedConfigCost = () => units =>
  map(
    getConfigCost(),
    (cost, index) =>
      `* ${(costsConvertFormatter[Number(index)] || head(costsConvertFormatter))(cost)(units)}`,
  );
