import moment from 'moment';
import { last, isEmpty } from 'lodash';
import { getOrdersByStop } from './OrdersHelpers';

const getHeader = (tour, tourID, depot) => {
  return {
    id: `Tour-${tourID}`,
    vehicle: {
      id: tour.vehicleId,
      location: { ...depot.location },
      address: depot.address,
      registrationNumber: 'AA 2717',
      model: 'Panda',
    },
    scheduledDate: moment().format('YYYY-MM-DD'),
    departureDate: tour.stops[0].time.departure,
    driverId: 'weGoDriverId',
    status: 'assigned',
  };
};

const getLocationFromOrder = order => {
  return {
    location: {
      lat: order.Latitude,
      lng: order.Longitude,
    },
    address: order.Address,
  };
};

const getDepotInfo = (orders, tourPlanner) => {
  if (tourPlanner.location.label) {
    return {
      location: { ...tourPlanner.location.value },
      address: tourPlanner.location.label,
    };
  }

  return getLocationFromOrder(orders[0]);
};

const getStopHeader = (stop, index, depot, ordersInStop) => {
  const loc = !isEmpty(ordersInStop) ? getLocationFromOrder(ordersInStop[0]) : depot;
  return {
    location: { ...loc.location },
    address: {
      street: loc.address || '',
      city: '',
      careOf: '',
      additional: '',
      countryCode: '',
      zipCode: '',
    },
    schedule: { ...stop.time },
  };
};

const getStopActivities = (stop, index, ordersInStop, header) => {
  if (isEmpty(ordersInStop)) {
    return [
      {
        id: 'departureId',
        type: 'departure',
        status: 'planned',
      },
    ];
  }

  return stop.activities.map((act, i) => {
    const order = ordersInStop[`${i}`];
    const address = order ? order.address : header.location.address;
    return {
      location: { ...header.location },
      address: {
        street: address || '',
        city: '',
        careOf: (order && order['c/o']) || '',
        additional: '',
        countryCode: '',
        zipCode: '',
      },
      schedule: { ...header.schedule },
      id: act.jobId,
      jobId: act.jobId,
      externalId: '',
      type: 'delivery',
      status: 'planned',
      recipient: {
        address: {
          street: address || '',
          city: '',
          careOf: (order && order['c/o']) || '',
          additional: '',
          countryCode: '',
          zipCode: '',
        },
        firstName: (order && order.Name) || '',
        lastName: '',
        businessName: '',
        salutation: '',
        phoneNumber: (order && order.Phone) || '',
      },
      description: (order && order.Notes) || '',
    };
  });
};

const getStops = (tour, depot, orders) => {
  return tour.stops.map((stop, index) => {
    const ordersInStop = getOrdersByStop(orders, stop);
    const header = getStopHeader(stop, index, depot, ordersInStop);
    const activities = getStopActivities(stop, index, ordersInStop, header);
    return { ...header, activities };
  });
};

export function exportToDriverApp(solution, tourID) {
  const sol = last(solution.iterations);
  const tour = sol.response.tours[`${tourID}`];
  const tourPlanner = sol.request.tourPlanner;
  const orders = sol.request.orders;
  const depot = getDepotInfo(orders, tourPlanner);
  const header = getHeader(tour, tourID, depot);
  const stops = getStops(tour, depot, orders);
  return { ...header, stops };
}
