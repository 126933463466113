import React, { useCallback } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import { size } from "lodash";
import { mapDataKey, getBounds } from "../../utils/map/MapCalculations";
import { featureFilter, featureFilterUnselectedTourLine } from "../../utils/map/MapFeatureFilters";
import { onEachFeature } from "../../utils/map/MapTourLineUtils";
import { animateFlyTo, getMapSettings, getStyle, getStyleUnselected } from "../../utils/map/MapUtils";
import { AMPLITUDE_EVENTS, AmplitudeService } from "../../utils/amplitude";
import MapAnimatedTour from "./MapAnimatedTour";

const MapTourLines = ({ mapData, user, display, routingRoutes, routingData, activateTour, tourData }) => {
  const map = useMap();
  const onTourLineClick = useCallback(feature => {
    const routeId = feature.properties.routeId;
    const isSelected = display.routeIds.includes(routeId);
    if (isSelected && feature.geometry.type === 'LineString') {
      const bounds = getBounds(mapData, user, display, routingRoutes);
      map.flyToBounds(bounds, getMapSettings(), animateFlyTo);
      AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ZOOM_IN);
      return;
    }

    if (routeId === undefined) return;
    const tour = tourData.tours[`${routeId}`];
    activateTour(routeId, tour);
    AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ACTIVATE_TOUR);
  }, [display, tourData, mapData, user, routingRoutes]);

  const filterLineFeatures = useCallback(feature => {
    return featureFilter(feature, display, routingData);
  }, [display, routingData]);

  const filterLineFeaturesUnselected = useCallback(feature => {
    return featureFilterUnselectedTourLine(feature, display);
  }, [display]);

  const onEachFeatureCb = useCallback((feature, layer) => {
    onEachFeature(feature, layer, mapData, display, tourData, onTourLineClick);
  }, [mapData, display, tourData, onTourLineClick]);

  const totalTours = tourData && tourData.tours && tourData.tours.length;
  const displayUnselected = tourData && routingRoutes && totalTours <= 100;

  return (
    <>
      {displayUnselected && (
        <GeoJSON
          key={`${mapDataKey(mapData, user, display, routingRoutes)}-routing-unselected`}
          data={routingRoutes}
          style={getStyleUnselected}
          onEachFeature={onEachFeatureCb}
          filter={filterLineFeaturesUnselected}
        />
      )}
      <GeoJSON
        key={`${mapDataKey(mapData, user, display, routingRoutes)}-routing`}
        data={routingRoutes}
        style={getStyle}
        onEachFeature={onEachFeatureCb}
        filter={filterLineFeatures}
      />
      {size(display.routeIds) > 0 && routingRoutes && (
        <MapAnimatedTour tour={routingRoutes} display={display} />
      )}
    </>
  );
};

export default MapTourLines;
