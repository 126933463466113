import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { colors } from 'global/variables';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';

const { aqua } = colors;

const StyledSwitch = styled.span({
  color: aqua,
  fontWeight: 700,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
});

const ReadMoreLess = ({
  short,
  long,
  origin,
  initialShort = true,
  translations: {
    global: { readMore, readLess },
  },
}) => {
  const [isShortVisible, setIsShortVisible] = useState(initialShort);

  const switchVisible = useCallback(() => {
    setIsShortVisible(!isShortVisible);
    AmplitudeService.log(AMPLITUDE_EVENTS.READ_MORE_LESS, {
      read: isShortVisible ? 'more' : 'less',
      origin,
    });
  }, [setIsShortVisible, isShortVisible, origin]);

  return (
    <div>
      {isShortVisible && (
        <span>
          {short}&nbsp;&nbsp;<StyledSwitch onClick={switchVisible}>{readMore}</StyledSwitch>
        </span>
      )}
      {!isShortVisible && (
        <span>
          {long}&nbsp;&nbsp;<StyledSwitch onClick={switchVisible}>{readLess}</StyledSwitch>
        </span>
      )}
    </div>
  );
};

export default withTranslation(ReadMoreLess);
