import {
  SELECT_TOUR_BY_ID,
  CLEAR_MAP_DATA,
  GET_SOLUTION,
  CLEAR_SOLUTION,
  SET_TOUR_PARAMETER,
  SHARE_TOUR_BY_ID,
} from '../actions';
import { getStateFromMemory, setStateToMemory } from '../utils/MemoryHelpers';
import { STORAGE_IDS } from '../utils/localStorageHelpers';

const defaultState = { display: { showProblem: true, routeIds: [], sharedRouteIds: [] } };
const stateID = STORAGE_IDS.mapContainer;
const initialState = getStateFromMemory(stateID, defaultState);

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TOUR_BY_ID: {
      const newState = {
        display: {
          showProblem: false,
          routeIds: [action.payload],
          sharedRouteIds: [...state.display.sharedRouteIds],
        },
      };
      setStateToMemory(stateID, newState);
      return newState;
    }
    case SHARE_TOUR_BY_ID: {
      const sharedRouteIds = [...state.display.sharedRouteIds];
      sharedRouteIds.push(action.payload);
      const newState = {
        display: {
          showProblem: false,
          routeIds: state.display.routeIds,
          sharedRouteIds,
        },
      };
      setStateToMemory(stateID, newState);
      return newState;
    }
    case CLEAR_MAP_DATA: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    case GET_SOLUTION: {
      const newState = action.error ? state : defaultState;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case CLEAR_SOLUTION: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    case SET_TOUR_PARAMETER: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    default:
      return state;
  }
};
