import {
  USER_SET_PARAM,
  USER_SET_COOKIES,
  USER_DISPLAY_COOKIES,
  USER_INCREASE_USAGE,
  RECORD_USAGE_EVENT,
  USAGE_EVENTS,
  SET_UPLOADED_FILE, USER_DISPLAY_SURVEY_BANNER, USER_DISPLAY_SURVEY, USER_SURVEY_COMPLETED,
} from "actions";
import { assign, cloneDeep } from 'lodash';
import moment from 'moment';
import { APP_MODES, getAppMode, getUrlUserSource } from "../utils/urlHelpers";
import { getStorageValue, setStorageValue, STORAGE_IDS } from '../utils/localStorageHelpers';
import { setLocale } from '../utils/timeFormatHelpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../utils/amplitude';
import getInitialLanguage from '../utils/LanguageHelpers';
import { guessDelimiter } from '../utils/csv/helper';

const appMode = getAppMode()
const MAX_SURVEY_DISMISSED = 3
const isSurveyFullyDismissed = state => state.survey.bannerDismissedCounter >= MAX_SURVEY_DISMISSED

const getDefaultStateMemory = storage => {
  if (!storage) return true;
  if (storage.hasStateMemory === undefined) return true;
  return storage.hasStateMemory;
};

const userFromStorage = getStorageValue(STORAGE_IDS.userState);
const sourceFromStorage = userFromStorage && userFromStorage.source;
const sourceFromUrl = getUrlUserSource();
const source = sourceFromUrl || sourceFromStorage || '';
const defaultLanguage = getInitialLanguage();
const cookieFromStorage = getStorageValue(STORAGE_IDS.cookieAgreement, null, true);
const cookieFromStorageVal =
  cookieFromStorage && cookieFromStorage.value && cookieFromStorage.value.siteImprovementApproved;
const cookies = { accept: !!cookieFromStorageVal, display: !cookieFromStorage };
const firstVisitDate = cookieFromStorage ? cookieFromStorage.createdOn : new Date();
const lastVisitDate =
  (userFromStorage && userFromStorage.usage && userFromStorage.usage.lastVisitDate) ||
  firstVisitDate;
const initialUsage = {
  firstVisitDate,
  lastVisitDate,
  loads: cookieFromStorage && cookieFromStorage.createdOn ? 1 : 0,
  days: 1,
  toursPlanned: 0,
  toursDispatched: 0,
  ordersDispatched: 0,
};

const defaultSurvey = {
  isCompleted: false,
  isBannerVisible: appMode === APP_MODES.SURVEY,
  isSurveyVisible: false,
  bannerDismissedCounter: 0,
  isFullyDismissed: false,
}
let fromStorageSurvey
if (userFromStorage && userFromStorage.survey) {
  fromStorageSurvey = {...userFromStorage.survey}
  fromStorageSurvey.isSurveyVisible = false
  if (!userFromStorage.survey.isCompleted && !userFromStorage.survey.isFullyDismissed) {
    fromStorageSurvey.isBannerVisible = !userFromStorage.isDemo
  }
}
const initialSurvey = fromStorageSurvey || defaultSurvey
if (appMode === APP_MODES.SURVEY) initialSurvey.isBannerVisible = true

const usage = (userFromStorage && userFromStorage.usage) || initialUsage;
usage.loads += 1;
if (!moment().isSame(lastVisitDate, 'day')) usage.days += 1;
usage.lastVisitDate = new Date();
const hasStoredIsDemo = userFromStorage && userFromStorage.isDemo !== undefined;
const initialState = {
  language: defaultLanguage,
  isDemo: hasStoredIsDemo ? userFromStorage.isDemo : true,
  isLastOrdersAddedDemo: hasStoredIsDemo ? userFromStorage.isLastOrdersAddedDemo : false,
  useImport: hasStoredIsDemo ? userFromStorage.useImport : false,
  useManual: hasStoredIsDemo ? userFromStorage.useManual : false,
  isOnline: false,
  location: null,
  survey: initialSurvey,
  cookies,
  source,
  usage,
  hasStateMemory: getDefaultStateMemory(userFromStorage),
};
setLocale(initialState.language);
AmplitudeService.setUserParams(initialState);
AmplitudeService.log(AMPLITUDE_EVENTS.PAGE_LOADED);

const storeUserState = state => {
  const toStore = {
    language: state.language,
    source: state.source,
    usage: state.usage,
    isDemo: state.isDemo,
    isLastOrdersAddedDemo: state.isLastOrdersAddedDemo,
    useImport: state.useImport,
    useManual: state.useManual,
    hasStateMemory: state.hasStateMemory,
    survey: state.survey,
  };
  setStorageValue(STORAGE_IDS.userState, toStore);
};

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  switch (type) {
    case USER_SET_PARAM: {
      if (payload.language) {
        setLocale(payload.language);
        AmplitudeService.log(AMPLITUDE_EVENTS.USER_SET_LANGUAGE, { language: payload.language });
      }

      const newState = assign({}, state, payload);
      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState;
    }
    case USER_SET_COOKIES: {
      setStorageValue(STORAGE_IDS.cookieAgreement, { siteImprovementApproved: payload });
      const newCookies = assign({}, state.cookies, { accept: payload });
      return assign({}, state, { cookies: newCookies });
    }
    case USER_DISPLAY_COOKIES: {
      const newCookies = assign({}, state.cookies, { display: payload });
      return assign({}, state, { cookies: newCookies });
    }
    case RECORD_USAGE_EVENT: {
      if (payload.event !== USAGE_EVENTS.ORDERS_ADD) return state;

      const newState = assign({}, state);
      newState.isLastOrdersAddedDemo = payload.mode === 'demo';
      if (payload.mode !== 'demo') newState.isDemo = false;
      if (payload.mode === 'upload' || payload.mode === 'drop') newState.useImport = true;
      if (payload.mode === 'manual') newState.useManual = true;

      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState;
    }
    case USER_INCREASE_USAGE: {
      const newState = assign({}, state);
      if (payload.toursPlanned) newState.usage.toursPlanned += payload.toursPlanned;
      if (payload.ordersDispatched) {
        newState.usage.ordersDispatched += payload.ordersDispatched;
        newState.usage.toursDispatched += payload.toursDispatched || 1;
      }
      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState;
    }
    case SET_UPLOADED_FILE: {
      if (!payload || !payload.file) return state;

      const newState = assign({}, state);
      newState.lastImport = payload;
      newState.lastImport.delimiter = guessDelimiter(payload.file);
      return newState;
    }
    case USER_DISPLAY_SURVEY_BANNER: {
      const newState = assign({}, state);
      if (payload.isDismissal) newState.survey.bannerDismissedCounter = state.survey.bannerDismissedCounter + 1
      newState.survey.isFullyDismissed = isSurveyFullyDismissed(newState)
      newState.survey.isBannerVisible = payload.display
      storeUserState(newState);
      return newState
    }
    case USER_DISPLAY_SURVEY: {
      const newState = assign({}, state);
      if (!payload) newState.survey.bannerDismissedCounter = state.survey.bannerDismissedCounter + 1
      newState.survey.isFullyDismissed = isSurveyFullyDismissed(newState)
      newState.survey.isSurveyVisible = payload
      newState.survey.isBannerVisible = false
      storeUserState(newState);
      return newState
    }
    case USER_SURVEY_COMPLETED: {
      const newState = assign({}, state);
      newState.survey = {
        isCompleted: true,
        isSurveyVisible: false,
        isBannerVisible: false,
        isFullyDismissed: false,
        bannerDismissedCounter: newState.survey.bannerDismissedCounter,
        responses: payload,
      }
      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState
    }
    default: {
      return state;
    }
  }
};
