export const colors = {
  primaryColor: '#22CDC8',
  primaryColorHovered: '#2BDACD',
  secondaryColor: '#80B2FC',
  primaryBorderColor: 'rgba(15, 22, 33, .2)',
  secondaryBorderColor: 'rgba(15, 22, 33, .05)',
  placeholderTextColor: 'rgba(15, 22, 33, .4)',
  primaryTextColor: 'rgba(15, 22, 33, .5)',
  secondaryTextColor: 'rgba(15, 22, 33, .8)',
  primaryTextColorDarkBg: '#F1F5FF',
  secondaryTextColorDarkBg: 'rgba(227,236,254,0.7)',
  deletingTextColor: '#FF6666',
  negativeTextColor: '#FF6666',
  primaryMarkerColor: '#6076B6',
  secondaryMarkerColor: 'rgba(68, 68, 153, 0.9)',
  clusterColor: 'rgba(68, 68, 153, 0.0)',
  unusedMarkerFill: 'rgba(140, 140, 140, 0.95)',
  unusedMarkerStroke: 'rgba(76, 76, 76, .5)',
  transparentWhiteColor: 'rgba(256, 256, 256, 0.9)',
  transparentGreyColor: 'rgba(0,0,0,0.02)',
  transparentBlackColor: 'rgba(15, 22, 33, .5)',
  transparentDarkerColor: 'rgba(15, 22, 33, .75)',
  darkBackground: 'rgb(27, 34, 44, 0.9)',
  darkSolidBackground: '#394049',
  darkerSolidBackground: '#2F353C',
  disabledBackground: 'rgba(200, 200, 200, .3)',

  black: '#0F1621',
  white: '#FFFFFF',
  yellow: '#F5BE45',
  aqua: '#22CDC8',
  animations: ['#80b2fc', '#7fc1f6', '#7ecff0', '#7ddbea', '#7ce4e2'],
  clusters: ['rgba(68, 68, 153, 0)', 'rgba(68, 68, 153, 0)', 'rgba(68, 68, 153, 0)'],
  switchTextColor: '#bee8f5',
  errorBackgroundColor: 'rgba(255, 0, 0, 0.9)',
  loaderBackgroundColor: '#7ce4e2',
};

export const fontSize = {
  big: '1.2rem',
  normal: '1rem',
  medium: '0.9rem',
  small: '0.8rem',
  smaller: '0.7rem',
  mini: '0.5rem',
};

export const lineHeight = {
  lhBig: '1.6rem',
  lhNormal: '1.4rem',
  lhMedium: '1.3rem',
  lhSmall: '1.2rem',
  lhSmaller: '1.1rem',
  lhMini: '0.9rem',
};


export const USE_ALTERNATIVE_THEME = false;
export const currentTheme = 'xmas';
export const themeVars = {
  normal: {
    mapTourLineColor: 'rgba(68, 68, 153, 0.9)',
    animationsColor: ['#80b2fc', '#7fc1f6', '#7ecff0', '#7ddbea', '#7ce4e2'],
    animationsWeight: 2,
  },
  xmas: {
    mapTourLineColor: '#A93232',
    animationsColor: ['#ED4E4E', '#D9F8FF', '#ED4E4E', '#D9F8FF', '#ED4E4E', '#D9F8FF'],
    animationsWeight: 2,
  },
};

export const getThemeProp = prop => {
  const toReturn = USE_ALTERNATIVE_THEME ? currentTheme : 'normal';
  return themeVars[`${toReturn}`][`${prop}`];
};
