import React, { useState, useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { Swipeable } from '../Form/Swipeable';
import HereLogo from '../Presentation/HereLogo';
import { fontSize, colors } from '../../global/variables';
import { isMobileDevice } from '../../utils/helpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../utils/amplitude';
import menu from '../../global/img/menu.svg';
import mapHandler from '../../global/img/map-handler.svg';

const { medium } = fontSize;
const { black } = colors;

const headerHeight = '3rem';

const StyledTopBarContainer = styled.div(({ showTransparent, isVisible }) => ({
  position: 'fixed',
  top: 0,
  right: '0',
  left: '0',
  width: '100%',
  height: headerHeight,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'rgba(250, 250, 250, 1)',
  boxSizing: 'border-box',
  zIndex: '10000',
  padding: '0 2rem',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '0',
  backdropFilter: 'blur(10px)',
  textAlign: 'left',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  div: {
    lineHeight: headerHeight,
    verticalAlign: 'middle',
  },
  a: {
    transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  },
  '@media screen and (max-width: 850px)': {
    textAlign: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: showTransparent ? 'transparent' : 'rgba(250, 250, 250, 1)',
    backdropFilter: 'blur(2px)',
    top: isVisible ? '0' : 'calc(100% - 2rem)',
    a: {
      position: 'absolute',
      top: !isVisible ? '-2rem' : '0.5rem',
      transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
    },
  },
}));

const StyledTopBarTitle = styled.div({
  marginLeft: '1.1rem',
  fontWeight: 'bold',
  color: black,
  opacity: '0.8',
  fontSize: medium,
  display: 'inline-block',
  '@media screen and (max-width: 850px)': {
    display: 'none',
  },
});

const StylesToggleMenuButton = styled.button({
  position: 'fixed',
  top: '0.7rem',
  backgroundColor: 'transparent',
  backgroundImage: `url(${menu})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 1,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',
  zIndex: 10002,
  '@media screen and (max-width: 850px)': {
    left: '0.7rem',
  },
  '@media screen and (min-width: 851px)': {
    right: '1.4rem',
  },
});

const StyledBarHandle = styled.div(({ isVisible }) => ({
  width: '3rem',
  height: '1rem',
  marginTop: isVisible ? '-1.6rem' : '2.4rem',
  backgroundColor: '#f8f9f8',
  borderRadius: '0.2rem',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  cursor: 'pointer',
  backgroundImage: `url(${mapHandler})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  '@media screen and (min-width: 851px)': {
    display: 'none',
    width: 0,
    height: 0,
  },
}));

const TopBar = ({ onClickMenu, onToggleMobileView, isVisible }) => {
  const [showMapMobile, setShowMapMobile] = useState(false);

  const onClickLogo = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component: 'Logo' });
  }, []);

  const onClickBar = useCallback(() => {
    if (!isMobileDevice()) return;

    const component = showMapMobile ? 'map' : 'panel';
    AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component });
    onToggleMobileView();
    setShowMapMobile(!showMapMobile);
  }, [onToggleMobileView, setShowMapMobile, showMapMobile]);

  const onClickMenuButton = useCallback(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component: 'Menu' });
      onClickMenu();
    },
    [onClickMenu],
  );

  const linkUrl = !isMobileDevice()
    ? 'https://www.here.com/wegodeliver?cid=wegodeliver.here.com-logo'
    : '#';
  const linkRef = !isMobileDevice() ? '_blank' : '';

  return (
    <>
      <StylesToggleMenuButton type="button" onClick={onClickMenuButton} />
      <Swipeable onSwipedUp={onClickBar} onSwipedDown={onClickBar}>
        <StyledTopBarContainer
          onClick={onClickBar}
          showTransparent={!showMapMobile}
          isVisible={isVisible}
        >
          <a href={linkUrl} target={linkRef} rel="noopener noreferrer" onClick={onClickLogo}>
            <HereLogo />
          </a>
          <StyledTopBarTitle>HERE WeGo Deliver</StyledTopBarTitle>
          <StyledBarHandle isVisible={!isVisible} />
        </StyledTopBarContainer>
      </Swipeable>
    </>
  );
};

export default withTranslation(TopBar);
