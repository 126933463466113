import axios from 'axios';
import config from 'config';
import { isEmpty, size } from 'lodash';
import { sprintf } from 'utils/helpers';
import OAuthHelpers from 'utils/OAuthHelpers';
import { CSV_META } from 'utils/csv/config';
import parseCSV from 'utils/csv/parser';
import { CSVProcessingError, NoJobsFoundError, ValidationError } from 'utils/csv/errors';
import { mergeRecordsWithGeocodedLocations, sanitizeOrders } from 'utils/csv/helper';
import { solveInWebworker } from '../utils/vrp/vrpSolver';
import { addToCache, getGeocodeRequestsByAddress } from '../utils/GeoCoder';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../utils/amplitude';
import { APP_MODES, getAppMode } from '../utils/urlHelpers';

const appMode = getAppMode();
const {
  api: { url: apiUrl },
  routing: { url: routingUrl },
  oExe: { url: oAuthUrl },
  maxValues,
} = config;

const maxOrders = appMode === APP_MODES.DEVELOPER ? 1000000 : maxValues.orders;

export const FLEET_MODES = {
  SOLO: 'solo',
  CUSTOM: 'custom',
};

export const USAGE_EVENTS = {
  ORDERS_ADD: 'ORDERS_ADD',
  WIZARD_SET_STEP: 'WIZARD_SET_STEP',
  FEEDBACK_ADD: 'FEEDBACK_ADD',
};

export const GET_SOLUTION = 'GET_SOLUTION';
export const CLEAR_SOLUTION = 'CLEAR_SOLUTION';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const CLEAR_MAP_DATA = 'CLEAR_MAP_DATA';
export const SELECT_TOUR_BY_ID = 'SELECT_TOUR_BY_ID';
export const SHARE_TOUR_BY_ID = 'SHARE_TOUR_BY_ID';
export const SET_PROBLEM = 'SET_PROBLEM';
export const GET_ROUTING_TOUR = 'GET_ROUTING_TOUR';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADER = 'SET_LOADER';
export const SET_TOUR_PARAMETER = 'SET_TOUR_PARAMETER';
export const GET_OAUTH = 'GET_OAUTH';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_DEMO = 'SET_ORDERS_DEMO';
export const SET_ORDERS_NOT_LOCATED = 'SET_ORDERS_NOT_LOCATED';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const CLEAR_ORDERS_NOT_LOCATED = 'CLEAR_ORDERS_NOT_LOCATED';
export const USER_SET_PARAM = 'USER_SET_PARAM';
export const USER_SET_COOKIES = 'USER_SET_COOKIES';
export const USER_DISPLAY_COOKIES = 'USER_DISPLAY_COOKIES';
export const USER_INCREASE_USAGE = 'USER_INCREASE_USAGE';
export const RECORD_USAGE_EVENT = 'RECORD_USAGE_EVENT';
export const SET_UPLOADED_FILE = 'SET_UPLOADED_FILE';
export const SET_UPLOADED_IMAGE = 'SET_UPLOADED_IMAGE';
export const USER_DISPLAY_SURVEY_BANNER = 'USER_DISPLAY_SURVEY_BANNER';
export const USER_DISPLAY_SURVEY = 'USER_DISPLAY_SURVEY';
export const USER_SURVEY_COMPLETED = 'USER_SURVEY_COMPLETED';

export function recordUsageEvent(param) {
  return {
    type: RECORD_USAGE_EVENT,
    payload: param,
  };
}

export function clearSolution() {
  return {
    type: CLEAR_SOLUTION,
    payload: null,
  };
}

export function clearMapData() {
  return {
    type: CLEAR_MAP_DATA,
    payload: null,
  };
}

export function setMapData(solution) {
  return {
    type: SET_MAP_DATA,
    payload: solution,
  };
}

export function selectTourById(value) {
  return {
    type: SELECT_TOUR_BY_ID,
    payload: value,
  };
}

export function shareTourById(value) {
  return {
    type: SHARE_TOUR_BY_ID,
    payload: value,
  };
}

export function setProblem(problem) {
  return {
    type: SET_PROBLEM,
    payload: problem,
  };
}

export function setSolution(solution) {
  return {
    type: GET_SOLUTION,
    payload: solution,
  };
}

export function setError(e) {
  return {
    type: SET_ERROR,
    payload: e,
  };
}

export const setLoader = data => ({
  type: SET_LOADER,
  payload: data,
});

export function setOrders(orders, isDemo) {
  const type = isDemo ? SET_ORDERS_DEMO : SET_ORDERS;
  return {
    type,
    payload: orders,
  };
}

export function clearOrders() {
  return { type: CLEAR_ORDERS };
}

export function setOrdersNotLocated(orders) {
  return {
    type: SET_ORDERS_NOT_LOCATED,
    payload: orders,
  };
}

export function clearOrdersNotLocated() {
  return { type: CLEAR_ORDERS_NOT_LOCATED };
}

export const setTourParameter = param => ({
  type: SET_TOUR_PARAMETER,
  payload: param,
});

export const submitProblem = problem => {
  const toUpload = problem.json;
  let payload;
  if (!problem.user || !problem.user.isOnline) {
    payload = solveInWebworker(toUpload);
  } else {
    payload = axios.post(`${apiUrl}/problems`, toUpload, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${problem.oAuth.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  }
  return {
    type: GET_SOLUTION,
    payload,
  };
};

function performRoutingRequest(data) {
  const authHeaders = data.oAuth && {
    headers: {
      Authorization: `Bearer ${data.oAuth.accessToken}`,
    },
  };
  const request = data.routingRequest;
  const params = Object.keys(request).map(key => `${key}=${request[`${key}`]}`);
  const url = `${routingUrl}?${params.join('&')}`;
  return axios.get(url, authHeaders);
}

export const parseAndProcessCSVFile = (file, errorTranslations, orders, mode) => (
  dispatch,
  getState,
) => {
  dispatch(setError());
  dispatch(setLoader({ isLoading: true }));
  parseCSV(file)
    .then(res => {
      if (isEmpty(res) === 0) throw new NoJobsFoundError();

      AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD, {
        addOrdersMode: mode,
        addOrdersAmount: size(res),
        addOrdersHeaders: Object.keys(res[0]).join(','),
      });

      if (size(res) > maxOrders)
        throw new ValidationError('maxJobsFound', { maxOrderAmount: maxOrders });

      const recordsWithNoCoordinates = res.filter(CSV_META.hasNoCoordinates);
      if (recordsWithNoCoordinates.length === 0) {
        return Promise.resolve([res]);
      }
      const { oAuth } = getState();
      const uniqueAddresses = [...new Set(recordsWithNoCoordinates.map(CSV_META.freeTextAddress))];
      const geoRequests = getGeocodeRequestsByAddress(uniqueAddresses, oAuth);
      dispatch(setLoader({ isLoading: true }));

      return Promise.all([
        Promise.resolve(res),
        Promise.all(
          geoRequests.map((promise, i) =>
            promise.then(
              response => {
                addToCache(uniqueAddresses[Number(i)], { data: response.data, request: response.request });
                return { response, status: 'fulfilled' };
              },
              error => ({ error, status: 'rejected' }),
            ),
          ),
        ),
      ]);
    })
    .then(data => {
      let readyData = null;
      if (data.length === 1) {
        readyData = data[0];
      } else {
        const { resolvedRecords, unresolvedRecords } = mergeRecordsWithGeocodedLocations(...data);
        const unresolvedWithAddress = unresolvedRecords.filter(CSV_META.freeTextAddress);

        if (resolvedRecords.length === 0 || !isEmpty(unresolvedWithAddress)) {
          AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD_NOT_LOCATED, {
            action: 'open',
            addOrdersMode: mode,
            addOrdersAmountNotLocated: size(unresolvedRecords),
          });
          if (mode === 'upload' || mode === 'drop') {
            dispatch(setOrdersNotLocated(unresolvedRecords));
          } else if (mode === 'demo') {
            dispatch(setError(errorTranslations.ordersFromUrlNotFoundDemo));
          }
        }
        readyData = resolvedRecords;
      }

      const toSet = sanitizeOrders(readyData, null, orders.length + 1);
      if (orders.length + toSet.length > maxOrders) {
        dispatch(setError(sprintf(errorTranslations.maxJobsFound, { maxOrderAmount: maxOrders })));
        return;
      }

      dispatch(recordUsageEvent({ event: USAGE_EVENTS.ORDERS_ADD, mode }));
      dispatch(setOrders(toSet));
      dispatch(setLoader({ isLoading: false }));
    })
    .catch(error => {
      dispatch(setLoader({ isLoading: false }));
      dispatch(clearMapData());
      dispatch(clearSolution());
      if (error instanceof CSVProcessingError) {
        dispatch(setError(error.getPayload(errorTranslations)));
        return;
      }
      dispatch(setError(errorTranslations.invalidFileFormat));
    });
};

export const setOrdersFromUrl = (url, errorTranslations, orders, mode) => dispatch => {
  const safeUrl = mode !== 'demo' ? `https://cors-anywhere.herokuapp.com/${url}` : url;
  axios
    .get(safeUrl, { headers: { Accept: 'text/csv' } })
    .then(res => {
      dispatch(parseAndProcessCSVFile(res.data, errorTranslations, orders, mode));
    })
    .catch(() => {
      const err =
        mode === 'demo'
          ? errorTranslations.ordersFromUrlNotFoundDemo
          : errorTranslations.ordersFromUrlNotFound;
      dispatch(setError(err));
    });
};

export const getRoutingTourInfo = routingRequest => (dispatch, getState) => {
  if (getState().routingTour[routingRequest.tourId]) return null;

  return dispatch({
    type: GET_ROUTING_TOUR,
    payload: performRoutingRequest(routingRequest),
  });
};

export function getOAuth() {
  const oAuthHeader = OAuthHelpers.createOauthHeader(
    'POST',
    config.oExe.url,
    config.oExe.cla,
    config.oExe.claSe,
  );
  const oAuthBody = { grantType: 'client_credentials', tokenFormat: 'hN' };
  const oAuth = axios.post(oAuthUrl, oAuthBody, {
    headers: {
      Accept: 'application/json',
      Authorization: oAuthHeader,
    },
  });
  return {
    type: GET_OAUTH,
    payload: oAuth,
  };
}

export function setUserParam(param) {
  return {
    type: USER_SET_PARAM,
    payload: param,
  };
}

export function setUserCookies(param) {
  return {
    type: USER_SET_COOKIES,
    payload: param,
  };
}

export function setUserCookiesDisplay(param) {
  return {
    type: USER_DISPLAY_COOKIES,
    payload: param,
  };
}

export function increaseUserUsage(param) {
  return {
    type: USER_INCREASE_USAGE,
    payload: param,
  };
}

export function setUploadedFile(info) {
  return {
    type: SET_UPLOADED_FILE,
    payload: info,
  };
}

export function setUploadedImage(info) {
  return {
    type: SET_UPLOADED_IMAGE,
    payload: info,
  };
}

export function setUserDisplaySurveyBanner(display, isDismissal) {
  return {
    type: USER_DISPLAY_SURVEY_BANNER,
    payload: { display, isDismissal },
  };
}

export function setUserDisplaySurvey(display) {
  return {
    type: USER_DISPLAY_SURVEY,
    payload: display,
  };
}

export function setUserSurveyCompleted(responses) {
  return {
    type: USER_SURVEY_COMPLETED,
    payload: responses,
  };
}
