import { keys, isEmpty, head } from 'lodash';

export const getCSVConfigLabelByCategory = (tourPlanner, category, defaultValue) => {
  if (!tourPlanner || tourPlanner.ordersMode !== 'import') return defaultValue;
  if (!tourPlanner.csvConfig || isEmpty(tourPlanner.csvConfig)) return defaultValue;

  const csvConfig = tourPlanner.csvConfig;
  const columns = keys(csvConfig).filter(key => csvConfig[`${key}`] === category);
  return isEmpty(columns) ? defaultValue : head(columns);
};

export const getCSVConfigLastDemandLabel = (tourPlanner, defaultValue) => {
  if (tourPlanner && !isEmpty(tourPlanner.lastDemandLabel)) return tourPlanner.lastDemandLabel;
  return getCSVConfigLabelByCategory(tourPlanner, 'demand', defaultValue);
};
