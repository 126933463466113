import { isMobileDevice } from "../helpers";
import { colors, getThemeProp } from "../../global/variables";

const { unusedMarkerStroke } = colors;
const tourLineColor = getThemeProp('mapTourLineColor');

export const getMapSettings = () =>
  isMobileDevice()
    ? { paddingTopLeft: [50, 0], paddingBottomRight: [80, 0] }
    : { paddingTopLeft: [450, 100], paddingBottomRight: [40, 40] };

export const animateFlyTo = {
  animation: true,
  duration: 1.111,
  easeLinearity: 0.69,
};

export const getStyle = () => ({ opacity: 1, weight: 4, color: tourLineColor });
export const getStyleUnselected = () => ({ opacity: 0.8, weight: 2, color: unusedMarkerStroke });

