import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';

const QRCodeBase64 = ({ url, id, onGenerateQRCode }) => {
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    const qrEl = document.getElementById(id);
    const qrCodeDataUri = qrEl.toDataURL('image/jpg');
    if (onGenerateQRCode) onGenerateQRCode(id, qrCodeDataUri);
    setDisplay(false);
  }, [setDisplay, onGenerateQRCode, id]);

  return display && <QRCode value={url} id={id} style={{ width: 35, height: 35 }} />;
};

export default QRCodeBase64;
