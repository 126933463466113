import crypto from 'crypto';

const getSecondsSinceEpoch = () => Math.floor(Date.now() / 1000);

const createNonce = len =>
  crypto
    .randomBytes(Math.ceil(len / 2))
    .toString('hex')
    .slice(0, len);

const createOAuthParams = (consumerKey, nonce, timestamp) =>
  [
    `oauth_consumer_key=${consumerKey}`,
    `oauth_nonce=${nonce}`,
    'oauth_signature_method=HMAC-SHA256',
    `oauth_timestamp=${timestamp}`,
    'oauth_version=1.0',
  ].join('&');

const createSignature = (baseString, consumerSecret) => {
  const signatureKey = `${consumerSecret}&`;
  return crypto
    .createHmac('sha256', signatureKey)
    .update(baseString)
    .digest()
    .toString('base64');
};

const OAuthHelpers = {
  createOauthHeader: (method, url, consumerKey, consumerSecret) => {
    const timestamp = getSecondsSinceEpoch();
    const nonce = createNonce(32);
    const encodedUrl = encodeURIComponent(url);
    const encodedParams = encodeURIComponent(createOAuthParams(consumerKey, nonce, timestamp));
    const baseString = `${method}&${encodedUrl}&${encodedParams}`;
    const encodedSignature = encodeURIComponent(createSignature(baseString, consumerSecret));
    const headerParams = [
      `oauth_consumer_key="${consumerKey}"`,
      `oauth_nonce="${nonce}"`,
      `oauth_signature="${encodedSignature}"`,
      'oauth_signature_method="HMAC-SHA256"',
      `oauth_timestamp="${timestamp}"`,
      'oauth_version="1.0"',
    ].join(',');

    return `OAuth ${headerParams}`;
  },
};

export default OAuthHelpers;
