import { v4 } from 'uuid';
import moment from 'moment';
import config from 'config';
import { isNaN, isString, get } from 'lodash';
import { CSV_META } from './csv/config';
import { getVRPTimes, getVRPVehicleDefinition } from './VRPConverterTourPlanner';

const { dateTimeFormat, defaults } = config;
const parseCoordinateValue = value =>
  typeof value === 'number' ? value : parseFloat(value.replace(',', '.'));

const parseRowTimes = row => {
  const knownDateFormats = [CSV_META.timeFormat];
  const timeRegexStart = /(\d?\d:\d\d)/g;
  const timeRegexEnd = /(\d?\d:\d\d)/g;
  const startTime = timeRegexStart.exec(row.StartTime)[1];
  const endTime = timeRegexEnd.exec(row.EndTime)[1];

  return [moment(startTime, knownDateFormats).format(), moment(endTime, knownDateFormats).format()];
};

const createJobPlaceFromRow = row => ({
  duration:
    !isNaN(parseInt(row.ServiceTime, 10)) && parseInt(row.ServiceTime, 10) >= 0
      ? parseInt(row.ServiceTime, 10) * 60
      : defaults.serviceTime,
  location: {
    lat: parseCoordinateValue(row.Latitude),
    lng: parseCoordinateValue(row.Longitude),
  },
  ...(row.StartTime && row.EndTime && { times: [parseRowTimes(row)] }),
});

export function convertParsedCSVToVRPJobs(data = [], isClustered) {
  return data.map(row => ({
    id: isClustered ? row.ClusterID : row.InternalID,
    places: { delivery: createJobPlaceFromRow(row) },
    demand:
      !isNaN(parseInt(row.Demand, 10)) && parseInt(row.Demand, 10) > 0
        ? [parseInt(row.Demand, 10)]
        : [defaults.demand],
    ...(isString(row.Skills) && row.Skills.length > 0 ? { skills: [row.Skills] } : {}),
  }));
}

export function generateVRPPayload(jobs, depot, filename, tourPlanner, orders) {
  const depotLocation = depot && depot.lat ? depot : get(depot, 'places.delivery.location');
  const vehicleDef = getVRPVehicleDefinition(tourPlanner);
  const { start, end } = getVRPTimes(tourPlanner, orders);
  const vrpPayload = {
    id: filename ? filename.replace('.csv', '') : v4(),
    plan: {
      jobs,
    },
    fleet: {
      types: [
        {
          ...vehicleDef,
          shifts: [
            {
              start: {
                location: {
                  ...depotLocation,
                },
                time: start.format(dateTimeFormat),
              },
            },
          ],
        },
      ],
      profiles: [
        {
          name: 'car',
          type: 'car',
        },
      ],
    },
  };

  if (tourPlanner.returnLocation.value !== null) {
    vrpPayload.fleet.types[0].shifts[0].end = {
      location: { ...tourPlanner.returnLocation.value },
      time: end.format(dateTimeFormat),
    };
  } else {
    const shiftTime = (end - start) / 1000;
    vrpPayload.fleet.types[0].limits = { shiftTime };
  }

  return vrpPayload;
}
