import React from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { colors } from 'global/variables';
import WizardHeaderStep from './WizardHeaderStep';

const { transparentGreyColor } = colors;

const StyledContainer = styled.div({
  display: 'grid ',
  gridTemplateColumns: '1fr 1fr 1fr',
  height: '3.2rem',
  padding: '1.1rem 2.5rem 0.5rem 2.5rem',
  backgroundColor: transparentGreyColor,
  '@media screen and (max-width : 850px)': {
    padding: '1.1rem 1rem 0.5rem 1rem',
  },
});

const WizardHeader = ({ step, onChange, hasSolution }) => {
  const showSteps = step <= 3;
  return (
    <StyledContainer showSteps={showSteps}>
      {showSteps &&
        [1, 2, 3].map(i => (
          <WizardHeaderStep
            key={i}
            step={i}
            selectedStep={step}
            onChange={onChange}
            hasSolution={hasSolution}
          />
        ))}
    </StyledContainer>
  );
};

export default withTranslation(WizardHeader);
