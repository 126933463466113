import { size } from "lodash";
import { divIcon, marker } from "leaflet";
import moment from "moment";
import { formatTime } from "../formatter";
import { isPointDepot, isPointReturnLocation, isUnassignedJob, isRouteToDisplay } from "./MapFeatureFilters";
import { isMobileDevice } from "../helpers";

const getTagsRow = jobTags =>
  size(jobTags) > 0
    ? `<tr>
    <td>Tags:</td>
    <td>${jobTags && jobTags.join(', ')}</td>
  </tr>`
    : '';

const getTimeWindowRows = tw =>
  (tw || [])
    .map(
      t =>
        `<tr><td>TW:</td><td>${formatTime(t[0])} - ${formatTime(t[1])}</td></tr>`,
    )
    .join('');

export const makeDepotMarker = (feature, latlng, onMarkerClick, translations) => {
  const { map: { depotMarkerTrans } } = translations;
  const hover = true;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const labels = feature.properties && feature.properties.labels ? feature.properties.labels : [];
  const title =
    labels || address ? `<div class="labels">${address}${labels.join('<br />')}</div>` : '';

  const m = marker(latlng, {
    icon: divIcon({
      className: 'leaflet-marker-icon-job depot-icon',
      html: '<div></div>',
      iconSize: [64, 64],
      iconAnchor: [32, 64],
    }),
  }).bindPopup(
    `${title}${depotMarkerTrans}`, { className: 'custom-depot', autoPan: false },
  );
  m.on('mouseover', () => { if (hover) m.openPopup(); });
  m.on('mouseout', () => { m.closePopup(); });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makeReturnLocationMarker = (feature, latlng, onMarkerClick, translations) => {
  const { map: { returnLocationMarkerTrans } } = translations;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const title = `<div class="labels">${address}</div>`;

  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job return-location-icon`,
      html: '<div></div>',
      iconSize: [64, 64],
      iconAnchor: [32, 64],
    }),
  }).bindPopup(`${title}${returnLocationMarkerTrans}`, {
    className: 'custom-depot',
    autoPan: false,
  });

  m.on('mouseover', () => { m.openPopup(); });
  m.on('mouseout', () => { m.closePopup(); });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makeJobMarker = (feature, latlng, isSelected, onMarkerClick, translations) => {
  const {
    properties: {
      arr_time: arrival,
      dep_time: departure,
      jobOrder,
      jobTags,
      routeId,
      types,
      tw,
    },
  } = feature;

  const {
    tourSolution: { unassignedTrans },
    map: { arrivalJobMarkerTrans, departureJobMarkerTrans },
  } = translations;

  const typesObj = (types || []).reduce((t, item) => {
    if (!t[`${item}`]) t[`${item}`] = 0;
    t[`${item}`] += t[`${item}`];
    return t;
  }, {});

  let typesClass = '';
  if (Object.keys(typesObj).length === 1) {
    typesClass = typesObj.pickup ? 'tour-icon-pickup' : 'tour-icon-delivery';
  } else if (!isUnassignedJob(feature)) {
    typesClass = 'tour-icon-mixed';
  }
  const iconSize = isUnassignedJob(feature) ? [7, 7] : [10, 10];
  const unselectedClass = isUnassignedJob(feature) ? 'unassigned-icon' : 'tour-unselected';
  const selectedClass = isSelected ? 'tour-selected' : unselectedClass;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const labels = feature.properties && feature.properties.labels;
  const title = labels ? `<div class="labels">${address}${labels.join('<br />')}</div>` : '';

  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job tour-icon ${selectedClass} ${typesClass}`,
      html: isSelected ? jobOrder : '',
      iconSize: isSelected ? [35, 35] : iconSize,
      iconAnchor: isSelected ? [12, 35] : [2, 2],
    }),
  }).bindPopup(
    routeId === undefined
      ? `${title}<br/>${unassignedTrans}`
      : `
            ${title}
            <table>
              ${getTagsRow(jobTags)}
              <tr><td>${arrivalJobMarkerTrans}:&nbsp;&nbsp;</td><td>${moment(arrival).format(
      'LT',
      )}</td></tr>
              <tr><td>${departureJobMarkerTrans}:&nbsp;&nbsp;</td><td>${moment(departure).format(
      'LT',
      )}</td></tr>
              ${getTimeWindowRows(tw)}
            </table>`,
    { className: `custom-job ${selectedClass}`, autoPan: false },
  );

  m.on('mouseover', () => { m.openPopup(); });
  m.on('mouseout', () => { m.closePopup(); });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makePlanMarker = (feature, latlng, onMarkerClick, translations) => {
  const { map: { typeJobDeliveryMarkerTrans } } = translations;
  const types = feature.properties && feature.properties.types;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const labels = feature.properties && feature.properties.labels;
  const title = labels ? `<div class="labels">${address}${labels.join('<br />')}</div>` : address;
  const typeString = types ? typeJobDeliveryMarkerTrans : '';
  const iconClass = feature.properties.isEditing ? 'plan-editing-icon' : 'plan-icon';
  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job ${iconClass}`,
      html: '<div></div>',
      iconSize: feature.properties.isEditing ? [35, 35] : [24, 24],
      iconAnchor: feature.properties.isEditing ? [12, 35] : [12, 24],
    }),
  }).bindPopup(`${title}${typeString}`, { className: 'custom-job', autoPan: false });

  m.on('mouseover', () => { m.openPopup(); });
  m.on('mouseout', () => { m.closePopup(); });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makeMarker = (feature, latlng, display, routingRoutes, onMarkerClick, translations) => {
  if (isPointDepot(feature)) return makeDepotMarker(feature, latlng, onMarkerClick, translations);
  if (isPointReturnLocation(feature)) return makeReturnLocationMarker(feature, latlng, onMarkerClick, translations);
  if (isRouteToDisplay(feature, display)) return makeJobMarker(feature, latlng, true, onMarkerClick, translations);
  return !display.showProblem ? makeJobMarker(feature, latlng, false, onMarkerClick, translations) : makePlanMarker(feature, latlng, onMarkerClick, translations);
};
