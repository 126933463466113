import { SET_UPLOADED_IMAGE } from '../actions';
import { getStorageValue, setStorageValue, STORAGE_IDS } from "../utils/localStorageHelpers";

const fromStorage = getStorageValue(STORAGE_IDS.uploadedImage, null);

export default (state = fromStorage, action) => {
  switch (action.type) {
    case SET_UPLOADED_IMAGE:
      setStorageValue(STORAGE_IDS.uploadedImage, action.payload)
      return action.payload || null;
    default:
      return state;
  }
};
