import React, { useEffect, useState, useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { setLoader, setError } from 'actions';
import { colors, fontSize } from 'global/variables';
import { css } from '@emotion/react';
import Spinner from '../../Presentation/Spinner';
import { StyledIcon, StyledFullPanelContainer } from './WizardStyled';
import Button from '../../Form/Button';
import { getAsset } from '../../../utils/helpers';
import warningIcon from '../../../global/img/warning-icon.svg';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';

const { aqua, black, primaryTextColor, secondaryTextColor } = colors;
const { normal, medium } = fontSize;

export const WarningIconStyle = css`
  background-image: url(${getAsset(warningIcon)});
  margin: 0;
  width: 2rem;
  height: 2rem;
  background-size: 1.4rem;
  background-position: center !important;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  animation: EntryAnimationNotificationIcon 0.3s 1;
`;

const StyledNotificationText = styled.div({
  margin: '0 auto',
  position: 'absolute',
  top: '50%',
  width: '60%',
  transform: 'translateY(-50%)',
  padding: '20%',
  lineHeight: '1.2rem',
  borderRadius: '2px',
  p: {
    marginBottom: '2rem',
    marginTop: 0,
    fontSize: medium,
    color: primaryTextColor,
    fontWeight: 300,
    lineHeight: '1.2rem',
  },
  h1: {
    fontSize: normal,
    color: secondaryTextColor,
  },
});

const StyledNotificationSpinner = styled.div({
  margin: '2rem auto',
  zIndex: 4000,
});

const StyledLinkContainer = styled.div({
  marginTop: '6rem',
});

const StyledLink = styled.a({
  textDecoration: 'none',
  fontSize: medium,
  color: black,
  ':active': {
    color: black,
  },
});

const Notifications = ({ user, translations }) => {
  const dispatch = useDispatch();
  const error = useSelector(({ error: stateError }) => stateError);
  const loader = useSelector(({ loader: stateLoader }) => stateLoader);
  const handleSetLoader = useCallback(() => dispatch(setLoader()), [dispatch]);
  const handleSetError = useCallback(() => dispatch(setError()), [dispatch]);
  const [msg, setMsg] = useState(null);
  const {
    notification: { errorHeader, planningTourHeader, buttonTryAgain },
    contact: { linkTitle },
  } = translations;

  useEffect(() => {
    if (!error && (!loader || !loader.isLoading)) {
      setMsg();
      return;
    }

    if (error) {
      const { errorMessage, errorCode } = error;
      const text = errorCode ? translations.error[`${errorCode}`] : errorMessage;
      setMsg(text);
      AmplitudeService.log(AMPLITUDE_EVENTS.ERROR, error);
      return;
    }

    if (loader.isLoading) setMsg(translations.loader.message);
  }, [error, loader, user, translations, setMsg]);

  useEffect(() => {
    if (!loader.isLoading) setMsg();
  }, [user.language, loader.isLoading, setMsg]);

  const onClick = useCallback(() => {
    handleSetError();
    handleSetLoader();
  }, [handleSetLoader, handleSetError]);

  if (!msg) return <></>;

  return (
    <StyledFullPanelContainer isError={error}>
      <StyledNotificationText id="label-error-message">
        {error && <StyledIcon icon={WarningIconStyle} />}
        {loader && loader.isLoading && (
          <StyledNotificationSpinner>
            <Spinner color={aqua} />
          </StyledNotificationSpinner>
        )}

        <h1>{error ? errorHeader : planningTourHeader}</h1>
        <p id="label-error-message">{msg}</p>

        {error && !error.isFatal && (
          <Button id="button-error-try-again" text={buttonTryAgain} onClick={onClick} inverted />
        )}
        {error && (
          <StyledLinkContainer>
            <StyledLink href="mailto:wegodeliver@here.com">
              <Button id="button-error-help" text={linkTitle} />
            </StyledLink>
          </StyledLinkContainer>
        )}
      </StyledNotificationText>
    </StyledFullPanelContainer>
  );
};

export default withTranslation(Notifications);
