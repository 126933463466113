import React, { useState, useCallback, useEffect } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { size, isEmpty } from 'lodash';
import iconEmail from 'global/img/mail.svg';
import iconEmailContrast from 'global/img/mail-contrast.svg';
import iconShare from 'global/img/share.svg';
import iconPdf from 'global/img/pdf.svg';
import iconPdfContrast from 'global/img/pdf-contrast.svg';
import iconCSV from 'global/img/csv.svg';
import iconCSVContrast from 'global/img/csv-contrast.svg';
import { BlobProvider } from '@react-pdf/renderer';
import { useDispatch } from 'react-redux';
import { StyledLink } from '../Global/WizardStyled';
import { canDownloadCSV, copyToClipboard, isMobileDevice } from '../../../utils/helpers';
import {
  createTourShareData,
  exportTourToCSV,
  makeShareNavLink,
} from '../../../utils/SolutionExport';
import SimpleButton from '../../Form/SimpleButton';
import { getTourDemand } from '../../../utils/OrdersHelpers';
import {
  getNumberOfDepotStops,
  getTotalJobsInTour,
  isDemandVisible,
} from '../../../utils/SolutionHelpers';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import TourPDF from './TourPDF';
import QRCodeBase64 from './QRCodeBase64';
import DownloadPDF from './DownloadPDF';
import { increaseUserUsage, shareTourById } from '../../../actions/index';
import { getUrlInitialTourIndex } from '../../../utils/urlHelpers';

const initialTourIndex = getUrlInitialTourIndex();

const StyledActions = styled.div({
  height: '2.7rem',
  margin: 'auto',
  div: {
    display: 'inline-block',
  },
});

const StyledHiddenContainer = styled.div({
  visibility: 'hidden',
});

const TourDispatch = ({
  user,
  selectedTours,
  solution,
  tourPlanner,
  tour,
  request,
  origin,
  helpers: { formatDuration },
  translations,
  contrast,
  onStartShare,
  onFinishShare,
}) => {
  const dispatch = useDispatch();
  const handleIncreaseUsage = useCallback(data => dispatch(increaseUserUsage(data)), [dispatch]);
  const handleShareTour = useCallback(data => dispatch(shareTourById(data)), [dispatch]);
  const [stopsQRCodes, setStopsQRCodes] = useState([]);
  const [loadPDF, setLoadPDF] = useState(false);
  const [printDemand, setPrintDemand] = useState(false);
  const qrCodes = [];

  useEffect(() => {
    if (!request || isEmpty(request.orders)) return;
    setPrintDemand(isDemandVisible(request.orders, tourPlanner));
  }, [request, tourPlanner, setPrintDemand]);

  const onRenderQRCode = useCallback(
    (id, imageUri) => {
      qrCodes.push(imageUri);
      if (size(qrCodes) === size(tour.stops)) setStopsQRCodes(qrCodes);
    },
    [setStopsQRCodes, qrCodes, tour],
  );

  const {
    map,
    tourSolution: { tourDetails },
    wizard: {
      orders: { download },
      toursDetails: { shareTour, sharing, toursDetailsTitle },
    },
    csv: { phoneTrans },
  } = translations;
  const formattedDuration = tour.statistic.duration
    ? formatDuration(Math.floor(tour.statistic.duration / 60))
    : 'Unknown';

  const translationsToCopy = {
    ...map,
    ...sharing,
    ...tourDetails,
    toursDetailsTitle,
    phoneTrans,
  };

  const logToAmplitude = useCallback(
    mode => {
      const tourInfo = {
        stopsInTour: tour.stops.length - getNumberOfDepotStops(tour.stops),
        ordersInTour: getTotalJobsInTour(tour.stops),
        dropsInTour: getTourDemand(tour, request),
        dispatchMode: mode,
        origin,
        isDemo: user.isLastOrdersAddedDemo,
      };
      if (onStartShare) onStartShare();
      handleShareTour(selectedTours[0]);
      AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, tourInfo);
    },
    [user, tour, request, selectedTours, origin, handleShareTour, onStartShare],
  );

  const postDispatch = useCallback(
    mode => {
      const ordersDispatched = getTotalJobsInTour(tour.stops);
      handleIncreaseUsage({ ordersDispatched, mode });
      if (onFinishShare) onFinishShare();
    },
    [tour, onFinishShare, handleIncreaseUsage],
  );

  const shareData = createTourShareData(tour, request, translationsToCopy, tourPlanner, user);
  const copyShareDataToClipboard = useCallback(() => {
    copyToClipboard(shareData.text);
    logToAmplitude('email');
    postDispatch('email');
  }, [shareData, logToAmplitude, postDispatch]);

  const onClickPDFDownload = useCallback(
    evt => {
      if (isMobileDevice()) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      setLoadPDF(true);
      logToAmplitude('pdf');
    },
    [setLoadPDF, logToAmplitude],
  );

  const onFinishPDF = useCallback(() => {
    setLoadPDF(false);
    postDispatch('pdf');
  }, [setLoadPDF, postDispatch]);

  const onClickShareNative = useCallback(
    evt => {
      if (isMobileDevice()) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      const titleArr = ['HERE WeGo Deliver'];
      if (tourPlanner && !isEmpty(tourPlanner.companyName))
        titleArr.unshift(tourPlanner.companyName);
      const title = titleArr.join(' & ');
      navigator.share({
        title,
        text: shareData.text,
      });
      logToAmplitude('share');
      postDispatch('share');
    },
    [shareData, logToAmplitude, postDispatch, tourPlanner],
  );

  const onClickDownloadCSV = useCallback(
    evt => {
      if (isMobileDevice()) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      logToAmplitude('csv');
      postDispatch('csv');
      exportTourToCSV(tour, request, tourPlanner, user, selectedTours[0] + initialTourIndex);
    },
    [tour, request, tourPlanner, user, selectedTours, logToAmplitude, postDispatch],
  );

  const canShare = navigator.share;

  return (
    <>
      <StyledActions>
        {tour && isEmpty(stopsQRCodes) && (
          <StyledHiddenContainer>
            {tour.stops.map((stop, index) => (
              <QRCodeBase64
                url={makeShareNavLink(stop, user.language, request.orders, tourPlanner)}
                id={`qr-code-${selectedTours[0]}-${index}`}
                onGenerateQRCode={onRenderQRCode}
                key={index}
              />
            ))}
          </StyledHiddenContainer>
        )}
        {!isEmpty(stopsQRCodes) && (
          <SimpleButton
            id="button-tour-details-pdf"
            icon={contrast ? iconPdfContrast : iconPdf}
            title={download}
            bigger
            onClick={onClickPDFDownload}
          />
        )}
        {canDownloadCSV() && (
          <SimpleButton
            id="button-tour-details-csv"
            icon={contrast ? iconCSVContrast : iconCSV}
            title={download}
            bigger
            onClick={onClickDownloadCSV}
          />
        )}
        {canShare && (
          <SimpleButton
            id="button-tour-details-share-native"
            icon={iconShare}
            title={shareTour}
            onClick={onClickShareNative}
          />
        )}
        {!canShare && (
          <StyledLink
            id="button-tour-details-share"
            target="_blank"
            rel="noopener noreferrer"
            href={shareData.link}
          >
            <SimpleButton
              icon={contrast ? iconEmailContrast : iconEmail}
              title={shareTour}
              onClick={copyShareDataToClipboard}
            />
          </StyledLink>
        )}
      </StyledActions>
      {loadPDF && (
        <BlobProvider
          document={
            <TourPDF
              tour={tour}
              tourID={selectedTours[0] + initialTourIndex}
              solution={solution}
              tourPlanner={tourPlanner}
              translations={translations}
              formattedDuration={formattedDuration}
              user={user}
              qrCodes={stopsQRCodes}
              printDemand={printDemand}
            />
          }
        >
          {({ blob, loading, error }) => (
            <DownloadPDF
              blob={blob}
              loading={loading}
              error={error}
              tourID={selectedTours[0] + initialTourIndex}
              onFinish={onFinishPDF}
              user={user}
            />
          )}
        </BlobProvider>
      )}
    </>
  );
};

export default withTranslation(TourDispatch);
