import styled from '@emotion/styled';
import { colors, fontSize } from "../../../global/variables";
import close from "../../../global/img/close-contrast.svg";

const { transparentDarkerColor, white } = colors;
const { medium } = fontSize;

export const StyledSurveyMainContainer = styled.div(({ isVisible}) => ({
  backgroundColor: transparentDarkerColor,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: isVisible ? 0 : '-100%',
  width: '100%',
  height: '100%',
  zIndex: '3000000',
  padding: '10%',
  boxSizing: 'border-box',
  textAlign: 'center',
  fontSize: medium,
  transition: isVisible && 'all 350ms ease-in-out',

  '@media screen and (max-width: 850px)': {
    padding: '1rem',
  },
}))

export const StyledInnerContainer = styled.div({
  position: 'relative',
  backgroundColor: white,
  maxWidth: '30rem',
  margin: '0 auto',
  textAlign: 'left',
  padding: '0.6rem 1.4rem',
  borderRadius: '0.25rem 0.25rem 2rem 2rem',

  '@media screen and (max-width: 850px)': {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: 'unset',
    height: 'calc(100vh - 6rem)',
  },
})

export const StyledSurveyHeader = styled.div({
  textAlign: 'center',
})

export const StyledCloseButton = styled.button({
  backgroundColor: 'transparent',
  backgroundImage: `url(${close})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 0.8,
  width: '1.2rem',
  height: '1.2rem',
  cursor: 'pointer',
  position: 'absolute',
  top: '1rem',
  right: '1rem',

  '&:hover': {
    opacity: 1,
  },

  '@media screen and (max-width: 850px)': {
    top: '0.5rem',
    right: '0.5rem',
    width: '1rem',
    height: '1rem',
  },
});

export const StyledQuestionContainer = styled.div({
  padding: '0.75rem 0 0 0',
})

export const StyledQuestionLabelContainer = styled.div({
  marginBottom: '0.75rem',
})

export const StyledQuestionTextBoxContainer = styled.div({
  maxWidth: '30rem',
  marginTop: '1rem',
})

export const StyledQuestionCheckboxContainer = styled.div({
  lineHeight: '1.4rem',
  verticalAlign: 'middle',
  cursor: 'pointer',
  marginBottom: '0.2rem',
})

export const StyledIntroContainer = styled.div({
  textAlign: 'center',
  p: {
    lineHeight: '1.3rem',
  },
})

export const StyledButtonContainer = styled.div({
  margin: '3rem auto 1rem auto',
  maxWidth: '20rem',
})
