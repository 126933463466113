import React, { useCallback } from "react";
import { GeoJSON,  useMap } from "react-leaflet";
import withTranslation from 'hoc/withTranslation';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { mapDataKey, toBounds } from "../../utils/map/MapCalculations";
import {
  filterDepot,
  featureFilter,
  featureFilterUnselectedMarker,
  isRouteToDisplay,
  isUnassignedJob,
} from "../../utils/map/MapFeatureFilters";
import { makeMarker } from "../../utils/map/MapMarkersUtils";
import { createClusterCustomIcon } from "../../utils/map/MapClusterUtils";
import { calculateBBox } from "../../utils/GeoJSONConverter";
import { animateFlyTo, getMapSettings, getStyle } from "../../utils/map/MapUtils";
import { AMPLITUDE_EVENTS, AmplitudeService } from "../../utils/amplitude";

const DEFAULT_CLUSTER_RADIUS = 50;
const MIN_CLUSTER_RADIUS = 40;
const CLUSTER_BREAKPOINT_MAP_ZOOM = 12;

const MapMarkers = ({ mapData, user, display, routingRoutes, tourData, routingData, isSolution, activateTour, translations }) => {
  const map = useMap();

  const onMarkerClick = useCallback(feature => {
    const routeId = feature.properties.routeId;
    const isSelected = display.routeIds.includes(routeId);
    const isPointClickable = !isSolution || isSelected || isUnassignedJob(feature);

    if (isPointClickable) {
      const dist = isUnassignedJob(feature) || !isSolution ? 200 : 60;
      const bounds = calculateBBox([feature.geometry.coordinates], dist);
      map.flyToBounds(
        toBounds(bounds),
        getMapSettings(),
        animateFlyTo,
      );
      AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ZOOM_IN);
    }

    if (routeId === undefined) return;
    const tour = tourData.tours[`${routeId}`];
    activateTour(routeId, tour);
    AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ACTIVATE_TOUR);
  }, [display, isSolution, tourData]);

  const makeMapMarkers = useCallback((feature, latlng) => {
    return makeMarker(feature, latlng, display, routingRoutes, onMarkerClick, translations);
  }, [display, routingRoutes, translations]);

  const filterMarkersFeatures = useCallback(feature => {
    return featureFilter(feature, display, routingData);
  }, [display, routingData]);

  const filterMarkersUnselectedFeatures = useCallback(feature => {
    return featureFilterUnselectedMarker(feature, display);
  }, [display]);

  const createClusterIcon = cluster => {
    return createClusterCustomIcon(cluster);
  };

  const clusteredData = useCallback(() => {
    const start = filterDepot(mapData, false);
    return isSolution ? start.filter(f => isRouteToDisplay(f, display)) : start;
  }, [isSolution, mapData, display]);

  const totalTours = tourData && tourData.tours && tourData.tours.length;
  const displayUnselected = isSolution && tourData && totalTours <= 100;

  return (
    <>
      {displayUnselected && (
        <GeoJSON
          key={`${mapDataKey(mapData, user, display, routingRoutes)}-marker-unselected`}
          data={filterDepot(mapData, false)}
          pointToLayer={makeMapMarkers}
          filter={filterMarkersUnselectedFeatures}
        />
      )}
      <MarkerClusterGroup
        iconCreateFunction={createClusterIcon}
        showCoverageOnHover={false}
        maxClusterRadius={level =>
          level > CLUSTER_BREAKPOINT_MAP_ZOOM ? MIN_CLUSTER_RADIUS : DEFAULT_CLUSTER_RADIUS
        }
      >
        <GeoJSON
          key={mapDataKey(mapData, user, display, routingRoutes)}
          data={clusteredData()}
          pointToLayer={makeMapMarkers}
          style={getStyle}
          filter={filterMarkersFeatures}
        />
      </MarkerClusterGroup>

      <GeoJSON
        key={mapDataKey(mapData, user, display, routingRoutes)}
        data={filterDepot(mapData, true)} // each tour has own depot point. Filter out with the same location
        pointToLayer={makeMapMarkers}
        filter={filterMarkersFeatures}
      />
    </>
  );
};

export default withTranslation(MapMarkers);
