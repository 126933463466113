import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setOrders, clearOrders } from 'actions';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { map } from 'lodash';
import { css } from '@emotion/react';
import { StyledInfoEllipsis, StyledInfoWrapper, StyledIcon } from '../Global/WizardStyled';
import { getAsset } from '../../../utils/helpers';
import deleteIcon from '../../../global/img/delete.svg';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';

const {
  primaryBorderColor,
  secondaryBorderColor,
  transparentGreyColor,
  black,
  primaryTextColor,
  secondaryTextColor,
} = colors;
const { medium, small } = fontSize;

const StyledGridRow = styled.div`
  display: grid;
  grid-template-columns: 1rem 2rem 0.6fr 1.2fr 0.5fr 1rem;

  div:nth-last-of-type(-n + 6) {
    border-bottom: none;
  }
  div {
    text-align: left;
    vertical-align: text-bottom;
  }
  div:nth-last-of-type(6n + 2) {
    text-align: center;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1rem 2rem 0.7fr 1.2fr 0.5fr 1rem;

  margin-top: 0;
  border-bottom: 1px solid ${primaryBorderColor};

  & > div {
    text-transform: uppercase;
    font-size: ${small};
    position: relative;
    top: -2px;
    opacity: 0.75;
  }
`;

const StyledOrder = styled.div({
  lineHeight: '3rem',
  padding: '0.5rem 0.4rem 0.5rem 0',
  borderBottom: `1px solid ${secondaryBorderColor}`,
  color: secondaryTextColor,
  fontSize: medium,
  fontWeight: '700',
  backgroundColor: transparentGreyColor,
});

const StyledOrderName = styled.div({
  color: black,
  lineHeight: '1rem',
  padding: '0.5rem 0',
  div: {
    textAlign: 'left !important',
    width: '6rem',
    display: 'block',
    color: secondaryTextColor,
  },
  'div:last-of-type': {
    color: primaryTextColor,
    fontWeight: 400,
  },
});

const StyledOrderAddress = styled(StyledInfoWrapper)({
  color: secondaryTextColor,
  fontWeight: 400,
});

const StyledRemoveIconStyle = css`
  background-image: url(${getAsset(deleteIcon)});
  cursor: pointer;
  background-size: 1.2rem;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: 3px;
  opacity: 0.8;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);

  &:hover {
    opacity: 1;
  }
`;

const Order = withTranslation(({ order, index, onOrderRemove, allowRemove }) => {
  return (
    <>
      <StyledOrder />
      <StyledOrder>
        {allowRemove && (
          <StyledIcon
            id={`button-remove-order-${index}`}
            icon={StyledRemoveIconStyle}
            onClick={() => onOrderRemove(order)}
          />
        )}
      </StyledOrder>
      <StyledOrder id={`label-order-name-${index}`}>
        <StyledOrderName>
          <StyledInfoEllipsis title={order.Name}>{order.Name}</StyledInfoEllipsis>
          <StyledInfoEllipsis title={order.ID}>{order.ID}</StyledInfoEllipsis>
        </StyledOrderName>
      </StyledOrder>
      <StyledOrder id={`label-order-address-${index}`}>
        <StyledOrderAddress title={order.Address}>{order.Address}</StyledOrderAddress>
      </StyledOrder>
      <StyledOrder id={`label-order-demand-${index}`}>{order.Demand || 1}</StyledOrder>
      <StyledOrder />
    </>
  );
});

const Orders = ({
  orders,
  allowRemove,
  tourPlanner,
  translations: {
    wizard: {
      orders: { ordersHeaders },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleSetOrders = useCallback(o => dispatch(setOrders(o)), [dispatch]);
  const handleClearOrders = useCallback(() => dispatch(clearOrders()), [dispatch]);
  const demandLabel = getCSVConfigLastDemandLabel(tourPlanner, ordersHeaders[2]);
  const tableHeader = useMemo(
    () => (
      <StyledHeader>
        {map(['', '', ...ordersHeaders, ''], (header, index) => (
          <StyledInfoEllipsis key={index} title={index === 4 ? demandLabel : header}>
            {index === 4 ? demandLabel : header}
          </StyledInfoEllipsis>
        ))}
      </StyledHeader>
    ),
    [ordersHeaders, demandLabel],
  );

  const onOrderRemove = useCallback(
    order => {
      handleClearOrders();
      const newOrders = orders.filter(o => o !== order);
      handleSetOrders(newOrders);
      AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_DELETE_SINGLE);
    },
    [orders, handleClearOrders, handleSetOrders],
  );

  return (
    <>
      {tableHeader}
      <div>
        {orders.map((order, index) => (
          <StyledGridRow key={index}>
            <Order
              index={index}
              key={`order-${index}`}
              order={order}
              onOrderRemove={onOrderRemove}
              allowRemove={allowRemove}
            />
          </StyledGridRow>
        ))}
      </div>
    </>
  );
};

export default withTranslation(Orders);
