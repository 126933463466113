import styled from '@emotion/styled';
import { isMobileDevice } from '../../utils/helpers';
import logo from '../../global/img/logo.svg';
import smallLogo from '../../global/img/small-logo.svg';
import logoLight from '../../global/img/logo-light.svg';

const isMobile = isMobileDevice();

const HereLogo = styled.div(({ backgroundTheme = 'light' }) => ({
  backgroundImage: `url(${backgroundTheme === 'light' ? logo : logoLight})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: isMobile ? 'center 3px' : '0px 3px',
  width: '2rem',
  height: '2rem',
  '@media screen and (max-width: 850px)': {
    backgroundImage: `url(${backgroundTheme === 'light' ? smallLogo : logoLight})`,
  },
}));

export default HereLogo;
