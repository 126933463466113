export const STORAGE_IDS = {
  tourPlanner: 'tourPlanner',
  introVersion: 'introVersion',
  cookieAgreement: 'cookieAgreement',
  userState: 'userState',
  usage: 'userUsage',
  usageContext: 'usageContext',
  orders: 'orders',
  mapContainer: 'mapContainer',
  mapData: 'mapData',
  routingTour: 'routingTour',
  solution: 'solution',
  uploadedImage: 'uploadedImage',
};

function isValueValid(value) {
  if (!value || !value.createdOn) return false;

  const now = new Date();
  const then = new Date(value.createdOn);
  const diffTime = Math.abs(then.getTime() - now.getTime());
  const days = diffTime / (1000 * 60 * 60 * 24);
  return days < 365;
}

export function getStorageValue(storageId, defaultValue = null, returnFull = false) {
  if (!storageId) return defaultValue;

  const storedObject = window.localStorage.getItem(storageId);
  if (!storedObject) return defaultValue;

  let obj;
  try {
    obj = JSON.parse(storedObject);
  } catch {
    obj = null;
  }

  const toReturn = returnFull ? obj : obj && obj.value;
  return isValueValid(obj) ? toReturn : defaultValue;
}

export function setStorageValue(storageId, value) {
  if (!storageId) return;
  if (value === undefined) {
    window.localStorage.removeItem(storageId);
    return;
  }
  const previousValue = getStorageValue(storageId, null, true);
  const createdOn = previousValue ? previousValue.createdOn : new Date();
  const toStore = { createdOn, lastUpdate: new Date(), value };
  window.localStorage.setItem(storageId, JSON.stringify(toStore));
}
