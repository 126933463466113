import React from "react";
import styled from "@emotion/styled";
import withTranslation from "../../../hoc/withTranslation";
import Button from "../../Form/Button";
import { StyledButtonContainer, StyledIntroContainer } from "./SurveyCommon";

export const StyledQuestionContainer = styled.div({
  padding: '0.75rem 0 0 0',
  p: {
    marginBottom: '2rem',
  },
})

const SurveyIntro = ({ onNext, translations }) => {
  const { survey: { surveyLabels } } = translations

  return (
    <StyledIntroContainer>
      <StyledQuestionContainer>
        <p>{surveyLabels.description}</p>
        <p>
          {surveyLabels.disclaimer}&nbsp;
          <a href="https://legal.here.com/en-en/privacy/policy" target="_blank" rel="noopener noreferrer">HERE Privacy policy</a>
        </p>
      </StyledQuestionContainer>
      <StyledButtonContainer>
          <Button text={surveyLabels.buttonStart} onClick={onNext} />
      </StyledButtonContainer>
    </StyledIntroContainer>
  );
};

export default withTranslation(SurveyIntro);
