import { SET_ORDERS, CLEAR_ORDERS, SET_ORDERS_DEMO } from 'actions/index';
import { getStateFromMemory, setStateToMemory } from '../utils/MemoryHelpers';
import { STORAGE_IDS } from '../utils/localStorageHelpers';

const stateID = STORAGE_IDS.orders;
const initialState = getStateFromMemory(stateID, []);

export default (state = initialState, { payload = [], type }) => {
  switch (type) {
    case SET_ORDERS: {
      const newState = state.concat(payload);
      setStateToMemory(stateID, newState);
      return newState;
    }
    case SET_ORDERS_DEMO: {
      return state.concat(payload);
    }
    case CLEAR_ORDERS: {
      setStateToMemory(stateID, []);
      return [];
    }
    default: {
      return state;
    }
  }
};
