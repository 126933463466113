let workerInst;

export function initWebworker() {
  workerInst = new Worker('vrp_worker.js');
  workerInst.postMessage({ type: 'INIT' });
  return new Promise((resolve, reject) => {
    workerInst.onmessage = msg => {
      if (msg.data.type === 'INIT_DONE') {
        resolve();
      } else if (msg.data.type === 'ERROR') {
        reject(msg.data.error);
      } else {
        reject(new Error('Undefined message type.'));
      }
    };
  });
}

export function solveInWebworker(problem) {
  workerInst.postMessage({ type: 'PROBLEM', problem });
  return new Promise((resolve, reject) => {
    workerInst.onmessage = msg => {
      if (msg.data.type === 'SOLUTION') {
        resolve({ data: msg.data.solution });
      } else if (msg.data.type === 'ERROR') {
        reject(msg.data.error);
      } else {
        reject(new Error('Undefined message type.'));
      }
    };
  });
}
