import React from 'react';
import styled from '@emotion/styled';
import { size } from 'lodash';
import withTranslation from 'hoc/withTranslation';
import Button from '../../Form/Button';
import { colors } from '../../../global/variables';

const { white } = colors;

const StyledContainer = styled.div({
  height: '4.5rem',
  padding: '0.9rem',
  boxSizing: 'border-box',
  borderTop: `solid 1px rgba(0,0,0,0.05)`,
  backgroundColor: white,
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr',
  textRendering: 'geometricPrecision',
  zIndex: 900,
  div: {
    width: '95%',
    justifySelf: 'end',
  },
  'div:first-of-type': {
    justifySelf: 'start',
  },
});

const StyledCourtain = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  padding: '1rem',
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, .8)',
  zIndex: 1000,
});

const WizardFooter = ({
  step,
  onClick,
  onClickNextTour,
  isSubmitting,
  orders,
  tourData,
  tourPlanner,
  disabled,
  translations: {
    tourPlanner: { submitTrans },
    tourSolution: { backTrans },
    wizard: {
      next,
      toursDetails: { nextTour },
    },
  },
}) => {
  const hasDepot = !!(tourPlanner && tourPlanner.location && tourPlanner.location.value);
  const showNextTour = tourData && size(tourData.tours) > 1;
  const minOrders = hasDepot ? 1 : 2;
  const hasOrders = orders && orders.length >= minOrders;

  return (
    <StyledContainer>
      {step === 1 && (
        <>
          <div />
          <Button id="button-fleet-next" text={next} onClick={() => onClick(step + 1)} />
        </>
      )}
      {step === 2 && (
        <>
          <Button
            id="button-orders-back"
            inverted
            text={backTrans}
            onClick={() => onClick(step - 1)}
          />
          <Button
            id="button-orders-next"
            text={submitTrans}
            disabled={isSubmitting || !hasOrders}
            onClick={() => onClick(step + 1)}
          />
        </>
      )}
      {step === 3 && (
        <>
          <Button
            id="button-solution-back"
            inverted
            text={backTrans}
            onClick={() => onClick(step - 1)}
          />
          <div />
        </>
      )}
      {step === 4 && (
        <>
          <Button
            id="button-tour-details-back"
            inverted
            text={backTrans}
            onClick={() => onClick(step - 1)}
          />
          {showNextTour && (
            <Button id="button-tours-next" text={nextTour} onClick={onClickNextTour} />
          )}
        </>
      )}
      {disabled && <StyledCourtain />}
    </StyledContainer>
  );
};

export default withTranslation(WizardFooter);
