import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { recordUsageEvent, setOrders, setTourParameter, USAGE_EVENTS } from 'actions';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, assign, isNumber, size } from 'lodash';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import Label from '../../Form/Label';
import InputLocation from '../Global/InputLocation';
import Button from '../../Form/Button';
import directions from '../../../global/img/plus.svg';
import iconPhone from '../../../global/img/phone.svg';
import iconName from '../../../global/img/customer-name.svg';
import iconAddress from '../../../global/img/delivery-address.svg';
import { StyledTextInput } from '../Global/WizardStyled';
import config from '../../../config';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';

const StyledManualContainer = styled.div({
  position: 'relative',
  padding: '0',
});

const StyledButtonContainer = styled.div({
  position: 'absolute',
  bottom: '-2.4rem',
  textAlign: 'center',
  width: '100%',
  div: {
    div: {
      backgroundPosition: 'center',
      backgroundSize: '1rem',
    },
    margin: 'auto',
  },
});

export const StyledDoubleContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '2.8fr 1.2fr',
  justifyContent: 'space-around',
  width: '100%',
  padding: '0 0 0.8rem 0',
  'div:nth-of-type(2)': {
    paddingLeft: '15%',
  },
});

export const StyledLastInput = styled.div({
  padding: '0 0 1.2rem 0',
});

const { maxValues } = config;

const emptyOrder = {
  Address: '',
  Name: '',
  Demand: 1,
  'Service time (min)': 5,
  Latitude: null,
  Longitude: null,
  isEditing: true,
  InternalID: v4(),
  Phone: '',
};

const OrdersManual = ({
  oAuth,
  user,
  tourPlanner,
  orders,
  translations: {
    csv: { addressTrans, nameTrans, demandTrans, phoneTrans },
    tourPlanner: { depotPlaceholderTrans },
    error: { depotNotFound },
  },
}) => {
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(p => dispatch(setTourParameter(p)), [dispatch]);
  const handleRecordUsage = useCallback(data => dispatch(recordUsageEvent(data)), [dispatch]);
  const handleSetOrder = useCallback(
    order => {
      dispatch(setOrders([order]));
    },
    [dispatch],
  );
  const [editedOrder, setEditedOrder] = useState(emptyOrder);
  const [noAddressFound, setNoAddressFound] = useState(false);

  useEffect(() => {
    handleSetTourParameter({ editedOrder: { ...emptyOrder } });
  }, [handleSetTourParameter]);

  /*
  useEffect(() => {
    if (tourPlanner.editedOrder && tourPlanner.editedOrder.isDetectedInFile) {
      setEditedOrder(tourPlanner.editedOrder);
    } else {
      handleSetTourParameter({ editedOrder: { ...emptyOrder } });

    }
  }, [handleSetTourParameter, setEditedOrder, tourPlanner]);

   */

  const onLocationFound = useCallback(
    loc => {
      setNoAddressFound(!loc.value && !isEmpty(loc.label));
      const newOrder = assign({}, editedOrder, {
        Address: loc.label,
        Latitude: loc.value && loc.value.lat,
        Longitude: loc.value && loc.value.lng,
      });
      setEditedOrder(newOrder);
      handleSetTourParameter({ editedOrder: { ...newOrder } });
    },
    [setNoAddressFound, setEditedOrder, editedOrder, handleSetTourParameter],
  );

  const onChangeName = useCallback(
    e => {
      const value = e.target.value;
      const newOrder = assign({}, editedOrder, { Name: value });
      setEditedOrder(newOrder);
    },
    [setEditedOrder, editedOrder],
  );

  const onBlurName = useCallback(() => {
    handleSetTourParameter({ editedOrder: { ...editedOrder } });
  }, [editedOrder, handleSetTourParameter]);

  const onChangePhone = useCallback(
    e => {
      const value = e.target.value;
      const newOrder = assign({}, editedOrder, { Phone: value });
      setEditedOrder(newOrder);
    },
    [setEditedOrder, editedOrder],
  );

  const onBlurPhone = useCallback(() => {
    handleSetTourParameter({ editedOrder: { ...editedOrder } });
  }, [editedOrder, handleSetTourParameter]);

  const onChangeDemand = useCallback(
    e => {
      const value = e.target.value && parseInt(e.target.value, 10);
      const newOrder = assign({}, editedOrder, { Demand: value });
      setEditedOrder(newOrder);
      e.target.focus();
    },
    [setEditedOrder, editedOrder],
  );

  const onBlurDemand = useCallback(
    e => {
      const value = e.target.value && parseInt(e.target.value, 10);
      if (!Number.isNaN(value) && value >= 1 && value <= maxValues.demand) {
        const newOrder = assign({}, editedOrder, { Demand: value });
        handleSetTourParameter({ editedOrder: { ...newOrder } });
      } else {
        const newOrder = assign({}, editedOrder, { Demand: tourPlanner.editedOrder.Demand });
        setEditedOrder(newOrder);
      }
    },
    [setEditedOrder, editedOrder, handleSetTourParameter, tourPlanner],
  );

  const onSave = useCallback(() => {
    handleSetOrder(editedOrder);
    emptyOrder.InternalID = v4();
    setEditedOrder(emptyOrder);
    handleSetTourParameter({ editedOrder: { ...emptyOrder } });
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD, {
      addOrdersMode: 'manual',
      addOrdersAmount: 1,
    });
    handleRecordUsage({ event: USAGE_EVENTS.ORDERS_ADD, mode: 'manual' });
  }, [setEditedOrder, handleSetOrder, editedOrder, handleSetTourParameter, handleRecordUsage]);

  const isButtonDisabled = !isNumber(editedOrder.Latitude) || size(orders) >= maxValues.orders;

  return (
    <StyledManualContainer>
      <StyledDoubleContainer>
        <div>
          <Label>{nameTrans}</Label>
          <StyledTextInput
            id="input-edit-order-name"
            placeholder="William R. Hamilton"
            value={editedOrder.Name}
            onChange={onChangeName}
            onBlur={onBlurName}
            icon={iconName}
            autoComplete="nope"
          />
        </div>
        <div>
          <Label>{demandTrans}</Label>
          <StyledTextInput
            id="input-edit-order-demand"
            value={editedOrder.Demand}
            type="Number"
            pattern="[0-9]*"
            inputmode="numeric"
            onChange={onChangeDemand}
            onBlur={onBlurDemand}
            max={maxValues.demand}
            min="1"
            autoComplete="nope"
          />
        </div>
      </StyledDoubleContainer>
      <>
        {!noAddressFound && <Label>{addressTrans}</Label>}
        {noAddressFound && <Label error>{depotNotFound}</Label>}
        <InputLocation
          id="input-edit-order-add"
          placeholder={depotPlaceholderTrans}
          value={editedOrder.Address}
          oAuth={oAuth}
          onLocationFound={onLocationFound}
          user={user}
          tourPlanner={tourPlanner}
          icon={iconAddress}
        />
      </>
      <StyledLastInput>
        <Label>{phoneTrans}</Label>
        <StyledTextInput
          id="input-edit-order-phone"
          value={editedOrder.Phone}
          onChange={onChangePhone}
          onBlur={onBlurPhone}
          icon={iconPhone}
          autoComplete="nope"
        />
      </StyledLastInput>
      <StyledButtonContainer>
        <Button
          icon={directions}
          onClick={onSave}
          disabled={isButtonDisabled}
          id="button-add-order-manual"
        />
      </StyledButtonContainer>
    </StyledManualContainer>
  );
};

export default withTranslation(OrdersManual);
