import { isEmpty } from 'lodash';
import { divIcon } from "leaflet";

export const createClusterCustomIcon = (cluster) => {
  const tmpClusters = cluster.getAllChildMarkers();
  tmpClusters.sort((a, b) => {
    return a.feature.properties.jobOrder - b.feature.properties.jobOrder;
  });
  const childrenForRoute = tmpClusters.filter(c => c.feature.properties.routeId !== undefined);
  const isSolution = !isEmpty(childrenForRoute);

  const type = isSolution ? 'stop' : 'order';
  const displayInfo = isSolution
    ? tmpClusters[0].feature.properties.jobOrder
    : cluster.getChildCount();

  const additionalClass = cluster.getChildCount() === 2 ? 'double' : 'multiple';
  return divIcon({
    html: `<span>${displayInfo}</span>`,
    className: `marker-cluster marker-cluster-${type} marker-cluster-${additionalClass}`,
    iconSize: [40, 46],
  });
};
