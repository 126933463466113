/* eslint-disable react/jsx-props-no-spreading */
import en from 'global/locales/en/main';
import es from 'global/locales/es/main';
import fr from 'global/locales/fr/main';
import de from 'global/locales/de/main';
import it from 'global/locales/it/main';
import hi from 'global/locales/hi/main';
import pt from 'global/locales/pt/main';
import ru from 'global/locales/ru/main';
import nl from 'global/locales/nl/main';
import th from 'global/locales/th/main';
import el from 'global/locales/el/main';
import { isFunction, mapValues } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import * as solutionHelpers from 'utils/formatter';
import * as costHelpers from 'utils/cost';

const translations = { en, es, fr, de, it, hi, pt, ru, nl, th, el };
const mapFunction = (fn, units) => (isFunction(fn) ? value => fn(value)(units) : fn);

const withTranslation = Component => props => {
  const user = useSelector(({ user: stateUser }) => stateUser);
  const language = user.language;
  const linkLang = user.language === 'el' ? 'gr-el' : `${user.language}-${user.language}`;

  const { units, ...currentTranslations } = translations[`${language}`];
  const helpers = {
    ...mapValues(solutionHelpers, fn => mapFunction(fn, units)),
    ...mapValues(costHelpers, fn => mapFunction(fn, units)),
  };

  return (
    <Component
      helpers={helpers}
      translations={{ ...currentTranslations }}
      linkLang={linkLang}
      {...props}
    />
  );
};

export default withTranslation;
