import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  & > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid ${props => props.color || '#fff'};
    border-radius: 50%;
    animation: ${props => props.frames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color || '#fff'} transparent transparent transparent;
  }

  & > div:nth-type(1) {
    animation-delay: -0.45s;
  }
  & > div:nth-type(2) {
    animation-delay: -0.3s;
  }
  & > div:nth-type(3) {
    animation-delay: -0.15s;
  }
`;

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = ({ color }) => (
  <SpinnerContainer frames={animation} color={color}>
    <div />
    <div />
    <div />
  </SpinnerContainer>
);

export default Spinner;
