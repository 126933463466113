import React from 'react';
import { Image, Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import moment from 'moment';
import { isEmpty, size } from 'lodash';
import { getAsset, isSameLocation, isValidPhone } from 'utils/helpers';
import depot from 'global/img/depot.png';
import returnLocation from 'global/img/return-location.png';
import {
  getOrdersAddress,
  getOrdersByLocation,
  getOrdersByStop,
} from '../../../utils/OrdersHelpers';
import { makeShareNavLink } from '../../../utils/SolutionExport';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';

const styles = StyleSheet.create({
  section: {
    margin: 20,
    marginTop: 0,
    marginBottom: 0,
    flexDirection: 'row',
  },

  // Address, Arrive/ Depart
  summaryAddress: {
    width: 200,
    flexGrow: 1,
    marginBottom: 30,
  },

  stopInfo: {
    marginLeft: 0,
    fontWeight: 700,
    fontSize: 8,
  },

  stopInfoLink: {
    marginLeft: 0,
    fontWeight: 700,
    fontSize: 8,
    marginBottom: 6,
  },

  stopLink: {
    display: 'block',
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 30,
    fontSize: 8,
  },

  // QR
  summaryQr: {
    width: 100,
    flexGrow: 1,
    position: 'relative',
    top: -10,
    marginLeft: 25,
  },

  // Text styles
  infoBold: {
    fontSize: 7,
    fontWeight: 700,
  },

  infoRegular: {
    fontSize: 7,
    fontWeight: 400,
  },

  uppercased: {
    textTransform: 'uppercase',
    fontSize: 5,
    fontWeight: 400,
    color: '#A3A3A3',
    marginBottom: 2,
    marginTop: 6,
  },

  summary: {
    flexGrow: 1,
    margin: 0,
  },

  summaryStop: {
    width: 1,
    flexGrow: 1,
    marginBottom: 0,
  },

  summarySchedule: {
    width: 100,
    flexGrow: 1,
    marginBottom: 20,
  },

  // Order detail Information

  orderContainer: {
    marginTop: 1,
    marginBottom: 1,
  },

  infoAddress: {
    backgroundColor: '#F6F6F6',
    width: 440,
    paddingTop: 6,
    paddingLeft: 10,
    paddingBottom: 0,
  },

  infoContainer: {
    backgroundColor: '#F6F6F6',
    margin: 0,
    paddingTop: 0,
    paddingLeft: 10,
    paddingBottom: 0,
    width: 440,
    flexDirection: 'row',
    display: 'block',
  },

  summaryIdDemand: {
    width: 10,
    flexGrow: 1,
    marginBottom: 7,
  },
  summaryContactInfo: {
    width: 80,
    flexGrow: 1,
    marginBottom: 7,
  },
  summaryNotes: {
    width: 120,
    flexGrow: 1,
    paddingRight: 20,
    marginBottom: 7,
  },

  // Delivered / Signature / Comment

  summarySignatureComment: {
    width: 100,
    flexGrow: 1,
    borderColor: 'white',
    borderLeftWidth: 2,
    paddingBottom: 5,
    paddingLeft: 10,
  },

  yesNoText: {
    marginLeft: 12,
    fontSize: 7,
    fontWeight: 700,
    position: 'relative',
    top: 8,
  },

  tickMark: {
    backgroundColor: 'white',
    borderColor: 'black',
    borderWidth: 1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
    width: 8,
    height: 8,
    marginRight: 8,
    display: 'inline',
  },

  checkmarkComponent: {
    position: 'relative',
    top: 0,
  },

  signature: {
    textTransform: 'uppercase',
    fontSize: 5,
    fontWeight: 400,
    color: '#545961',
    marginTop: 17,
    borderColor: 'black',
    paddingTop: 2,
    borderTopWidth: 1,
    width: 100,
    marginBottom: 0,
  },

  stopIcon: {
    backgroundColor: '#6B63CB',
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
    color: 'white',
    fontWeight: 700,
    fontSize: 10,
    padding: 5,
    paddingTop: 7,
    width: 25,
    height: 25,
    textAlign: 'center',
    display: 'block',
    position: 'relative',
    top: 10,
  },
  qr: {
    width: 50,
    height: 50,
    position: 'relative',
    left: 0,
    top: -5,
  },
  departureArrivalIcon: {
    position: 'relative',
    width: 25,
    height: 25,
    top: 10,
  },
});

const StopPDF = ({
  index,
  stop,
  request,
  isDepot,
  isReturnLocation,
  tourPlanner,
  user,
  qrCode,
  printDemand,
  translations: {
    map: { depotMarkerTrans },
    tourSolution: {
      tourDetails: { arrivalTrans, departTrans, stopTrans },
    },
    wizard: {
      toursDetails: {
        pdf: {
          linkText,
          arriveTitle,
          departTitle,
          notesWasDelivered,
          notesSignature,
          notesComment,
          contactInfo,
        },
      },
    },
    csv: { idTrans, demandTrans, notesTrans, addressTrans },
  },
}) => {
  let tourPlannerLabel = null;
  if (isDepot) {
    tourPlannerLabel = tourPlanner && tourPlanner.value && tourPlanner.location.label;
    if (!tourPlannerLabel) {
      const ordersForDepot = getOrdersByLocation(request.orders, stop.location);
      tourPlannerLabel = !isEmpty(ordersForDepot) ? ordersForDepot[0].Address : null;
    }
  }

  if (isReturnLocation) {
    tourPlannerLabel = isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
      ? depotMarkerTrans
      : tourPlanner.returnLocation.label;
  }

  const ordersInStop = getOrdersByStop(request.orders, stop);
  const address = getOrdersAddress(ordersInStop);
  const defaultVal = isDepot ? null : `${stopTrans} ${index}`;
  const defaultLabel = defaultVal || (isDepot ? depotMarkerTrans : arrivalTrans);
  const label = address || tourPlannerLabel || defaultLabel;
  const order = !isDepot ? index : '-';
  const linkUrl = makeShareNavLink(stop, user.language, request.orders, tourPlanner);
  const addressLabel =
    !isDepot && !isReturnLocation ? addressTrans : isReturnLocation ? arrivalTrans : departTrans;
  const scheduleLabel =
    !isDepot && !isReturnLocation
      ? `${arriveTitle} / ${departTitle}`
      : isReturnLocation
      ? arriveTitle
      : departTitle;
  const wrapStop = size(ordersInStop) > 1;
  const demandLabel = getCSVConfigLastDemandLabel(tourPlanner, demandTrans);

  return (
    <>
      <View style={styles.section} key={index} wrap={wrapStop}>
        <View style={styles.summaryStop}>
          {!isDepot && !isReturnLocation && <Text style={styles.stopIcon}>{order}</Text>}
          {isDepot && <Image style={styles.departureArrivalIcon} src={getAsset(depot)} />}
          {isReturnLocation ? (
            isSameLocation(tourPlanner.location, tourPlanner.returnLocation) ? (
              <Image style={styles.departureArrivalIcon} src={getAsset(depot)} />
            ) : (
              <Image style={styles.departureArrivalIcon} src={getAsset(returnLocation)} />
            )
          ) : null}
        </View>

        <View style={styles.summaryAddress}>
          <Text style={styles.uppercased}>{addressLabel}</Text>
          <Text style={styles.stopInfo}>{label}</Text>
          {!isDepot && linkUrl && (
            <Text style={styles.stopInfoLink}>
              <Link src={linkUrl} style={styles.stopLink}>
                {linkText}
              </Link>
            </Text>
          )}
          {!isDepot &&
            !isReturnLocation &&
            ordersInStop &&
            ordersInStop.map((o, i) => (
              <View style={styles.orderContainer} key={`order-${i}`} wrap={false}>
                {wrapStop && (
                  <View style={styles.infoAddress} key={`order-${i}-address`}>
                    <Text style={styles.infoBold}>{o.Address}</Text>
                  </View>
                )}
                <View style={styles.infoContainer} key={`order-${i}-info`}>
                  {(!isEmpty(o.ID) || printDemand) && (
                    <View style={styles.summaryIdDemand}>
                      {!isEmpty(o.ID) && (
                        <>
                          <Text style={styles.uppercased}>{idTrans}</Text>
                          <Text key={`stop-${index}-${i}-0`} style={styles.infoRegular}>
                            {o.ID}
                          </Text>
                        </>
                      )}
                      {printDemand && (
                        <>
                          <Text style={styles.uppercased}>{demandLabel}</Text>
                          <Text key={`stop-${index}-${i}-1`} style={styles.infoRegular}>
                            {o.Demand || 1}
                          </Text>
                        </>
                      )}
                    </View>
                  )}

                  <View style={styles.summaryContactInfo}>
                    <Text style={styles.uppercased}>{contactInfo}</Text>
                    <Text key={`stop-${index}-${i}-2`} style={styles.infoBold}>
                      {o.Name || ''}
                    </Text>
                    {!isEmpty(o.Phone) && (
                      <Text style={styles.infoRegular}>
                        {isValidPhone(o.Phone) && <Link src={`tel:${o.Phone}`}>{o.Phone}</Link>}
                        {!isValidPhone(o.Phone) && <>{o.Phone}</>}
                      </Text>
                    )}
                    <Text style={styles.infoRegular}>{o.Email || ''}</Text>
                  </View>

                  <View style={styles.summaryNotes}>
                    <Text style={styles.uppercased}>{notesTrans}</Text>
                    <Text style={styles.infoRegular}>{o.Notes || ''}</Text>
                  </View>

                  <View style={styles.summarySignatureComment}>
                    <View style={styles.checkmarkComponent}>
                      <Text style={styles.yesNoText}>{notesWasDelivered}</Text>
                      <View style={styles.tickMark} />
                    </View>
                    <Text style={styles.signature}>{`${notesSignature}/${notesComment}`}</Text>
                  </View>
                </View>
              </View>
            ))}
        </View>

        <View style={styles.summarySchedule}>
          <Text style={styles.uppercased}>{scheduleLabel}</Text>
          <Text style={styles.stopInfo}>
            {isDepot && moment(stop.time.departure).format('LT')}
            {isReturnLocation && moment(stop.time.arrival).format('LT')}
            {!isDepot &&
              !isReturnLocation &&
              `${moment(stop.time.arrival).format('LT')} - ${moment(stop.time.departure).format(
                'LT',
              )}`}
          </Text>
        </View>
        <View style={styles.summaryQr}>
          {!isDepot && qrCode && <Image src={qrCode} style={styles.qr} />}
        </View>
      </View>
      <View style={styles.section} />
    </>
  );
};

const StopsPDF = ({ stops, request, translations, tourPlanner, user, qrCodes, printDemand }) => (
  <>
    {stops.map((stop, index) => (
      <StopPDF
        index={index}
        key={`stop-${index}`}
        stop={stop}
        prevStop={stops[index - 1]}
        request={request}
        isDepot={index === 0}
        isReturnLocation={
          index === stops.length - 1 && (!tourPlanner || tourPlanner.returnLocation.value !== null)
        }
        translations={translations}
        tourPlanner={tourPlanner}
        user={user}
        qrCode={qrCodes[Number(index)]}
        printDemand={printDemand}
      />
    ))}
  </>
);

export default StopsPDF;
