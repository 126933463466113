export const formatDistance = value => ({ distance: { kilo, meter } }) => {
  if (value < 250) {
    return `${Math.round(value / 5.0) * 5} ${meter}`;
  }
  if (value < 500) {
    return `${Math.round(value / 10.0) * 10} ${meter}`;
  }
  if (value < 1000) {
    return `${Math.round(value / 50.0) * 50} ${meter}`;
  }
  const distance = value / 1000.0;
  const fractionLength = distance >= 10.0 ? 0 : 1;
  return `${distance.toLocaleString([], { maximumFractionDigits: fractionLength })} ${kilo}`;
};

export const formatTime = timeInMins => () => {
  const time = new Date();
  time.setHours(0);
  time.setMinutes(timeInMins);
  time.setSeconds(0);
  return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const formatDuration = minTotal => ({ time: { hour, minute } }) => {
  const total = Math.round(minTotal);
  const minutes = total % 60;
  const hours = (total - minutes) / 60;
  if (minutes > 0 && hours > 0) {
    return `${hours}${hour} ${minutes}${minute}`;
  }
  if (minutes === 0 && hours === 0) {
    return '--';
  }
  return minutes > 0 ? `${minutes}${minute}` : `${hours}${hour}`;
};

export const formatPrice = price => ({ currency } = {}) => `${price.toLocaleString()}${currency}`;
export const formatPricePerTime = price => ({ currency, time: { hour } } = {}) =>
  `${price}${currency}/${hour}`;
export const formatPricePerDistance = price => ({ currency, distance: { kilo } } = {}) =>
  `${price}${currency}/${kilo}`;
export const formatPricePerTour = price => ({ currency, tour } = {}) =>
  `${price}${currency}/${tour}`;
