import { SET_UPLOADED_FILE } from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case SET_UPLOADED_FILE:
      return action.payload || null;
    default:
      return state;
  }
};
