import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getRoutingTourInfo, selectTourById } from 'actions';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { camelCase, head, includes, size } from 'lodash';
import { getDemand, getLastValidIteration } from 'utils/SolutionHelpers';
import { tourToRoutingRequest } from 'utils/RoutingConverter';
import Tour from './Tour';
import { isMobileDevice } from '../../../utils/helpers';

const { primaryBorderColor } = colors;
const { small } = fontSize;

const gridSizes = isMobile => {
  if (!isMobile) return '0.5rem 0.5fr 0.1fr 1fr 1fr 1.8fr 1.8fr 0.5rem 0.5rem';
  return '0.5rem 0.5fr 0.1fr 1.4fr 1.4fr 1.8fr 1fr 0.5rem 0.5rem';
};

const StyledGrid = styled.div(({ isMobile }) => ({
  display: 'grid',
  gridTemplateColumns: gridSizes(isMobile),
  div: {
    textAlign: 'center',
    verticalAlign: 'text-bottom',
  },
  'div:nth-last-of-type(-n + 9)': {
    borderBottom: 'none',
  },
}));

const StyledHeader = styled.div`
  text-transform: uppercase;
  padding: 0 0.2rem;
  text-align: center;
  border-bottom: 1px solid ${primaryBorderColor};
  text-rendering: geometricPrecision;

  & > span {
    font-size: ${small};
    opacity: 0.75;
    position: relative;
    top: -2px;
  }
`;

const Tours = ({
  oAuth,
  id,
  lastIterationTourData: { fleet, plan, tours = [], uploaded },
  helpers,
  onTourChange,
  selectedTours,
  sharedTours,
  solution,
  tourPlanner,
  user,
  translations: {
    tourSolution: { tourHeaders },
  },
}) => {
  const dispatch = useDispatch();
  const handleSelectTourById = useCallback(index => dispatch(selectTourById(index)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest({ ...tour, routeId: index }),
        }),
      ),
    [dispatch, oAuth],
  );

  const onClick = useCallback(
    (index, tour) => () => {
      if (!includes(selectedTours, index)) {
        handleSelectTourById(index);
        handleGetRoutingTourInfo(index, tour);
      }
    },
    [handleGetRoutingTourInfo, handleSelectTourById, selectedTours],
  );

  const onClickViewTour = useCallback(
    (index, tour) => () => {
      onClick(index, tour);
      onTourChange(tour);
    },
    [onClick, onTourChange],
  );

  useEffect(() => {
    if (size(tours) && selectedTours.length === 0) {
      handleSelectTourById(0);
      handleGetRoutingTourInfo(0, head(tours));
    }
  }, [handleGetRoutingTourInfo, handleSelectTourById, tours, selectedTours]);

  const tableHeader = useMemo(
    () => (
      <>
        {['', '#', ...tourHeaders, '', ''].map((header, index) => (
          <StyledHeader key={camelCase(`${header}-${index}`)}>
            <span>{header}</span>
          </StyledHeader>
        ))}
      </>
    ),
    [tourHeaders],
  );

  const request = getLastValidIteration(solution).request;

  return (
    <>
      <StyledGrid id={id} isMobile={isMobileDevice()}>
        {tableHeader}
        {tours.map((tour, index) => (
          <Tour
            demand={!uploaded ? getDemand(tour, plan) : null}
            fleet={fleet}
            helpers={helpers}
            isActive={includes(selectedTours, index)}
            key={`tour-${index}`}
            onClickTour={onClick(index, tour)}
            tour={tour}
            uploaded={uploaded}
            onTourChange={onClickViewTour(index, tour)}
            index={index}
            solution={solution}
            tourPlanner={tourPlanner}
            user={user}
            selectedTours={selectedTours}
            sharedTours={sharedTours}
            request={request}
          />
        ))}
      </StyledGrid>
    </>
  );
};

export default withTranslation(Tours);
