import { concat, filter, flatten, isEmpty, isEqual, size, get } from 'lodash';
import { getSequenceNumbersOfJobs } from './SolutionHelpers';
import {
  getOrdersByStop,
  getLabelsFromOrders,
  getOrdersByLocation,
  getOrdersByInternalID,
  getAddressFromOrders,
  getOrdersAddress,
} from './OrdersHelpers';
import { getBoundingCoordinates } from './MapHelpers';

const mapFeatures = (coordinates = []) =>
  coordinates.map(({ coordinate, properties = {} }) => ({
    type: 'Feature',
    geometry: { coordinates: coordinate, type: 'Point' },
    properties,
  }));

export const calculateBBox = (coordinates, distance = 100) => {
  if (coordinates.length === 1) return getBoundingCoordinates(coordinates[0], distance);
  return [
    Math.min(...coordinates.map(stop => stop[0])),
    Math.min(...coordinates.map(stop => stop[1])),
    Math.max(...coordinates.map(stop => stop[0])),
    Math.max(...coordinates.map(stop => stop[1])),
  ];
};

export const convertToGeoJSON = (solution, plannedJobs = [], orders = [], tourPlanner) => {
  if (!solution) {
    return undefined;
  }

  const geoJSON = {};
  const features = [];
  const allCoordinates = [];
  const jobNumbers = getSequenceNumbersOfJobs(plannedJobs);
  (solution.tours || []).forEach((tour, tourIndex) => {
    if (!tour.stops) return;
    const coordinates = [];
    tour.stops.forEach((stop, index) => {
      coordinates.push([stop.location.lng, stop.location.lat]);
      const stopOrders = getOrdersByStop(orders, stop);
      let address = getOrdersAddress(stopOrders);
      const labels = getLabelsFromOrders(stopOrders);
      if (index === 0) {
        address =
          tourPlanner &&
          tourPlanner.location &&
          tourPlanner.location.value &&
          tourPlanner.location.value.lat
            ? tourPlanner.location.label
            : size(orders) && orders[0].Address;
      }
      if (
        index === size(tour.stops) - 1 &&
        tourPlanner.returnLocation !== undefined &&
        tourPlanner.returnLocation.value !== null
      ) {
        address = tourPlanner.returnLocation.label;
      }

      features.push({
        type: 'Feature',
        geometry: { coordinates: [stop.location.lng, stop.location.lat], type: 'Point' },
        properties: {
          // filtering out breaks
          jobNumbers: stop.activities.map(a => jobNumbers[a.jobId]).filter(v => v !== undefined),
          routeId: tourIndex,
          arr_time: stop.time.arrival,
          dep_time: stop.time.departure,
          jobOrder: index,
          skills: [], // toDo
          types: stop.activities.map(a => a.type),
          jobIds: stop.activities.map(a => a.jobId),
          jobTags: stop.activities.map(a => a.jobTag).filter(val => val),
          vehicle: { type: tour.vehicleId },
          labels,
          address,
        },
      });
    });

    allCoordinates.push(...coordinates);

    const line = {
      geometry: {
        coordinates,
        type: 'LineString',
      },
      type: 'Feature',
      properties: {
        routeId: tourIndex,
        cost: tour.statistic.cost,
        distance: tour.statistic.distance,
        duration: tour.statistic.duration,
        times: tour.statistic.times,
        vehicle: { type: tour.typeId, id: tour.vehicleId },
      },
    };
    line.bbox = calculateBBox(coordinates);
    features.push(line);
  });

  (solution.unassigned || []).forEach(unassigned => {
    const job = plannedJobs.find(item => item.id === unassigned.jobId);
    if (!job) return;

    let coordinates = [];
    if (job.places.delivery || job.places.deliveries) {
      const places = job.places.deliveries || [job.places.delivery];
      places.forEach(place => {
        coordinates = [place.location.lng, place.location.lat];
        const stopOrders = getOrdersByLocation(orders, place.location);
        const address = !isEmpty(stopOrders) ? stopOrders[0].Address : null;
        const labels = getLabelsFromOrders(stopOrders);
        features.push({
          type: 'Feature',
          geometry: { coordinates, type: 'Point' },
          properties: {
            jobId: job.id,
            jobNumber: jobNumbers[job.id],
            reason: unassigned.reasons,
            labels,
            address,
          },
        });
        allCoordinates.push(coordinates);
      });
    } else if (job && (job.places.pickup || job.places.pickups)) {
      const places = job.places.deliveries || [job.places.delivery];
      places.forEach(place => {
        coordinates = [place.location.lng, place.location.lat];
        features.push({
          type: 'Feature',
          geometry: { coordinates, type: 'Point' },
          properties: {
            jobId: job.id,
            jobNumber: jobNumbers[job.id],
            reason: unassigned.reasons,
          },
        });
        allCoordinates.push(coordinates);
      });
    }
  });

  const valid = allCoordinates.filter(c => c[0] && c[1]);
  if (valid.length === 0) return null;

  const bbox = calculateBBox(allCoordinates);
  geoJSON.geo = { features, bbox };

  return geoJSON;
};

export const convertProblemToGeoJSON = (
  { plan, fleet } = {},
  depot,
  returnLocation,
  orders = [],
  depotAddress,
  returnLocationAddress,
  editedOrder,
) => {
  if (!plan || !fleet) return null;

  const { jobs } = plan;
  const { types } = fleet;

  if (!size(types)) return null;

  const locations = concat(
    flatten(
      jobs.map(({ id, places: { deliveries = [], pickups = [], delivery = {}, pickup = {} } }) =>
        concat(deliveries, pickups, delivery, pickup)
          .filter(({ location }) => !isEmpty(location))
          .map(({ location }) => ({
            coordinate: [location.lng, location.lat],
            properties: {
              address: getAddressFromOrders(getOrdersByInternalID(orders, id)),
              labels: getLabelsFromOrders(getOrdersByInternalID(orders, id)),
              types: filter(
                [
                  (size(deliveries) > 0 || !isEmpty(delivery)) && 'delivery',
                  (size(pickups) > 0 || !isEmpty(pickup)) && 'pickup',
                ],
                type => type,
              ),
            },
          })),
      ),
    ),
    [],
  );

  if (editedOrder && editedOrder.Latitude && editedOrder.Longitude) {
    locations.push({
      coordinate: [editedOrder.Longitude, editedOrder.Latitude],
      properties: {
        isEditing: true,
        address: editedOrder.Address,
        labels: getLabelsFromOrders([editedOrder]),
      },
    });
  }

  if (locations.length === 0 && !depot) return null;

  let depots = [];
  if (depot || (types && !isEmpty(types[0].shifts[0].start.location))) {
    depots = (size(depot) === 2
      ? [[depot.lng, depot.lat]]
      : flatten(
          types.map(
            ({
              shifts: [
                {
                  start: {
                    location: { lat: startLat, lng: startLng },
                  },
                },
              ],
            }) => {
              const endLoc = get(types, 'shifts.end.location');
              const endLat = endLoc ? endLoc.lat : null;
              const endLng = endLoc ? endLoc.lng : null;
              if (!endLat || !endLng) return [[startLng, startLat]];
              return isEqual(startLat, endLat) && isEqual(startLng, endLng)
                ? [[startLng, startLat]]
                : [
                    [startLng, startLat],
                    [endLng, endLat],
                  ];
            },
          ),
        )
    ).map(coordinate => ({
      coordinate,
      properties: {
        isDepot: true,
        address: depotAddress,
      },
    }));
  }

  let returnLocationFeature = [];
  if (returnLocation !== null && returnLocationAddress !== depotAddress) {
    returnLocationFeature = [
      {
        coordinate: [returnLocation.lng, returnLocation.lat],
        properties: {
          isReturnLocation: true,
          address: returnLocationAddress,
        },
      },
    ];
  }

  return {
    features: concat(
      mapFeatures(locations),
      mapFeatures(depots),
      mapFeatures(returnLocationFeature),
    ),
    bbox: calculateBBox(
      concat(
        locations.map(({ coordinate }) => coordinate),
        depots.map(({ coordinate }) => coordinate),
        returnLocationFeature.map(({ coordinate }) => coordinate),
      ),
    ),
    type: 'FeatureCollection',
  };
};

export const hideDepot = ({ geo } = {}) =>
  geo
    ? {
        geo: {
          ...geo,
          features: filter(geo.features, ({ properties: { isDepot } }) => !isDepot),
        },
      }
    : {};
