import { includes, isNumber } from "lodash";

export const isRouteToDisplay = (feature, display) => includes(display.routeIds, feature.properties.routeId);

export const isPointDepot = feature =>
  feature.properties.isDepot ||
  (!!feature.properties.jobIds && includes(feature.properties.jobIds, 'departure'));

export const filterDepot = (mapData, isDepot) =>
  mapData.geo.features.filter(feature => isPointDepot(feature) === isDepot);

export const isPointReturnLocation = feature =>
  feature.properties.isReturnLocation ||
  (!!feature.properties.jobIds && includes(feature.properties.jobIds, 'arrival'));

export const isUnassignedJob = ({ properties: { routeId } }) => !isNumber(routeId);

export const isLineToDisplay = (feature, routingData) => feature.properties.isRouting || routingData.requestError;

export const filterPoint = (feature, display) =>
  display.showProblem ||
  isRouteToDisplay(feature, display) ||
  isUnassignedJob(feature);

export const filterLineString = (feature, display, routingData) =>
  !display.showProblem &&
  isRouteToDisplay(feature, display) &&
  isLineToDisplay(feature, routingData);

export const featureFilter = (feature, display, routingData) =>
  feature.geometry.type === 'Point' ? filterPoint(feature, display) : filterLineString(feature, display, routingData);

export const featureFilterUnselectedMarker = (feature, display) =>
  feature.geometry.type === 'Point' && !isRouteToDisplay(feature, display);

export const featureFilterUnselectedTourLine = (feature, display) => !isRouteToDisplay(feature, display);
