const EARTH_RADIUS_KM = 6371.01;

export const getBoundingCoordinates = (location, distance = 10) => {
  const lat = location[1] || location.lat;
  const lng = location[0] || location.lng;
  const radDist = distance / EARTH_RADIUS_KM;
  const minLat = lat - radDist;
  const maxLat = lat + radDist;
  const minLng = lng - radDist;
  const maxLng = lng + radDist;
  return [minLng, minLat, maxLng, maxLat];
};
