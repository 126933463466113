import parse from 'csv-parse';
import { isEmpty, head, keys } from 'lodash';
import validateRecords from './validator';
import { guessDelimiter } from './helper';

export default function parseCSV(data, maxRecords) {
  return new Promise((resolve, reject) => {
    const options = {
      delimiter: guessDelimiter(data),
      trim: true,
      skip_empty_lines: true,
      columns(columns) {
        return columns;
      },
      on_record(record, info) {
        return validateRecords(record, info);
      },
    };
    if (maxRecords) options.to = maxRecords;

    parse(data, options, (error, output) => {
      if (error) reject(error);
      resolve(output);
    });
  });
}

export function getCSVHeaders(data) {
  return parseCSV(data, 1).then(response => {
    if (!response || isEmpty(response)) return [];
    const first = head(response);
    return keys(first).map(key => key).filter(key => key !== 'rowIndex');
  });
}
