import moment from 'moment';
import 'moment-timezone';
import { isNaN, merge } from 'lodash';
import config from 'config';
import { CSV_HEADERS, CSV_META } from './config';
import { ValidationError } from './errors';
import { composeOrderField } from '../IngestOrdersHelpers';

const { timeFormat } = CSV_META;
const { timezone } = config;

function momentTimezone(timeString) {
  const now = moment.tz(timezone);
  return moment.tz(timeString, 'HH:mm', timezone).date(now.date());
}

function validateTimeFormat(record, index) {
  const start = composeOrderField(record, CSV_HEADERS.startTime);
  const end = composeOrderField(record, CSV_HEADERS.endTime);
  if (!start && !end) return;

  const timeRegexStart = /(\d?\d:\d\d)/g;
  const timeRegexEnd = /(\d?\d:\d\d)/g;
  const startTimeVal = timeRegexStart.exec(start);
  const endTimeVal = timeRegexEnd.exec(end);
  const startTimeToParse = startTimeVal && startTimeVal[1];
  const endTimeToParse = endTimeVal && endTimeVal[1];
  const startTime = momentTimezone(startTimeToParse);
  const endTime = momentTimezone(endTimeToParse);

  if (!startTime.isValid() || !endTime.isValid() || endTime < startTime) {
    const composedID = composeOrderField(record, CSV_HEADERS.id);
    throw new ValidationError('recordInvalidTimeFormat', {
      timeFormat,
      id: composedID || index,
    });
  }
}

function validateNumbers(record, index) {
  const demand = composeOrderField(record, CSV_HEADERS.demand);
  const serviceTime = composeOrderField(record, CSV_HEADERS.serviceTime);
  const lat = composeOrderField(record, CSV_HEADERS.latitude);
  const lng = composeOrderField(record, CSV_HEADERS.longitude);
  const composedID = composeOrderField(record, CSV_HEADERS.id);
  if (!demand || !serviceTime) return;
  if (isNaN(parseInt(demand, 10)) || isNaN(parseInt(serviceTime, 10))) {
    throw new ValidationError('recordInvalidNumericFormat', {
      id: composedID || index,
    });
  }
  if ((lat && isNaN(parseInt(lat, 10))) || (lng && isNaN(parseInt(lng, 10)))) {
    throw new ValidationError('recordInvalidNumericFormatLatLng', {
      id: composedID || index,
    });
  }
}

export default function validateRecords(record, info) {
  const index = info && info.records;
  const rec = merge(record, { rowIndex: info.records });
  validateTimeFormat(rec, index);
  validateNumbers(rec, index);
  return rec;
}
