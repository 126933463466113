import { first, last, size } from 'lodash';
import { decode } from './flexpolyline';

const getInitialTimeFormatted = () => {
  const start = new Date();
  start.setDate(start.getDate() + 1);
  start.setUTCHours(8, 0, 0);
  return `${start.toISOString().split('.')[0]}Z`;
};

const getGeoJSONfromRoutingResponse = (polylines, tourId) => {
  const allCoordinates = polylines.map(coord => coord.reverse());
  const bbox = [
    Math.min(...allCoordinates.map(stop => stop[0])),
    Math.min(...allCoordinates.map(stop => stop[1])),
    Math.max(...allCoordinates.map(stop => stop[0])),
    Math.max(...allCoordinates.map(stop => stop[1])),
  ];

  return {
    bbox,
    geometry: {
      coordinates: allCoordinates,
      type: 'LineString',
    },
    type: 'Feature',
    properties: {
      routeId: parseInt(tourId, 10),
      vehicle: {},
      isRouting: true,
    },
  };
};

export const tourToRoutingRequest = tour => {
  const req = {
    transportMode: 'car',
    return: 'polyline',
    tourId: tour.routeId,
    departureTime: getInitialTimeFormatted(),
    origin: `${first(tour.stops).location.lat},${first(tour.stops).location.lng}`,
    destination: `${last(tour.stops).location.lat},${last(tour.stops).location.lng}`,
  };
  if (size(tour.stops) > 2) {
    req.via = tour.stops
      .slice(1, tour.stops.length - 1)
      .map(({ location: { lat, lng } }) => `${lat},${lng}`)
      .join('&via=');
  }
  return req;
};

export const RoutingResponseToSolverFormat = routingResponse => {
  if (
    !routingResponse ||
    !routingResponse.payload ||
    !routingResponse.payload.data ||
    !routingResponse.payload.data.routes ||
    !routingResponse.payload.data.routes[0]
  ) {
    return null;
  }
  const url =
    routingResponse &&
    routingResponse.payload &&
    routingResponse.payload.config &&
    routingResponse.payload.config.url;
  const tourId =
    url &&
    url
      .split('&')
      .find(comp => comp.startsWith('tourId'))
      .split('=')[1];

  const sections = routingResponse.payload.data.routes[0].sections || [];
  const polylines = sections
    .map(s => decode(s.polyline))
    .map(r => r.polyline)
    .flat(1);

  return {
    geo: polylines.length ? getGeoJSONfromRoutingResponse(polylines, tourId) : null,
    tourId: parseInt(tourId, 10),
  };
};
