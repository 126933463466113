import { isEmpty, get, flatten, size } from 'lodash';
import { sortOrdersByComplexAddress } from './csv/clusters';

export function getOrdersByLocation(orders, location) {
  if (!orders || !location || !location.lat || !location.lng) return null;
  return orders.filter(o => o.Latitude === location.lat && o.Longitude === location.lng);
}

export function getOrdersByInternalID(orders, ID) {
  return orders && ID ? orders.filter(o => o.InternalID === ID) : null;
}

export function getOrdersByStop(orders, stop) {
  if (!orders || isEmpty(orders) || !stop) return null;
  const flat = flatten(stop.activities.map(act => getOrdersByInternalID(orders, act.jobId)));
  return sortOrdersByComplexAddress(flat);
}

export function getAddressFromOrders(orders) {
  return !isEmpty(orders) && orders[0].Address;
}

export function getLabelsFromOrders(orders, extended, translations) {
  if (!orders) return null;

  const phoneTrans = get(translations, 'phoneTrans');
  const details = orders.map(o => {
    const values = [];
    values.push([o.ID, o.Name, o['c/o'], o.Demand].filter(e => !!e).join(', '));
    if (extended) {
      if (o.Phone) {
        values.push(`${phoneTrans}: ${o.Phone}`);
      }
      values.push(o.Email);
      values.push(o.Notes);
    }
    const valid = values.filter(v => !!v);
    return `\n${valid.join('\n')}`;
  });
  return details.filter(n => !!n).concat('\n');
}

export function getTourOrders(tour, request) {
  return tour.stops.reduce((acc, stop) => acc.concat(getOrdersByStop(request.orders, stop)), []);
}

export function getTourDemand(tour, request) {
  const orders = getTourOrders(tour, request);
  return orders.reduce((acc, order) => acc + (parseInt(order.Demand, 10) || 1), 0);
}

export const getOrdersAddress = orders => {
  if (!orders || isEmpty(orders)) return null;
  if (size(orders) === 1) return orders[0].Address;
  return orders[0].Address ? orders[0].Address.split(',')[0] : '';
};
