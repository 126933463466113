import { CLEAR_MAP_DATA, SET_MAP_DATA, SET_TOUR_PARAMETER } from 'actions';
import { calculateBBox } from 'utils/GeoJSONConverter';
import { STORAGE_IDS } from '../utils/localStorageHelpers';
import { getStateFromMemory, setStateToMemory } from '../utils/MemoryHelpers';

const defaultState = null;
const stateID = STORAGE_IDS.mapData;
const initialState = getStateFromMemory(stateID, defaultState);

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_MAP_DATA: {
      const newState = payload || state;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case CLEAR_MAP_DATA: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    case SET_TOUR_PARAMETER: {
      const { location: { key, value } = {} } = payload;

      // empty keys means location was changed by dragging marker on map, so updating map's marker is not needed
      if (!value || !key || !state) return state;

      const {
        geo: { features, ...geo },
        ...restState
      } = state;
      const newFeatures = features.map(
        ({
          properties: { isDepot, ...properties },
          geometry: { coordinates, ...geometry },
          ...feature
        }) => ({
          ...feature,
          properties: { ...properties, isDepot },
          geometry: { ...geometry, coordinates: isDepot ? [value.lng, value.lat] : coordinates },
        }),
      );

      const newState = {
        ...restState,
        geo: {
          ...geo,
          bbox: calculateBBox(newFeatures.map(({ geometry: { coordinates } }) => coordinates)),
          features: newFeatures,
        },
      };
      setStateToMemory(stateID, newState);
      return newState;
    }
    default:
      return state;
  }
};
