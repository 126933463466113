import { FLEET_MODES, SET_TOUR_PARAMETER } from 'actions';
import config from 'config';
import { cloneDeep, assign } from 'lodash';
import { getStorageValue, setStorageValue, STORAGE_IDS } from '../utils/localStorageHelpers';
import { AmplitudeService } from '../utils/amplitude';
import { isMobileDevice } from '../utils/helpers';
import getInitialLanguage from '../utils/LanguageHelpers';

const {
  scenario: {
    times: [{ key: day, value }],
    vehicle,
    vehicleMobile,
  },
} = config;

const isMobile = isMobileDevice();
const vehicleToUse = isMobile ? vehicleMobile : vehicle;
const lang = getInitialLanguage();
const defaultFleetMode = lang === 'th' ? FLEET_MODES.CUSTOM : FLEET_MODES.SOLO;

const defaultOrdersMode = isMobile ? 'manual' : 'import';
const defaultInitialState = {
  location: { value: null, label: '' },
  isReturnLocationOn: false,
  returnLocation: { value: null, label: '' },
  time: { key: day, value },
  vehicle: { amount: vehicleToUse.amount, capacity: vehicleToUse.capacity },
  ordersMode: defaultOrdersMode,
  editedOrder: null,
  fleetMode: defaultFleetMode,
  csvConfig: {},
};

const stored = getStorageValue(STORAGE_IDS.tourPlanner, defaultInitialState);
if (stored) {
  delete stored.editedOrder;
  if (stored.fleetMode === undefined) {
    stored.fleetMode = FLEET_MODES.CUSTOM;
  }
}
const initialState = assign({}, cloneDeep(defaultInitialState), stored);
if (initialState.ordersMode === 'demo') initialState.ordersMode = defaultOrdersMode;
AmplitudeService.setFleetConfiguration(initialState);

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  if (type !== SET_TOUR_PARAMETER) return state;
  const newState = assign({}, state, payload);
  if (payload && payload.location && !payload.location.value) delete newState.location.value;
  setStorageValue(STORAGE_IDS.tourPlanner, newState);
  AmplitudeService.setFleetConfiguration(newState);

  return newState;
};
