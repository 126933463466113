import React, { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import withTranslation from '../../../hoc/withTranslation';
import { setTourParameter } from '../../../actions';
import Label from '../../Form/Label';
import { StyledTextLight } from '../Global/WizardStyled';
import InputLocation from '../Global/InputLocation';
import { colors, fontSize } from '../../../global/variables';
import home from '../../../global/img/home-solid.svg';
import { isSameLocation } from '../../../utils/helpers';

const StyledFleetReturnAddress = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const StyledSwitchContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const StyledExplanation = styled.div({
  marginTop: '0.5rem',
  marginBottom: '0.25rem',
});

const StyledDepotShortcutButton = styled.button(({ disabled }) => ({
  alignSelf: 'flex-end',
  position: 'relative',
  top: '-0.25rem',
  opacity: disabled ? 0 : 1,
  display: 'flex',
  transitionProperty: 'opacity',
  transitionDuration: '0.2s',
  borderRadius: '50px',
  border: `solid 1px ${colors.primaryColorHovered} !important`,
  boxShadow: `inset 0px 0px 0px .1px ${colors.primaryColor} !important`,
  color: colors.secondaryTextColor,
  backgroundColor: 'transparent',
  textTransform: 'uppercase',
  cursor: 'pointer',
  padding: '5px 8px',
  fontSize: fontSize.smaller,
  fontFamily: 'FiraGO',

  '&:hover': {
    border: `solid 1px ${colors.primaryColor} !important`,
    boxShadow: `inset 0px 0px 0px 1px ${colors.primaryColor} !important`,
  },
}));

const StyledDepotIcon = styled.div({
  backgroundImage: `url(${home})`,
  backgroundRepeat: 'no-repeat',
  width: '0.8rem',
  height: '0.8rem',
  position: 'relative',
  // Compensate for visual center of mass
  top: '-0.5px',
  marginLeft: '0.3rem',
});

function explanationText(tourPlanner, translations) {
  const {
    wizard: {
      fleet: { fleetReturnToDepot, fleetReturnToCustomAddress },
    },
  } = translations;
  if (!tourPlanner.isReturnLocationOn) {
    return '';
  }

  if (isSameLocation(tourPlanner.location, tourPlanner.returnLocation)) {
    return fleetReturnToDepot;
  }

  return fleetReturnToCustomAddress;
}

function FleetReturnAddress({ oAuth, user, tourPlanner, translations }) {
  const {
    wizard: {
      fleet: { fleetReturnAddressLabel, depotAddressShortcutButton, fleetReturnAddressPlaceholder },
    },
  } = translations;
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(parameter => dispatch(setTourParameter(parameter)), [
    dispatch,
  ]);

  const onToggle = useCallback(
    e => {
      handleSetTourParameter({
        isReturnLocationOn: e.target.checked,
        returnLocation: e.target.checked ? tourPlanner.returnLocation : { value: null, label: '' },
      });
    },
    [handleSetTourParameter, tourPlanner],
  );

  const onLocationFound = useCallback(
    returnLocation => {
      handleSetTourParameter({ returnLocation });
    },
    [handleSetTourParameter],
  );

  const onDepotShortcutClick = useCallback(() => {
    handleSetTourParameter({ returnLocation: cloneDeep(tourPlanner.location) });
  }, [tourPlanner, handleSetTourParameter]);

  const locationInput = tourPlanner.isReturnLocationOn ? (
    <InputLocation
      id="input-fleet-end-tour"
      placeholder={fleetReturnAddressPlaceholder}
      value={tourPlanner.returnLocation.label}
      oAuth={oAuth}
      onLocationFound={onLocationFound}
      user={user}
      tourPlanner={tourPlanner}
    />
  ) : null;
  const deportShortcutButton = tourPlanner.isReturnLocationOn ? (
    <StyledDepotShortcutButton
      disabled={
        tourPlanner.location.label === '' ||
        isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
          ? 'disabled'
          : undefined
      }
      onClick={onDepotShortcutClick}
    >
      {depotAddressShortcutButton}
      <StyledDepotIcon />
    </StyledDepotShortcutButton>
  ) : null;

  return (
    <StyledFleetReturnAddress>
      <StyledSwitchContainer>
        <input
          id="input-depot-round-trip"
          type="checkbox"
          defaultChecked={tourPlanner.isReturnLocationOn}
          onChange={onToggle}
        />{' '}
        <label className="custom-switcher" htmlFor="input-depot-round-trip">
          T
        </label>
        <Label direction="horizontal">{fleetReturnAddressLabel}</Label>
      </StyledSwitchContainer>

      <StyledExplanation>
        <StyledTextLight>{explanationText(tourPlanner, translations)}</StyledTextLight>
      </StyledExplanation>

      {locationInput}
      {deportShortcutButton}
    </StyledFleetReturnAddress>
  );
}

export default withTranslation(FleetReturnAddress);
