import moment from 'moment';
import 'moment-timezone';

export default {
  asset: {
    isExternal: process.env.REACT_APP_IS_EXTERNAL_ASSET || 'false',
    url: 'https://go.engage.here.com/rs/142-UEL-347/images/',
  },
  api: {
    url: process.env.REACT_APP_API_URL,
  },
  map: {
    url: `https://4.traffic.maps.ls.hereapi.com/maptile/2.1/traffictile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${process.env.REACT_APP_AK}&ppi=320`,
  },
  geocoder: {
    url: 'https://geocode.search.hereapi.com/v1/geocode',
    autoSuggestUrl: 'https://geocode.search.hereapi.com/v1/autosuggest',
    discoverUrl: 'https://geocode.search.hereapi.com/v1/discover',
    reverseUrl: 'https://revgeocode.search.hereapi.com/v1/revgeocode',
  },
  languages: ['en', 'es', 'fr', 'de', 'it', 'hi', 'pt', 'ru', 'nl', 'th', 'el'],
  routing: {
    url: 'https://router.hereapi.com/v8/routes',
  },
  geocoding: {
    url: 'https://geocode.search.hereapi.com/v1/geocode',
  },
  oExe: {
    cla: process.env.REACT_APP_K,
    claSe: process.env.REACT_APP_S,
    url: 'https://account.api.here.com/oauth2/token',
  },
  share: {
    url: 'https://share.here.com/r',
  },
  ocr: {
    short: process.env.REACT_APP_OCR_K,
    long: process.env.REACT_APP_OCR_AK,
  },
  scenario: {
    times: [
      { key: 'day', value: { start: 8, end: 18 } },
      { key: 'morning', value: { start: 8, end: 11 } },
      { key: 'evening', value: { start: 19, end: 21 } },
    ],
    vehicle: {
      id: 'vehicle1',
      profile: 'car',
      amount: 2,
      capacity: [10],
      costs: {
        distance: 0.02,
        time: 0.004805555555556,
        fixed: 22,
      },
    },
    vehicleMobile: {
      amount: 1,
      capacity: [30],
    },
    start: 0,
    end: 24,
  },
  emptyProblem: {
    id: 'Unknown',
    plan: {
      jobs: [],
    },
  },
  defaults: {
    demand: 1,
    serviceTime: 300,
  },
  shortTimeFormat: 'HH:mm',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ssZ',
  shortDateTimeFormat: 'DD/MM/YYYY HH:mm',
  timezone: moment.tz.guess(),
  amplitudeApiKey: process.env.REACT_APP_AMP,
  maxValues: {
    orders: 1500,
    capacity: 500,
    vehicles: 200,
    demand: 100,
    defaultVehicleCapacity: 6,
  },
};
