import { createStore as _createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import reducers from 'reducers';

const createStore = () => {
  const middleware = [thunk, ReduxPromise];
  const enhancers = [];
  const isDev = process.env.NODE_ENV === 'development';

  if (isDev) {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }
  return _createStore(
    reducers,
    {},
    isDev ? compose(applyMiddleware(...middleware), ...enhancers) : applyMiddleware(...middleware),
  );
};

export default createStore;
